import { EMPTY_VESSEL_SUMMARY_MODEL } from 'presentation/model/ChargeHeaderMaintenance//VesselSummaryMaintenanceModel';
import { useState } from 'react';
import { createContainer } from "react-tracked";

const {
    Provider: VesselSummaryMaintenanceProvider,
    useTracked: useVesselSummaryMaintenanceTracked
} = createContainer(() => useState(EMPTY_VESSEL_SUMMARY_MODEL), {concurrentMode: true});
export { VesselSummaryMaintenanceProvider, useVesselSummaryMaintenanceTracked };

