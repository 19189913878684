import { Permission } from "presentation/constant/ANAInfo/PermissionName";
import { VesselSummaryMaintenanceProvider } from "presentation/store/ChargeHeaderMaintenance/VesselSummaryMaintenanceProvider";
import { ANAInfoWrapper } from "presentation/view/components/ANAInfo/ANAInfoWrapper";
import { VesselSummaryComp } from "presentation/view/section/ChargeHeaderMaintenance/VesselSummary/VesselSummaryComp";
import { GridStyles } from "veronica-ui-component/dist/component/core/styled/table.styled";

export const VesselSummaryMaintenanceContainer = () => {
    return <ANAInfoWrapper permission={Permission.LOGIN}>
        <VesselSummaryMaintenanceProvider>
            <GridStyles/>
            <VesselSummaryComp />
        </VesselSummaryMaintenanceProvider>
    </ANAInfoWrapper>;
};