export const ContainerStatusPolicy = () => {
    const CNTR_STATUS_EM = "EM";
    const CNTR_STATUS_IF = "IF";       
    const CNTR_STATUS_IM = "IM";       
    const CNTR_STATUS_TF = "TF";       
    const CNTR_STATUS_TM = "TM";       
    const CNTR_STATUS_XF = "XF";       
    const CNTR_STATUS_XI = "XI";       
    const CNTR_STATUS_XM = "XM";       
    const CNTR_STATUS_OF = "OF";       
    const CNTR_STATUS_OM = "OM";       
    const CNTR_STATUS_RE = "RE";       
    const CNTR_STATUS_RM = "RM";       
    const CNTR_STATUS_SH = "SH";       
    const CNTR_STATUS_SM = "SM";

    const getContainerStatusForO = () => {
        return [CNTR_STATUS_XF, CNTR_STATUS_XM, CNTR_STATUS_XI];
	}
    
    const getContainerStatusForX = () => {
        return [CNTR_STATUS_OF, CNTR_STATUS_OM];     
    }
    
    const getContainerStatusForE = () => {
        return [CNTR_STATUS_TF, CNTR_STATUS_TM];    
    }
    
    const getContainerStatusForI = () => {
        return [CNTR_STATUS_EM, CNTR_STATUS_IF, CNTR_STATUS_IM];    
    }
	
    const getContainerStatusForR = () => {
        return [CNTR_STATUS_RE, CNTR_STATUS_RM];
	}
    
    const getContainerStatusForS = () => {
        return [CNTR_STATUS_SH, CNTR_STATUS_SM];
	}

    return {
        CNTR_STATUS_EM,
        CNTR_STATUS_IF,    
        CNTR_STATUS_IM,
        CNTR_STATUS_TF,
        CNTR_STATUS_TM,
        CNTR_STATUS_XF,
        CNTR_STATUS_XI,
        CNTR_STATUS_XM,
        CNTR_STATUS_OF,
        CNTR_STATUS_OM,
        CNTR_STATUS_RE,
        CNTR_STATUS_RM,
        CNTR_STATUS_SH,
        CNTR_STATUS_SM,
        getContainerStatusForO: getContainerStatusForO,
        getContainerStatusForX: getContainerStatusForX,
        getContainerStatusForE: getContainerStatusForE,
        getContainerStatusForI: getContainerStatusForI,
        getContainerStatusForR: getContainerStatusForR,
        getContainerStatusForS: getContainerStatusForS
    }
}