export interface VesselVoyageMasterEntity{
    consortiumCode?: string | null,
    vesselCode?: string | null,
    voyageCode?: string | null,
	vesselName?:  string | null,
    vslStartOpsDateTime?: Date | null,
    vslEndOpsDateTime?: Date | null,
    handlingTerminal?:  string | null,	
    soa?: string | null,
    loa?: number | null,
    vesselType?: string | null,
    etd?: Date | null,
    serviceCode?: string | null,


    [key: string]: string | number | Date | null | undefined
}

export const EMPTY_VESSEL_VOYAGE_MASTER_ENTITY : VesselVoyageMasterEntity = {
    consortiumCode: null,
    vesselCode: null,
    voyageCode: null,
	vesselName: null,
    vslStartOpsDateTime: null,
    vslEndOpsDateTime: null,
    handlingTerminal: null,
    soa: null,
    loa: null,
    vesselType: null,
    etd: null,
    serviceCode: null,
}