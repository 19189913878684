import { useChargeDetailTracked } from "presentation/store/ChargeDetailMaintenance/ChargeDetailProvider";
import { useState } from "react";

interface ISummaryRow {
    className?: string;
    summaryKey: string;
    summary?: {[key:string]: string};    
}

const SummaryRow: React.FC<ISummaryRow> = (props: ISummaryRow) => {
    const { className, summaryKey, summary } = props;
    if (!summaryKey || !summary) return <></>;
    if (className !== "total" && summaryKey === "totals") return <></>

    const keys = ["R", "F", "E", "C", "H", "MH", "T", "MT", "DI", "I", "SA", "SG"];
    const orderSummaryRow = keys?.map(key => ({ key, value: summary[key] ?? "0" }));

    return <li key={summaryKey} className={`${className || ''} ${summaryKey}`}>
        <p className='header'>
            {summaryKey === "first" ? "" : summaryKey}
        </p>
        {orderSummaryRow.map(({key, value}) => (
            <p className={key}> {value} </p>
        ))}
    </li>
}

export const ChargeDetailSummaryTablePanel = () => {
    const [ firstSummaryRow ] = useState<{[key:string]: string}>({
        "R": "R",
        "F": "F",
        "E": "E",
        "C": "C",
        "H": "H",
        "MH": "MH",
        "T": "T",
        "MT": "MT",
        "DI": "DI",
        "I": "I",
        "SA": "SA",
        "SG": "SG",
    });
    const [ chargeDetailState ] = useChargeDetailTracked();

    return <>{chargeDetailState.chargeDetailSummary && chargeDetailState.isShowSummaryPanel && firstSummaryRow && 
        <div className='charge-summary'>
            {/* <div className='group-title'><div className='title-summary'>{ChargeDetailConstant.Charge.SUMMARY_BUT}</div></div> */}

            <ul>
                <SummaryRow className='top' summaryKey={"first"} summary={firstSummaryRow} />
                {
                    Object.entries(chargeDetailState.chargeDetailSummary).flatMap(([outerKey, subObject]) =>                        
                        <SummaryRow summaryKey={outerKey} summary={subObject} />
                    )
                }
                <SummaryRow className='total' summaryKey={"Total"} summary={chargeDetailState.chargeDetailSummary.totals} />
            </ul>
        </div>
    }</>;
};