import { useChargeDetailVM } from "presentation/hook/ChargeDetailMaintenance/useChargeDetailVM";
import { ChargeDetailModel } from "presentation/model/ChargeDetailMaintenance/ChargeDetailModel";
import { InputDropdown } from "veronica-ui-component/dist/component/core";

export const CntrSizeTypeAccordion = ({chargeDetailState}:{chargeDetailState:ChargeDetailModel}) => {
    const chargeBatchUpdate = chargeDetailState.chargeBatchUpdate
    const chargeDetailVM = useChargeDetailVM();
    return (
        <>
            <div className={'im-flex-row'} style={{marginBottom:'24px'}}>
                <div className='im-flex-row-item'>
                    <InputDropdown
                        label='Size'
                        width='150px'
                        inputType="freeText"
                        mode={"single"}
                        value={chargeBatchUpdate.size}
                        onChange={(e) => chargeDetailVM.onDropdownChange(e, 'size', chargeBatchUpdate, 'chargeBatchUpdate')}
                        options={chargeDetailState.dynamicOptions.sizeGroupDropdownOptions}/>
                </div>
                
                <div className='im-flex-row-item'>
                    <InputDropdown
                        label='Type'
                        width='150px'
                        inputType="freeText"
                        mode={"single"}
                        value={chargeBatchUpdate.cntrType}
                        onChange={(e) => chargeDetailVM.onDropdownChange(e, 'cntrType', chargeBatchUpdate, 'chargeBatchUpdate')}
                        options={chargeDetailState.dynamicOptions.typeDropdownOptions}/>
                </div>
            </div>
        </>
    )
}