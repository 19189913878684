import { IMessage } from "@stomp/stompjs";
import { ManualSystemChargeEntity } from "domain/entity/ChargeDataEnquiry/ManualSystemChargeEntity";
import { SearchModeEntity } from "domain/entity/ChargeDataEnquiry/SearchModeEntity";
import { ChargeHeaderEntity } from "domain/entity/ChargeHeader/ChargeHeaderEntity";
import { ChargeHeaderSearchCriteriaEntity } from "domain/entity/ChargeHeader/ChargeHeaderSearchCriteriaEntity";
import { DivertShipRequestEntity, EMPTY_DIVERT_SHIP_REQUEST } from "domain/entity/ChargeHeader/DivertShipRequestEntity";
import { ChargeDataEnquiryRepository } from "domain/repository/ChargeDataEnquiry/ChargeDataEnquiryRepo";
import { ChargeHeaderRepository } from "domain/repository/ChargeHeader/ChargeHeaderRepo";
import { ChargeTypeRepository } from "domain/repository/ChargeType/ChargeTypeRepo";
import { SearchPreferenceRepository } from "domain/repository/Common/SearchPreferenceRepo";
import { CompanyRepository } from "domain/repository/Company/CompanyRepo";
import { MasterDataRepository } from "domain/repository/MasterData/MasterDataRepo";
import { SpecialHandlingIndMappingRepository } from "domain/repository/SpecialHanldingInd/SpecialHandlingIndMappingRepo";
import { StandardTariffCodeRepository } from "domain/repository/TariffCode/StandardTariffCodeRepo";
import { ParameterDetailRepository } from "domain/repository/parameter/ParameterDetailRepo";
import { DomUtils } from "helpers/DomUtils";
import { E_Custom_Dispatch_Event, E_Type_Of_Event, customDispatchEvent } from "helpers/Events";
import _, { parseInt } from "lodash";
import { ChargeHeaderCriteriaDirectoryAllCheckboxOption, ChargeHeaderCriteriaDirectoryCheckboxOptionList } from "presentation/constant/ChargeHeader/ChargeHeaderCriteriaDirectoryCheckboxOption";
import { ChargeHeaderEnabledSearchCriteria, DEFAULT_ENABLED_CHARGE_HEADER_SEARCH_CRITERIA } from "presentation/constant/ChargeHeader/ChargeHeaderEnabledSearchCriteria";
import { ChargeHeaderSearchCriteria, EMPTY_CHARGE_HEADER_SEARCH_CRITERIA } from "presentation/constant/ChargeHeader/ChargeHeaderSearchCriteria";
import { ChargeHeaderSearchCriteriaCheckboxRelatedField } from "presentation/constant/ChargeHeader/ChargeHeaderSearchCriteriaCheckboxRelatedField";
import { ChargeHeaderMaintenanceModel } from "presentation/model/ChargeHeaderMaintenance/ChargeHeaderMaintenanceModel";
import { DropdownProps } from "presentation/model/DropdownProps";
import { WorkspaceModel } from "presentation/model/workspaceModel";
import BaseViewModel from "presentation/viewModel/BaseViewModel";
import { ChangeEvent, Dispatch, SetStateAction } from "react";
import { GroupCheckboxList } from "veronica-ui-component/dist/component/core";

interface ChargeHeaderMaintenanceVMProps extends BaseViewModel {
    dispatch: [
        Dispatch<SetStateAction<ChargeHeaderMaintenanceModel>> | ((value: SetStateAction<ChargeHeaderMaintenanceModel>) => void),
        Dispatch<SetStateAction<WorkspaceModel>> | ((value: SetStateAction<WorkspaceModel>) => void),
    ]
    companyRepo: CompanyRepository,
    chargeTypeRepo: ChargeTypeRepository,
    standardTariffCodeRepo: StandardTariffCodeRepository,
    parameterDetailRepo: ParameterDetailRepository,
    specialhandlingIndMappingRepo: SpecialHandlingIndMappingRepository,
    chargeDataEnquiryRepo: ChargeDataEnquiryRepository,
    searchPreferenceRepo: SearchPreferenceRepository<ChargeHeaderSearchCriteria,ChargeHeaderEnabledSearchCriteria>,
    chargeHeaderRepo: ChargeHeaderRepository
    masterDataRepo: MasterDataRepository
}

export const ChargeHeaderMaintenanceVM = ({ dispatch, chargeDataEnquiryRepo: ChargeDataEnquiryRepo, companyRepo, chargeTypeRepo,
     standardTariffCodeRepo, parameterDetailRepo, specialhandlingIndMappingRepo, 
     searchPreferenceRepo, chargeHeaderRepo,masterDataRepo}: ChargeHeaderMaintenanceVMProps) => {
    const [ChargeHeaderMaintenanceDispatch] = dispatch;

    const onPageInit = (loginUser: string) =>{
        ChargeHeaderMaintenanceDispatch(prevState => {
            
            return { ...prevState,searchCriteria: {...prevState.searchCriteria,loginUser: loginUser}};
        })
    }
    const onRefresh = () => {
        ChargeHeaderMaintenanceDispatch((prevState) => {
            const gruopStateList = prevState.currentTableRef?.current?.api.getServerSideGroupLevelState();
            gruopStateList?.forEach((groupState: any) => {
                prevState.currentTableRef?.current?.api.refreshServerSide({ route: groupState.route, purge: false })
            })
            return {
                ...prevState
            }
        })
    };

    const onWSMessage = (msg: IMessage) => {
        const body = JSON.parse(msg.body) as ManualSystemChargeEntity;
        ChargeHeaderMaintenanceDispatch((prevState) => {
            prevState.currentTableRef?.current?.api.forEachNode((rowNode: any) => {
                if (body.cntrNo === rowNode.data.cntrNo) {
                    rowNode.setData(body);
                }
            });
            return {
                ...prevState
            }
        })
    }

    const onCriteriaDirectoryDropdown = (e: any) => {
        ChargeHeaderMaintenanceDispatch(prevState => {
            prevState.checkboxRef[e?.value]?.scrollIntoView()
            return {
                ...prevState,
                highlightedCheckboxKey: e?.value ?? e
            }
        })
    }

    const onCloseScreen = () => {
        document.dispatchEvent(new CustomEvent('closeChargeHeaderMaintenance'))
    }

    const onShowLoading = () => {
        ChargeHeaderMaintenanceDispatch(prevState => {
            return {
                ...prevState,
                isLoading: true,
            }
        })
    }

    const onHideLoading = () => {
        ChargeHeaderMaintenanceDispatch(prevState => {
            return {
                ...prevState,
                isLoading: false,
            }
        })
    }

    const updateSelectedChargeHeaders = (rows:any[]) => {
        ChargeHeaderMaintenanceDispatch(prevState => {
            return {
                ...prevState,
                selectedChargeHeaderRows: rows                       
            }
        })
    }

    const updateCheckboxRef = (checkboxRef: { [key: string]: HTMLElement | null }) => {
        ChargeHeaderMaintenanceDispatch(prevState => ({
            ...prevState,
            checkboxRef: checkboxRef
        }))
    }

    const updateCheckboxValue = (checked: boolean, key: string) => {
        ChargeHeaderMaintenanceDispatch(prevState => {
            if (key === ChargeHeaderCriteriaDirectoryAllCheckboxOption.key) {
                ChargeHeaderCriteriaDirectoryCheckboxOptionList.forEach(item => {
                    item.checkboxList?.forEach(item => {
                        prevState.checkboxValue[item.key] = checked
                    })
                })
            }
            ChargeHeaderCriteriaDirectoryCheckboxOptionList.find(item => item.option.key === key)?.checkboxList?.forEach(item => {
                prevState.checkboxValue[item.key] = checked
            })
            prevState.checkboxValue[key] = checked
            return {
                ...prevState,
                highlightedCheckboxKey: null
            }
        })
    }

    const onSaveCheckboxValue = () => {
        ChargeHeaderMaintenanceDispatch(prevState => {
            const searchCriteriaFieldName = Object.keys(EMPTY_CHARGE_HEADER_SEARCH_CRITERIA).concat(Object.keys(ChargeHeaderSearchCriteriaCheckboxRelatedField))
            const disabledCriteria = Object.keys(prevState.checkboxValue).filter((item) => !prevState.checkboxValue[item] && searchCriteriaFieldName.includes(item))

            const resetSearchCriteria = disabledCriteria.reduce((obj, fieldName) => {
                //By default, we use disabled field name to retrieve default value from EMPTY_INVENTORY_SEARCH_CRITERIA,
                //If related field found, we use related field to retrieve default value.
                let resetSearchCriteriaPerField = { [fieldName]: EMPTY_CHARGE_HEADER_SEARCH_CRITERIA[fieldName] }
                if (ChargeHeaderSearchCriteriaCheckboxRelatedField[fieldName]) {
                    resetSearchCriteriaPerField = ChargeHeaderSearchCriteriaCheckboxRelatedField[fieldName].reduce((objPerField, fieldNamePerField) => {
                        return {
                            ...objPerField,
                            [fieldNamePerField]: EMPTY_CHARGE_HEADER_SEARCH_CRITERIA[fieldNamePerField],
                        }
                    }, {})
                }
                return {
                    ...obj,
                    ...resetSearchCriteriaPerField
                };
            }, {});

            return {
                ...prevState,
                searchCriteria: { ...prevState.searchCriteria, ...resetSearchCriteria },
                searchCounter: prevState.searchCounter !== 0 ? prevState.searchCounter + 1 : 0,
                enabledSearchCriteria: prevState.checkboxValue,
                isFilterSearchCriteria: true,
                isEditSearchCriteria: true,
            }
        })
    }

    const onResetCheckboxValue = () => {
        ChargeHeaderMaintenanceDispatch(prevState => {
            return {
                ...prevState,
                checkboxValue: { ...DEFAULT_ENABLED_CHARGE_HEADER_SEARCH_CRITERIA },
            }
        })
    }

    const onRemoveSearchCriteria = (fieldName: string, isSearch: boolean = true) => {
        //By default, we use disabled field name to retrieve default value from EMPTY_INVENTORY_SEARCH_CRITERIA,
        //If related field found, we use related field to retrieve default value.
        let resetSearchCriteriaPerField = { [fieldName]: EMPTY_CHARGE_HEADER_SEARCH_CRITERIA[fieldName] }
        if (ChargeHeaderSearchCriteriaCheckboxRelatedField[fieldName]) {
            resetSearchCriteriaPerField = ChargeHeaderSearchCriteriaCheckboxRelatedField[fieldName].reduce((objPerField, fieldNamePerField) => {
                return {
                    ...objPerField,
                    [fieldNamePerField]: EMPTY_CHARGE_HEADER_SEARCH_CRITERIA[fieldNamePerField],
                }
            }, {})
        }

        ChargeHeaderMaintenanceDispatch(prevState => {
            return {
                ...prevState,
                searchCounter: isSearch ? prevState.searchCounter + 1 : prevState.searchCounter,
                searchCriteria: { ...prevState.searchCriteria, ...resetSearchCriteriaPerField }
            }
        })
    }

    const onRemoveAllSearchCriteria = (loginUser:string) => {
        ChargeHeaderMaintenanceDispatch(prevState => {
            prevState.currentTableRef?.current?.api.setServerSideDatasource({
                getRows: (params: any) => {
                    params.success({
                        rowData: [],
                        rowCount: 0
                    })
                },
            })
            const hdrGroupName = prevState.searchCriteria.hdrGroupName;
            const tempHdrGroupName = prevState.searchCriteria.tempHdrGroupName;
            return {
                ...prevState, 
                searchCounter: 0, 
                searchCriteria: { 
                    ...EMPTY_CHARGE_HEADER_SEARCH_CRITERIA,
                    hdrGroupName: hdrGroupName,
                    tempHdrGroupName: tempHdrGroupName,
                    loginUser: loginUser
                 }
            }
        })
    }

    const onHideRightCriteriaPanel = () => {
        ChargeHeaderMaintenanceDispatch(prevState => {
            if (prevState.isShowRightPanel) {
                customDispatchEvent(E_Type_Of_Event.CHARGE_HEADER_SEARCH_EVENT, E_Custom_Dispatch_Event.CHARGE_HEADER_HIDE_RIGHT_PANEL, {});
                DomUtils.removeWithSearchClass();
            } else {
                customDispatchEvent(E_Type_Of_Event.CHARGE_HEADER_SEARCH_EVENT, E_Custom_Dispatch_Event.CHARGE_HEADER_HIDE_RIGHT_PANEL, { isDirectory: !prevState.isFilterSearchCriteria });
                // DomUtils.addWithSearchClass();
            }

            return { ...prevState, isShowRightPanel: !prevState.isShowRightPanel, };
        });
    }

    // Open search criteria panel not preview panel
    const onEditSearchCriteria = () => {
        // DomUtils.addWithSearchClass();
        ChargeHeaderMaintenanceDispatch(prevState => {
            // if (prevState.searchCounter === 0) DomUtils.addOnlySearchClass();
            // else DomUtils.addWithSearchClass();
            return { ...prevState, isShowRightPanel: true, isFilterSearchCriteria: true, isEditSearchCriteria: true, }
        });
    }

    // Open directory criteria panel not search criteria preview panel
    const onSwitchSearchCriteriaMode = () => {
        // DomUtils.addWithSearchClass();
        ChargeHeaderMaintenanceDispatch(prevState => {
            // if (prevState.searchCounter === 0) DomUtils.addOnlySearchClass();
            // else DomUtils.addWithSearchClass();

            return {
                ...prevState, 
                isShowRightPanel: !prevState.isShowRightPanel, 
                isFilterSearchCriteria: false, 
                isEditSearchCriteria: false, 
                checkboxValue: prevState.enabledSearchCriteria, 
                highlightedCheckboxKey: null,
            };
        });
    }

    const onSearch = async (searchCriteria: ChargeHeaderSearchCriteria, searchModeEntity: SearchModeEntity, back:boolean=true, returnData:boolean=false, userName?:string) => {
        // DomUtils.addWithSearchClass();

        if (!returnData) {
            ChargeHeaderMaintenanceDispatch(prevState => ({
                ...prevState,
                chargeHeaderEntities: [],
                selectedChargeHeaderRows: [],
                searchCriteria: {
                    ...prevState.searchCriteria,
                    hdrGroupName:prevState.searchCriteria.tempHdrGroupName??"" 
                },
            }));
        }

        const groupCriteriaList: ChargeHeaderSearchCriteriaEntity[] = searchModeEntity.groupKeys.map((item, index) => ({
            key: searchModeEntity.rowGroupCols[index], value: item
        }))

        const groupName = returnData ? "" : searchCriteria.tempHdrGroupName??"";

        return await chargeHeaderRepo.getChargeHeader({
            searchCriteria: {...searchCriteria, hdrGroupName: groupName},
            startRow: searchModeEntity.startRow,
            endRow: searchModeEntity.endRow,
            searchSort: searchModeEntity.searchSort,
            currentGroup: searchModeEntity.currentGroup,
            groupCriteria: groupCriteriaList,
        }).then((data) => {
            let headerEntity;
            if (data && _.isArray(data) && !_.isEmpty(data)) {
                headerEntity = data[0];
            } else if(data && data.toString().startsWith("Error:")){
                ChargeHeaderMaintenanceDispatch(prevState => {
                    return {...prevState,
                        allFormState: {"validateSearchCriteria": data.toString()}
                    };
                });
                return {"validateSearchCriteria": data.toString()}
            }

            let currentCriteria:ChargeHeaderSearchCriteria = {...searchCriteria};
            if (searchCriteria.type === "deliveryChargeSummary") {
                currentCriteria = {
                    ...searchCriteria,                         
                    hdrGroupName: searchCriteria.tempHdrGroupName??"",
                    chargeHdr: headerEntity
                };
            }

            
            currentCriteria = {
                ...currentCriteria,
                hdrGroupName: searchCriteria.tempHdrGroupName??""
            };


            if (!returnData) {
                ChargeHeaderMaintenanceDispatch(prevState => {

                    const obj: { [x: string]: boolean } = {};
                    const objDispatch: { [x: string]: boolean } = { isEdit: true };
                    if (prevState.searchCounter === 0) {
                        obj.isShowRightCriteriaPanel = true;
                        objDispatch.isEdit = true;
                        obj.isEditSearchCriteria = false;
                    } else {
                        if (back) {
                            obj.isEditSearchCriteria = !prevState.isEditSearchCriteria;
                        }
                    }

                    return { 
                        ...prevState, 
                        ...obj, 
                        searchCounter: prevState.searchCounter + 1, 
                        chargeHeaderEntities: data??[],
                        selectedChargeHeaderRows: [],
                        searchCriteria: {
                            ...currentCriteria
                        },
                    };
                });
            } else {
                return data;
            }
        }).catch((error)=>{
            return null;            
        }) 
        
    }

    const onSwithTableStructure = () => {
        ChargeHeaderMaintenanceDispatch(prevState => ({
            ...prevState,
            searchCriteria: {
                ...prevState.searchCriteria,
                hdrGroupName: prevState.searchCriteria.tempHdrGroupName??"",
            }
        }))
    }

    const loadDropdownOption = async() => {
        companyRepo.getAllCompanyForCombobox().then(
            companies => {
                let companyCodeDropdownOptions = companies?.map((company) => ({
                    dropdownLabel: company.companyCode,
                    tagLabel: company.companyCode,
                    value: company.companyCode,
                })) ?? []
                companyCodeDropdownOptions = _.orderBy(companyCodeDropdownOptions, "dropdownLabel");

                ChargeHeaderMaintenanceDispatch(prevState => ({
                    ...prevState,
                    dynamicOptions: {
                        ...prevState.dynamicOptions,
                        billToCompDropdownOptions: [
                            ...companyCodeDropdownOptions],
                    }
                }))
            }
        );


        parameterDetailRepo.getAllParameterDtlsByParameterCode("BILLING_CYCLE").then(
            parDtls => {
                const billingCycleDropdownOptions = parDtls?.map((parDtl) => ({
                    dropdownLabel: parDtl.parameterDtlCode,
                    tagLabel: parDtl.parameterDtlCode,
                    value: parDtl.parameterDtlCode,
                })) ?? []

                ChargeHeaderMaintenanceDispatch(prevState => ({
                    ...prevState,
                    dynamicOptions: {
                        ...prevState.dynamicOptions,
                        billingCycleDropdownOptions: [
                            ...billingCycleDropdownOptions]
                    }
                }))
            }
        )

        masterDataRepo.getMasterDataByKey('ServiceEntity').then(
            serviceCodes => {
                const serviceCodeDropdownOptions = serviceCodes?.map((serviceCode)=>({
                    dropdownLabel: serviceCode.code,
                    tagLabel: serviceCode.code,
                    value: serviceCode.code,
                })) ?? []

                ChargeHeaderMaintenanceDispatch(prevState => ({
                    ...prevState,
                    dynamicOptions: {
                        ...prevState.dynamicOptions,
                        serviceCodeDropdownOptions: [
                            ...serviceCodeDropdownOptions]
                    }
                }))
            }           
        )
    }
    const onDocumentActive = () => {
        ChargeHeaderMaintenanceDispatch(prevState => ({
            ...prevState, documentActive: !prevState.documentActive
        }))
    }

    const onCoVslVoyChange = (inputData: { co?: string, vsl?: string, voy?: string }, fieldName: { co: string, vsl: string, voy: string }) => {
        ChargeHeaderMaintenanceDispatch(prevState => ({
            ...prevState,
            searchCriteria: {
                ...prevState.searchCriteria,
                [fieldName.co]: inputData?.co,
                [fieldName.vsl]: inputData?.vsl,
                [fieldName.voy]: inputData?.voy,
            }
        }))
    }

    const onDropdownChange = (e: any, fieldName: string) => {
        ChargeHeaderMaintenanceDispatch(prevState => ({
            ...prevState,
            searchCriteria: {
                ...prevState.searchCriteria,
                [fieldName]: e?.value ?? (_.isEmpty(e) ? 
                    (fieldName === "subChgTypeList" ? [] : '') : e),
            }
        }))
    }

    const onDatePickerChange = (value: Date, fieldName: string) => {
        ChargeHeaderMaintenanceDispatch(prevState => ({
            ...prevState,
            searchCriteria: {
                ...prevState.searchCriteria,
                [fieldName]: value,
            }
        }))
    }

    const onDateRangeChange = (startDate: any, endDate: any,  dateFields:{startField:string, endField: string}) => {
        ChargeHeaderMaintenanceDispatch(prevState => ({
            ...prevState,
            searchCriteria: {
                ...prevState.searchCriteria,
                [dateFields.startField]: startDate,
                [dateFields.endField]: endDate,
            }
        }))
    }

    const onMultipleDropdownChange = (e: any, fieldName: string) => {
        ChargeHeaderMaintenanceDispatch(prevState => {
            return {
                ...prevState,
                searchCriteria: {
                    ...prevState.searchCriteria,
                    [fieldName]: _.uniq(e?.map((item: DropdownProps) => item.value)),
                }
            }
        });
    };

    const onCheckboxChange = (checked: boolean, fieldName: string) => {
        ChargeHeaderMaintenanceDispatch(prevState => ({
            ...prevState,
            searchCriteria: {
                ...prevState.searchCriteria,
                [fieldName]: checked,
            }
        }))
    }

    const onMultipleCheckboxChange = (e: (GroupCheckboxList | undefined)[], fieldNames: string[]) => {
        const value = fieldNames.reduce((obj, fieldName) => {
            return {
                ...obj,
                [fieldName]: !!e.find((checkboxProp) => checkboxProp?.key === fieldName),
            };
        }, {});
        ChargeHeaderMaintenanceDispatch(prevState => ({
            ...prevState,
            searchCriteria: {
                ...prevState.searchCriteria,
                ...value
            }
        }))
    }

    const onRadioChange = (e: any, fieldName: string) => {
        if(e.checked){

            ChargeHeaderMaintenanceDispatch(prevState => {
                const criteria = {...prevState.searchCriteria};

                if(e.key === 'SHIPBOUNDED'){
                    criteria.billingCycleList=[];
                    criteria.billingCycleCode=null;
                }

                if(e.key === 'SHIPBOUNDED' || e.key === 'SHIPBOUNDEDINPERIOD'){
                    criteria.billToCompList=[];
                }else{
                    criteria.soa=null;
                    criteria.vesselCode=null;
                    criteria.voyageCode=null;
                    criteria.vesselName=null;
                    criteria.vesselTypeList=[];
                    criteria.etdDateFrom=null;
                    criteria.etdDateTo=null;
                    criteria.etaDateFrom=null;
                    criteria.etaDateTo=null;
                    criteria.serviceCodeList=[];
                }
                const obj: ChargeHeaderMaintenanceModel = {
                    ...prevState,
                    searchCriteria: {
                        ...criteria,
                        [fieldName]: e.key
                    }
                };
                return { ...obj }
            })
        }
    };

    const onSliderRangeChange = (e: string, fieldName: string) => {
        const inputData = e ?? ''
        const inputDataInNumber = inputData === '' ? null : parseInt(inputData)
        ChargeHeaderMaintenanceDispatch(prevState => ({
            ...prevState,
            searchCriteria: {
                ...prevState.searchCriteria,
                [fieldName]: isNaN(inputDataInNumber ?? 0) ? prevState.searchCriteria[fieldName] : inputDataInNumber,
            }
        }))
    };

    const onInputTextChange = (e: ChangeEvent<HTMLInputElement>, fieldName: string, toUpperCase:boolean=false) => {
        const val = toUpperCase || fieldName === 'uniqueCntrNo' ? e.target.value.toString().toUpperCase() : e.target.value;
        ChargeHeaderMaintenanceDispatch(prevState => ({
            ...prevState,
            searchCriteria: {
                ...prevState.searchCriteria,
                [fieldName]: val,
            }
        }))
    };

    const onTextAreaChange = (e: ChangeEvent<HTMLTextAreaElement>, fieldName: string) => {
        ChargeHeaderMaintenanceDispatch(prevState => ({
            ...prevState,
            searchCriteria: {
                ...prevState.searchCriteria,
                [fieldName]: fieldName === 'cntrList' ? 
                    (e.target.value&&e.target.value.toString().includes(",") ? e.target.value.toString().split(",") : [e.target.value.toString()]) : 
                    e.target.value.toString(),
            }
        }))
    };

    const onExpandableToggleChange = (e: ChangeEvent<HTMLInputElement>, fieldName: string) => {
        ChargeHeaderMaintenanceDispatch(prevState => ({
            ...prevState,
            searchCriteria: {
                ...prevState.searchCriteria,
                [fieldName]: e.target.value
            }
        }))
    };

    const onSearchClick = () => {
        ChargeHeaderMaintenanceDispatch(prevState => ({
            ...prevState,
            isShowCriteriaPanel: !prevState.isShowCriteriaPanel,
        }))
    };

    // Save new preference
    const openSaveNewConfirmModal = () => {
        ChargeHeaderMaintenanceDispatch(prevState => ({ ...prevState, isShowSaveNewConfirmModal: true, }));
    };

    const openDeleteConfirmModal = () => {
        ChargeHeaderMaintenanceDispatch(prevState => ({
            ...prevState,
            isShowDeleteConfirmModal: true,
        }))
    };

    const closeConfirmModal = () => {
        ChargeHeaderMaintenanceDispatch(prevState => ({
            ...prevState,
            isShowSaveNewConfirmModal: false,
            isShowDeleteConfirmModal: false,
        }))
    };

    const setUserEmail = (userEmail: string) => {
        ChargeHeaderMaintenanceDispatch(prevState => ({
            ...prevState,
            userEmail: userEmail
        }))
    }

    const onSelectedChargeHeader = (data: ChargeHeaderEntity) => {
        ChargeHeaderMaintenanceDispatch(prevState => {
            const obj: ChargeHeaderMaintenanceModel = {
                ...prevState,
                currentChargeHeader: data,
            };
            return { ...obj }
        })
    }


    const goToChargeDetail = (data: ChargeHeaderEntity) => {
            ChargeHeaderMaintenanceDispatch(prevState => {
                const obj: ChargeHeaderMaintenanceModel = {
                    ...prevState,
                    isShowContainerDetail: true,
                    currentChargeHeader: data,
                };
                // customDispatchEvent(E_Type_Of_Event.CHARGE_HEADER_DETAIL_EVENT, E_Custom_Dispatch_Event.CHARGE_HEADER_GO_TO_DETAIL, data);

                return { ...obj }
            })
    }

    const goToVesselSummary = () => {
        ChargeHeaderMaintenanceDispatch(prevState => {
            const obj: ChargeHeaderMaintenanceModel = {
                ...prevState,
                isShowVesselSummary: !prevState.isShowVesselSummary,
            };
            return { ...obj }
        })
    }

    
    const goToInboundContainerSummary = () => {
        
        ChargeHeaderMaintenanceDispatch(prevState => {
            const obj: ChargeHeaderMaintenanceModel = {
                ...prevState,
                isShowInboundContainerSummary: true,
            };
            return { ...obj }
        })
    }

    const goToDeliveryChargeSummary = () => {
        
        ChargeHeaderMaintenanceDispatch(prevState => {
            const obj: ChargeHeaderMaintenanceModel = {
                ...prevState,
                isShowDeliveryChargeSummary: true,
            };
            return { ...obj }
        })
    }

    const onRefreshHeaderState = async (hdrDtos: ChargeHeaderEntity[]) => {
        onShowLoading();
        let hdrIds: number[] = (hdrDtos.filter(item => item.id !== null) as Array<{ id: number }>).map(item => item.id);  
        await chargeHeaderRepo.refreshHeaderState(hdrIds).then((resp) => {
            if(!resp.success){
                throw new Error(resp.msg as string);                
            }
        }).catch((error) =>{
            throw error;    
        }).finally(()=>{
            onHideLoading();
        })   
    }

    const validateDivertShip = async (chargeHdrDto: ChargeHeaderEntity) => {
        onShowLoading();
        return await chargeHeaderRepo.validateDivertShip(chargeHdrDto).then((data) => {
            if (data && data.toString().startsWith("Error:")) {
                ChargeHeaderMaintenanceDispatch(prevState => {
                    return {...prevState,
                        allFormState: {"divertShipValidationFail": data.toString()}
                    };
                });
                return {"divertShipValidationFail": data.toString()};
            } else {
                ChargeHeaderMaintenanceDispatch(prevState => {
                    const obj: ChargeHeaderMaintenanceModel = {
                        ...prevState,
                        isEditSearchCriteria: false,
                        isShowDivertShipPanel: true,                      
                        divertShipRequest: {
                            chargeHdrDtos: data,
                            divertShipFrom: "",                            
                        },
                    };
                    return { ...obj }
                });
                return  {"divertShipValidationSuccess":"successful"};
            }
            
        }).finally(()=>{
            onHideLoading();
        })   
    }

    const applyDivertShip = async (divertShipRequest: DivertShipRequestEntity) => {
        await chargeHeaderRepo.doDivertShip(divertShipRequest).then((data) => {
            onCloseDivertShipPanel();
        }).catch((error) =>{
            throw error;  
        }).finally(()=>{
        }) 
    }

    const onOpenDivertShipPanel = async() => {
        parameterDetailRepo.getAllParameterDtlsByParameterCode("DIVERT_SHIP_FROM").then(
            parDtls => {
                const divertShipFromDropdownOptions = parDtls?.map((parDtl) => ({
                    dropdownLabel: parDtl.parameterDtlCode,
                    tagLabel: parDtl.parameterDtlCode,
                    value: parDtl.parameterDtlCode,
                })) ?? []

                ChargeHeaderMaintenanceDispatch(prevState => ({
                    ...prevState,
                    dynamicOptions: {
                        ...prevState.dynamicOptions,
                        divertShipFromDropdownOptions: [
                            ...divertShipFromDropdownOptions
                        ],
                    }
                }))
            },            
        ).finally(()=>{
        }) 
    }    

    const onCloseDivertShipPanel = () => {
        ChargeHeaderMaintenanceDispatch(prevState => {
            const obj: ChargeHeaderMaintenanceModel = {
                ...prevState,
                isShowDivertShipPanel: false,
                divertShipRequest: EMPTY_DIVERT_SHIP_REQUEST,
            };
            return { ...obj }
        })
    }

    const onDivertShipDropdownChange = (e: any) => {
        ChargeHeaderMaintenanceDispatch(prevState => ({
            ...prevState,
            divertShipRequest:{
                 ...prevState.divertShipRequest,
                 divertShipFrom: e?.value,
            }
        }))
    }

    const onGroupCheckboxChange = (e: (GroupCheckboxList | undefined)[], searchCriteria:ChargeHeaderSearchCriteria, fieldName:string) => {
        if (fieldName === "toBill") {
            if (e) {
                searchCriteria = {...searchCriteria, [fieldName]:true}
            } else {
                searchCriteria = {...searchCriteria, [fieldName]:false}
            }
        } else {
            let selectedValue:string[] = [];
            if (e) {
                e.forEach(function(value, index) {
                    if (value) {
                        selectedValue.push(value.key);
                    }
                });
            }
            searchCriteria = {...searchCriteria, [fieldName]:selectedValue}
        }
        ChargeHeaderMaintenanceDispatch(prevState => ({
            ...prevState,
            searchCriteria : {
                ...prevState.searchCriteria,
                ...searchCriteria,
            }
        }));
    };

    const toggleDivertShipPanel = async (isShowDivertShipPanel: boolean, editingHeader: ChargeHeaderEntity | null) => {
        if(isShowDivertShipPanel){
            ChargeHeaderMaintenanceDispatch(prevState => ({
                ...prevState,
                isShowDivertShipPanel: false,
                divertShipRequest: EMPTY_DIVERT_SHIP_REQUEST,
            }))
        }else{
            if(editingHeader && editingHeader.id){                
                return await validateDivertShip(editingHeader)
            }else{
                console.warn('Invalid header.')
            }
        }
    }

    const validateSearchCriteria = (searchCriteria: ChargeHeaderSearchCriteria) => {
        if(searchCriteria){

            for (const key in searchCriteria) {
                if (searchCriteria.hasOwnProperty(key)) {
                    if(key !== "hdrGroupName" && key !== "tempHdrGroupName"){
                        const value = searchCriteria[key];
                        if (value !== null 
                            && value !== "" 
                            && !(Array.isArray(value) && value.length === 0) 
                            && !(typeof value === "boolean" && value === false)) {
                            return false; 
                          }
                    }
                }
            }
            return true;
        }
        return true;
    }



    return {
        onPageInit: onPageInit,
        onRefresh: onRefresh,
        onWSMessage: onWSMessage,
        onSwitchSearchCriteriaMode,
        onCriteriaDirectoryDropdown: onCriteriaDirectoryDropdown,
        onCloseScreen: onCloseScreen,
        onShowLoading: onShowLoading,
        onHideLoading: onHideLoading,
        updateSelectedChargeHeaders: updateSelectedChargeHeaders,
        updateCheckboxRef: updateCheckboxRef,
        updateCheckboxValue: updateCheckboxValue,
        onSaveCheckboxValue: onSaveCheckboxValue,
        onResetCheckboxValue: onResetCheckboxValue,
        onRemoveSearchCriteria: onRemoveSearchCriteria,
        onRemoveAllSearchCriteria: onRemoveAllSearchCriteria,
        onHideRightCriteriaPanel,
        onEditSearchCriteria: onEditSearchCriteria,
        onSearch,
        loadDropdownOption: loadDropdownOption,
        onDocumentActive: onDocumentActive,
        // onDamageCodeActive: onDamageCodeActive,
        // onHoldConditionCodeActive: onHoldConditionCodeActive,
        // onEquipmentCodeActive: onEquipmentCodeActive,
        // onSpecialHandlingConditionCodeActive: onSpecialHandlingConditionCodeActive,
        // onDangerousCodeActive: onDangerousCodeActive,
        // onReeferConditionCodeActive: onReeferConditionCodeActive,
        onCoVslVoyChange: onCoVslVoyChange,
        // onYardLocationRangeChange: onYardLocationRangeChange,
        onDatePickerChange: onDatePickerChange,
        onDateRangeChange: onDateRangeChange,
        onDropdownChange: onDropdownChange,
        onMultipleDropdownChange: onMultipleDropdownChange,
        onCheckboxChange: onCheckboxChange,
        onMultipleCheckboxChange: onMultipleCheckboxChange,
        onRadioChange: onRadioChange,
        onSliderRangeChange: onSliderRangeChange,
        onInputTextChange: onInputTextChange,
        onTextAreaChange: onTextAreaChange,
        onExpandableToggleChange: onExpandableToggleChange,
        openSaveNewConfirmModal: openSaveNewConfirmModal,
        openDeleteConfirmModal: openDeleteConfirmModal,
        closeConfirmModal: closeConfirmModal,
        setUserEmail: setUserEmail,
        onSelectedChargeHeader: onSelectedChargeHeader,
        onRefreshHeaderState: onRefreshHeaderState,
        goToChargeDetail: goToChargeDetail,
        goToVesselSummary: goToVesselSummary,
        goToInboundContainerSummary: goToInboundContainerSummary,
        goToDeliveryChargeSummary: goToDeliveryChargeSummary,
        validateDivertShip: validateDivertShip,
        applyDivertShip: applyDivertShip,
        onOpenDivertShipPanel: onOpenDivertShipPanel,
        onCloseDivertShipPanel: onCloseDivertShipPanel,
        onDivertShipDropdownChange: onDivertShipDropdownChange,
        toggleDivertShipPanel: toggleDivertShipPanel,
        onSearchClick: onSearchClick,
        onGroupCheckboxChange: onGroupCheckboxChange,
        onSwithTableStructure: onSwithTableStructure,
        validateSearchCriteria: validateSearchCriteria,
    }
}