import { directDischargeIndDropdownOption } from "presentation/constant/DropDownOptions/StaticDropdownOptions";
import { useChargeDetailVM } from "presentation/hook/ChargeDetailMaintenance/useChargeDetailVM";
import { ChargeDetailModel } from "presentation/model/ChargeDetailMaintenance/ChargeDetailModel";
import { InputDropdown } from "veronica-ui-component/dist/component/core";

export const SecondLegInfoAccordion = ({chargeDetailState}:{chargeDetailState:ChargeDetailModel}) => {
    const chargeBatchUpdate = chargeDetailState.chargeBatchUpdate
    const chargeDetailVM = useChargeDetailVM();
    return (
        <>
            {/* <div className={'im-flex-row'}  style={{marginBottom:'24px'}}>
                <div className='im-flex-row-item'>
                    <InputDropdown
                        label='Special Ind'
                        width='330px'
                        inputType="freeText"
                        mode={"single"}
                        disabled={!chargeBatchUpdate.seclegSpecialIndEnable}
                        value={chargeBatchUpdate.seclegSpecialInd}
                        onChange={(e) => chargeDetailVM.onDropdownChange(e, 'seclegSpecialInd', chargeBatchUpdate, 'chargeBatchUpdate')}
                        options={chargeDetailState.dynamicOptions.specialIndDropdownOptions}/>
                </div>
            </div> */}
            <div className={'im-flex-row'}  style={{marginBottom:'24px'}}>
                <div className='im-flex-row-item'>
                    <InputDropdown
                        label='Direct/Indirect Discharge Ind'
                        width='330px'
                        inputType="freeText"
                        mode={"single"}
                        disabled={!chargeBatchUpdate.seclegDischargeIndEnable}
                        value={chargeBatchUpdate.seclegDischargeInd}
                        onChange={(e) => chargeDetailVM.onDropdownChange(e, 'seclegDischargeInd', chargeBatchUpdate, 'chargeBatchUpdate')}
                        options={directDischargeIndDropdownOption}/>
                 </div>
            </div>
            <div className={'im-flex-row'}  style={{marginBottom:'24px'}}>
                <div className='im-flex-row-item'>
                    <InputDropdown
                        label='Operating Tml'
                        width='330px'
                        inputType="freeText"
                        mode={"single"}
                        disabled={!chargeBatchUpdate.seclegOperatingTmlEnable}
                        value={chargeBatchUpdate.seclegOperatingTml}
                        onChange={(e) => chargeDetailVM.onDropdownChange(e, 'seclegOperatingTml', chargeBatchUpdate, 'chargeBatchUpdate')}
                        options={chargeDetailState.dynamicOptions.operatingTmlDropdownOptions}/>
                </div>
            </div>
        </>
    )
}