import { ContainerInformationEntity } from "./ContainerInformationEntity";

export interface VesselSummaryEntity {

    cntrInfoList: ContainerInformationEntity[],
    dischargeFullTotal: number | null,
    dischargeEmptyTotal: number | null,
    loadingFullTotal: number | null,
    loadingEmptyTotal: number | null,

}

export const EMPTY_VESSEL_SUMMARY_DATA_ENTITY : VesselSummaryEntity = {
    cntrInfoList: [],
    dischargeFullTotal: 0,
    dischargeEmptyTotal: 0,
    loadingFullTotal: 0,
    loadingEmptyTotal: 0,
}