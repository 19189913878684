import { ChargeHeaderEntity } from "./ChargeHeaderEntity";

export interface DivertShipRequestEntity {
    divertShipFrom: string,
    chargeHdrDtos: ChargeHeaderEntity[],
}

export const EMPTY_DIVERT_SHIP_REQUEST : DivertShipRequestEntity ={
    divertShipFrom: "",
    chargeHdrDtos: []
}