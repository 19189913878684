import { useVesselSummaryMaintenanceVM } from "presentation/hook/ChargeHeaderMaintenance/useVesselSummaryMaintenanceVM"
import { useChargeHeaderMaintenanceTracked } from "presentation/store/ChargeHeaderMaintenance/ChargeHeaderMaintenanceProvider"
import { useVesselSummaryMaintenanceTracked } from "presentation/store/ChargeHeaderMaintenance/VesselSummaryMaintenanceProvider"
import { useEffect } from "react"
import { Loader, SliderPanel } from "veronica-ui-component/dist/component/core"
import { VesselSummaryTDRPanel } from "./VesselSummaryTDRPanel"
import { VesselSummaryTablePanel } from "./VesselSummaryTablePanel"

export const VesselSummaryContentPanel: React.FC = () => {
    const [chargeHdrState] = useChargeHeaderMaintenanceTracked();
    const [vslSummaryState] = useVesselSummaryMaintenanceTracked();
    const vslSumMainVM = useVesselSummaryMaintenanceVM();
    const isShowTdrPanel = vslSummaryState.isShowTdrPanel;    
    const editingHdr = chargeHdrState.currentChargeHeader;

    useEffect(() => {
        vslSumMainVM.onPageLoad(editingHdr)
    }, [editingHdr, vslSumMainVM]);

    //if (vslSummaryState.isLoading) return <Loader Indicator="Stripe" size="Large" />;

        return (
            <div className="main-comp-wrapper">
                {vslSummaryState.isLoading && <Loader Indicator="Stripe" size="Large" />}
                <SliderPanel
                    isOpen={true}
                    draggable={false}
                    leftSectionWidth={isShowTdrPanel?"40%":"100%"}
                    rightSectionWidth={isShowTdrPanel?"60%":"0%"}
                    leftChildren={<VesselSummaryTablePanel/>}
                    rightChildren={<VesselSummaryTDRPanel/>}
                />
            </div>
    )

}



