import { ChargeDetailSeqCriteria } from "domain/entity/ChargeDetailMaintenance/ChargeDetailSeqCriteria";
import { axiosPostData } from "../axios/AxiosBasicImpl";
import chargeAxiosInstance from "../axios/chargeAxiosInstance";
import { ChargeDetailSeqCriteriaRepository } from "./ChargeDetailSeqCriteriaRepo";

export const ChargeDetailSeqCriteriaRepoImpl = () : ChargeDetailSeqCriteriaRepository => {

    const initForm = async(searchCriteria: ChargeDetailSeqCriteria) : Promise<ChargeDetailSeqCriteria> => {
        const newUrl = "/v1/initChargeDetailSeqForm"
        
        return axiosPostData(chargeAxiosInstance, `${newUrl}`, searchCriteria).then(res => {
            const data = res.data;
            // data.seqList.unshift(CHARGEDETAIL_CONSTANT.NEW_SEQ)
            return data;
        }).catch(err => {
            return [];
        });
    }

    const updateForm = async(searchCriteria: ChargeDetailSeqCriteria) : Promise<ChargeDetailSeqCriteria> => {
        const newUrl = "/v1/updateChargeDetailSeqForm"
        
        return axiosPostData(chargeAxiosInstance, `${newUrl}`, searchCriteria).then(res => {
            if (res.msg === "OK") {
                return res.data;
            } else {
                let errorMessage = "Assign seq. no. Failure."
                if (res.data && typeof (res.data) === "string") {
                    errorMessage = res.data;
                }
                return "Error:" + errorMessage; 
            }
        }).catch(err => {
            return "Error:" + err.Message;
        });
    }

    const batchUpdate = async(searchCriteria: ChargeDetailSeqCriteria) : Promise<boolean> => {
        const newUrl = "/v1/chargeDetailSeqBatchUpdate"
        return axiosPostData(chargeAxiosInstance, `${newUrl}`, searchCriteria).then(res => {
            if (res.msg === "OK") {
                return res.data;
            } else {
                let errorMessage = "Assign seq. no. Failure."
                if (res.data && typeof (res.data) === "string") {
                    errorMessage = res.data;
                }
                return "Error:" + errorMessage; 
            }
        }).catch(err => {
            return "Error:" + err.Message;
        });
    }
    
    return {
        initForm: initForm,
        updateForm: updateForm,
        batchUpdate: batchUpdate,
    }
}