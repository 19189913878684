import { useVesselSummaryMaintenanceTracked } from "presentation/store/ChargeHeaderMaintenance/VesselSummaryMaintenanceProvider";
import { Loader } from "veronica-ui-component/dist/component/core";
import { VesselSummaryContentPanel } from "./VesselSummaryContentPanel";
import { VesselSummaryHeaderFieldPanel } from "./VesselSummaryHeaderFieldPanel";
import VesselSummaryTitleBar from "./VesselSummaryTitleBar";


export const VesselSummaryComp: React.FC = () => {

    const [vslSummaryTracked] = useVesselSummaryMaintenanceTracked();
    // const isShowSummaryHeaderFieldPanel = vslSummaryTracked.isShowSummaryHeaderFieldPanel;
    return <>
        {vslSummaryTracked.isLoading && <Loader Indicator={"Stripe"} size={"Large"} />}
        <div className="main-comp-wrapper">
            <VesselSummaryTitleBar/>
            <VesselSummaryHeaderFieldPanel/>
            <VesselSummaryContentPanel/>
            {/* <SliderPanel
                isOpen={true}
                draggable={false}
                leftSectionWidth={isShowSummaryHeaderFieldPanel?"20%":"0%"}
                rightSectionWidth={isShowSummaryHeaderFieldPanel?"80%":"100%"}
                leftChildren={<VesselSummaryHeaderFieldPanel/>}
                rightChildren={<VesselSummaryContentPanel/>}
               /> */}
        </div>
    </>
}