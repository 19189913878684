import { ChargeDetailConstant } from "presentation/constant/ChargeDetailMaintenance/ChargeDetailConstant";
import { CriteriaDirectoryChargeCheckboxOption } from "presentation/constant/ChargeDetailMaintenance/ChargeDetailCriteriaDirectoryCheckboxOption";
import { useChargeDetailVM } from "presentation/hook/ChargeDetailMaintenance/useChargeDetailVM";
import { ChargeDetailModel } from "presentation/model/ChargeDetailMaintenance/ChargeDetailModel";
import { CriteriaItemContainer } from "presentation/view/components/CriteriaItemContainer";
import DatePickerComponent from "presentation/view/components/DatePickerComponent";
import { memo, useMemo } from "react";
import { HPHInputMask, InputField } from "veronica-ui-component/dist/component/core";

const UpdateVesselVoyageMasterSearchForm = ({chargeDetailState}:{chargeDetailState:ChargeDetailModel}) => {    
    const VESSEL_CONSTANT = ChargeDetailConstant.Charge;
    const vesselVoyageMasterSearchCriteria = chargeDetailState.searchVslVoyCriteria;
    const chargeDetailVM = useChargeDetailVM();
    const enabledCriteria = useMemo(() => CriteriaDirectoryChargeCheckboxOption.find(item => chargeDetailState.enabledSearchCriteria[item.key]), [chargeDetailState.enabledSearchCriteria])
    const allFormState = chargeDetailState.allFormState;

    const memoCoVslVoy = useMemo(() =>
        <div className='im-flex-row-item'>
            <HPHInputMask
                label={VESSEL_CONSTANT.CO_VSL_VOY}
                value={{
                    co: vesselVoyageMasterSearchCriteria.consortiumCode ?? '',
                    vsl: vesselVoyageMasterSearchCriteria.vesselCode ?? '',
                    voy: vesselVoyageMasterSearchCriteria.voyageCode ?? ''
                }}
                width="330px"
                fieldConfiguration={{
                    co: { maxLength: 60, autoComplete: "off", valueInNormalCase: true, isNumeric: false },
                    vsl: { maxLength: 10, autoComplete: "off", valueInNormalCase: true, isNumeric: false },
                    voy: { maxLength: 10, autoComplete: "off", valueInNormalCase: true, isNumeric: false },
                }}
                onDataChange={(e: any) => chargeDetailVM.onCoVslVoyChange(e, {
                    co: 'consortiumCode',
                    vsl: 'vesselCode',
                    voy: 'voyageCode'
                })}/>
        </div>
    , [vesselVoyageMasterSearchCriteria.consortiumCode, vesselVoyageMasterSearchCriteria.vesselCode, vesselVoyageMasterSearchCriteria.voyageCode,
        chargeDetailVM, VESSEL_CONSTANT.CO_VSL_VOY])

    const memoVesselName = useMemo(() =>
        <div className='flex-row-item'>
            <InputField
                width='330px'
                maxLength={30}
                label={VESSEL_CONSTANT.VESSEL_NAME}
                type="text"
                value={vesselVoyageMasterSearchCriteria.vesselName ?? ''}
                onChange={(e: any) => chargeDetailVM.onSearchVslVoyInputTextChange(e, 'vesselName')}
                />
        </div>
    , [vesselVoyageMasterSearchCriteria.vesselName, chargeDetailVM, VESSEL_CONSTANT.VESSEL_NAME])

    const memoVslStartOpsDateTime = useMemo(() =>
        <div className='flex-row-item'>
            <DatePickerComponent
                // disabled={isRead}
                label={VESSEL_CONSTANT.ARRIVAL_DATE_TIME}
                width="330px"
                date={vesselVoyageMasterSearchCriteria?.vslStartOpsDateTime}
                fieldName="vslStartOpsDateTime"
                errorMessage={allFormState?allFormState["vslStartOpsDateTime"]:""}
                onDateChange={chargeDetailVM.onVslVoyDateFieldChange}/>                
        </div>
    , [vesselVoyageMasterSearchCriteria.vslStartOpsDateTime, chargeDetailVM, allFormState, VESSEL_CONSTANT.ARRIVAL_DATE_TIME])

    const memoVslEndOpsDateTime = useMemo(() =>
        <div className='flex-row-item'>
            <DatePickerComponent
                // disabled={isRead}
                label={VESSEL_CONSTANT.DEPARTURE_DATE_TIME}
                width="330px"
                date={vesselVoyageMasterSearchCriteria?.vslEndOpsDateTime}
                fieldName="vslEndOpsDateTime"
                errorMessage={allFormState?allFormState["vslEndOpsDateTime"]:""}
                onDateChange={chargeDetailVM.onVslVoyDateFieldChange}/>    
        </div>
    , [vesselVoyageMasterSearchCriteria.vslEndOpsDateTime, chargeDetailVM, allFormState, VESSEL_CONSTANT.DEPARTURE_DATE_TIME])

    if (!enabledCriteria) return null;

    return <>
        <CriteriaItemContainer>
            {chargeDetailState.enabledSearchCriteria.coVslVoy ? memoCoVslVoy : null}
        </CriteriaItemContainer>

        <CriteriaItemContainer>
                {chargeDetailState.enabledSearchCriteria.vesselName ? memoVesselName : null}
        </CriteriaItemContainer>
        <CriteriaItemContainer>
            {chargeDetailState.enabledSearchCriteria.vslStartOpsDateTime ? memoVslStartOpsDateTime : null}
        </CriteriaItemContainer>
        <CriteriaItemContainer>
            {chargeDetailState.enabledSearchCriteria.vslEndOpsDateTime ? memoVslEndOpsDateTime : null}
        </CriteriaItemContainer>
    </>;
}
export default memo(UpdateVesselVoyageMasterSearchForm);