import { useChargeHeaderMaintenanceTracked } from "presentation/store/ChargeHeaderMaintenance/ChargeHeaderMaintenanceProvider";
import ChargeDetailContMain from "presentation/view/container/ChargeDetailMaintenance/ChargeDetailContMain";
import { DeliveryChargeSummaryMaintenanceContainer } from "presentation/view/container/ChargeHeaderMaintenance/Summary/DeliveryChargeSummaryMaintenanceContainer";
import { InboundContainerSummaryMaintenanceContainer } from "presentation/view/container/ChargeHeaderMaintenance/Summary/InboundContainerSummaryMaintenanceContainer";
import { VesselSummaryMaintenanceContainer } from "presentation/view/container/ChargeHeaderMaintenance/Summary/VesselSummaryMaintenanceContainer";
import { useMemo } from "react";
import { ChargeHeaderMaintenanceContent } from "./ChargeHeaderMaintenanceContent";
import { ChargeHeaderMaintenanceHeader } from "./ChargeHeaderMaintenanceHeader";

export const ChargeHeaderMaintenanceLayout: React.FC = () => {
     const [chargeHeaderState] = useChargeHeaderMaintenanceTracked();
     const isShowContainerDetail = chargeHeaderState.isShowContainerDetail;
     const isShowVesselSummary = chargeHeaderState.isShowVesselSummary;
     const isShowInboundContainerSummary = chargeHeaderState.isShowInboundContainerSummary;
     const isShowDeliveryChargeSummary = chargeHeaderState.isShowDeliveryChargeSummary;

     const memoChargeHeaderMaintenanceHeader = useMemo(() => {
        return <ChargeHeaderMaintenanceHeader/>
     }, []);
     
   return <>
        {!isShowContainerDetail 
            && !isShowVesselSummary 
            && !isShowInboundContainerSummary
            && !isShowDeliveryChargeSummary
            && <div className="main-comp-wrapper" >
            {memoChargeHeaderMaintenanceHeader}
            <ChargeHeaderMaintenanceContent/>
        </div>}
    {
        isShowContainerDetail && <ChargeDetailContMain/>
    }    
    {
        isShowVesselSummary && <VesselSummaryMaintenanceContainer/>
    }      
    {
        isShowInboundContainerSummary && <InboundContainerSummaryMaintenanceContainer/>
    }    
    {
        isShowDeliveryChargeSummary && <DeliveryChargeSummaryMaintenanceContainer/>
    } 

    </>
}
