import { Permission } from "presentation/constant/ANAInfo/PermissionName";
import { DeliveryChargeSummaryMaintenanceProvider } from "presentation/store/ChargeHeaderMaintenance/DeliveryChargeSummaryMaintenanceProvider";
import { ANAInfoWrapper } from "presentation/view/components/ANAInfo/ANAInfoWrapper";
import { DeliveryChargeSummaryComp } from "presentation/view/section/ChargeHeaderMaintenance/DeliveryChargeSummary/DeliveryChargeSummaryComp";
import { GridStyles } from "veronica-ui-component/dist/component/core/styled/table.styled";

export const DeliveryChargeSummaryMaintenanceContainer = () => {
    return <ANAInfoWrapper permission={Permission.LOGIN}>
        <DeliveryChargeSummaryMaintenanceProvider>
            <GridStyles/>
            <DeliveryChargeSummaryComp />
        </DeliveryChargeSummaryMaintenanceProvider>
    </ANAInfoWrapper>;
};