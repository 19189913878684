import { useChargeDetailVM } from "presentation/hook/ChargeDetailMaintenance/useChargeDetailVM";
import { ChargeDetailModel } from "presentation/model/ChargeDetailMaintenance/ChargeDetailModel";
import DateTimePickerComponent from "presentation/view/components/DateTimePickerComponent";

export const TSVesselInfoAccordion  = ({chargeDetailState}:{chargeDetailState:ChargeDetailModel}) => {
    const chargeBatchUpdate = chargeDetailState.chargeBatchUpdate
    const chargeDetailVM = useChargeDetailVM();
    return (
        <>
            <div className={'im-flex-row'} style={{marginBottom:'24px'}}>
                <div className='im-flex-row-item'>
                    <DateTimePickerComponent
                        label='1st Leg Arr Date/Time'
                        width='330px'
                        date={chargeBatchUpdate.otherArrDatetime}
                        onDateChange={chargeDetailVM.onDatePickerChange}
                        fieldName={"otherArrDatetime"}/>
                        
                </div>
            </div>

            <div className={'im-flex-row'} style={{marginBottom:'24px'}}>
                <div className='im-flex-row-item'>
                    <DateTimePickerComponent
                        label='1st Leg Dep Date/Time'
                        width='330px'
                        date={chargeBatchUpdate.otherDepDatetime}
                        onDateChange={chargeDetailVM.onDatePickerChange}
                        fieldName={"otherDepDatetime"}/>
                        
                </div>
            </div>

            {/*<div className={'im-flex-row'} style={{marginBottom:'24px'}}>
                <div className='im-flex-row-item'>
                    <DateTimePickerComponent
                        label='1st Leg Discharge Date/Time'
                        width='330px'
                        date={chargeBatchUpdate.otherDischargeDatetime}
                        onDateChange={chargeDetailVM.onDatePickerChange}
                        fieldName={"otherDischargeDatetime"}/>
                        
                </div>
            </div>*/}

            <div className={'im-flex-row'} style={{marginBottom:'24px'}}>
                <div className='im-flex-row-item'>
                    <DateTimePickerComponent
                        label='1st Leg First Discharge Date/Time'
                        width='330px'
                        date={chargeBatchUpdate.otherFirstDischargeDatetime}
                        onDateChange={chargeDetailVM.onDatePickerChange}
                        fieldName={"otherFirstDischargeDatetime"}/>
                        
                </div>
            </div>

            <div className={'im-flex-row'} style={{marginBottom:'24px'}}>
                <div className='im-flex-row-item'>
                    <DateTimePickerComponent
                        label='1st Leg Last Discharge Date/Time'
                        width='330px'
                        date={chargeBatchUpdate.otherLastDischargeDatetime}
                        onDateChange={chargeDetailVM.onDatePickerChange}
                        fieldName={"otherLastDischargeDatetime"}/>
                        
                </div>
            </div>
        </>
    )
}