export interface VesselVoyageMasterSearchCriteria{
    consortiumCode?: string | null,
    vesselCode?: string | null,
    voyageCode?: string | null,
	vesselName?:  string | null,	
    vslStartOpsDateTime?: Date | null,
    vslEndOpsDateTime?: Date | null,
    handlingTerminal?:  string | null,	
    currentVesselCode?: string | null,
	currentVoyageCode?: string | null,
	currentHandlingTerminal?: string | null,

    [key: string]: string | Date | null | undefined
}

export const EMPTY_VESSEL_VOYAGE_MASTER_SEARCH_CRITERIA : VesselVoyageMasterSearchCriteria = {
    consortiumCode: null,
    vesselCode: null,
    voyageCode: null,
	vesselName: null,
    vslStartOpsDateTime: null,
    vslEndOpsDateTime: null,
    handlingTerminal: null,
    currentVesselCode: null,
	currentVoyageCode: null,
	currentHandlingTerminal: null,
}