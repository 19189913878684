import { useChargeDetailVM } from "presentation/hook/ChargeDetailMaintenance/useChargeDetailVM";
import { ChargeDetailModel } from "presentation/model/ChargeDetailMaintenance/ChargeDetailModel";
import { InputField } from "veronica-ui-component/dist/component/core";

export const WaiverNumberAccordion = ({chargeDetailState}:{chargeDetailState:ChargeDetailModel}) => {
    const chargeBatchUpdate = chargeDetailState.chargeBatchUpdate
    const chargeDetailVM = useChargeDetailVM();
    return (
        <>
            <div className={'im-flex-row'}  style={{marginBottom:'24px'}}>
            
                <div className='im-flex-row-item'>
                    <InputField
                        label='Waiver No.'
                        width='330px'
                        type="text"
                        maxLength={50}
                        value={chargeBatchUpdate.waiverNumber || ''}
                        onChange={(e: any) => chargeDetailVM.onInputTextChange(e, 'waiverNumber', chargeBatchUpdate, 'chargeBatchUpdate')}/>
                </div>
                
            </div>
        </>
    )
}