export interface DeliveryChargeSummarySearchCriteria {    
    loginUser?: string | null,
    vesselCode?: string | null,
    voyageCode?: string | null,
    vesselName?: string | null,
    billingCycleList?: string[] | null,
    billingCycleCode?: string | null,
    soa?: string | null,
    etaDateFrom?: Date | null,
    etaDateTo?: Date | null,
    etdDateFrom?: Date | null,
    etdDateTo?: Date | null,
    serviceCodeList?: string[] | null,

    [key: string]: string | Date | string[] | number | null | undefined | boolean
}

export const EMPTY_DELIVERY_CHARGE_SUMMARY_SEARCH_CRITERIA : DeliveryChargeSummarySearchCriteria = {
    loginUser: '',
    vesselCode: '',
    voyageCode: '',
    vesselName: '',
    billingCycleList: [],
    billingCycleCode: '',
    soa: '',
    etaDateFrom: null,
    etaDateTo: null,
    etdDateFrom: null,
    etdDateTo: null,
    serviceCodeList: [],
}