export interface InboundContainerSummarySearchCriteria {    
    chargeHdrId: number | null,
    deliveryStatusList?: string[] | null,

    [key: string]: string[] | number | null | undefined
}

export const EMPTY_INBOUND_CONTAINER_SUMMARY_SEARCH_CRITERIA : InboundContainerSummarySearchCriteria = {
    chargeHdrId: null,
    deliveryStatusList: [],
}