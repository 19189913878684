import { DeliveryChargeSummaryEntity } from "domain/entity/ChargeHeader/DeliveryChargeSummaryEntity";
import { DeliveryChargeSummaryRequestEntity } from "domain/entity/ChargeHeader/DeliveryChargeSummaryRequestEntity";
import { axiosPostData } from "../axios/AxiosBasicImpl";
import chargeAxiosInstance from "../axios/chargeAxiosInstance";
import { DeliveryChargeSummaryRepository } from "./DeliveryChargeSummaryRepo";

export const DeliveryChargeSummaryRepoImpl = () : DeliveryChargeSummaryRepository => {
    const delChgSummaryDataUrl = "/v1/deliverychargesummarydatasearch"
    const searchCntrsUrl = "/v1/deliverychargesummarysearchcontainer"


    const getDeliveryChargeSummaryData = async (request: DeliveryChargeSummaryRequestEntity) : Promise<DeliveryChargeSummaryEntity[]> => {
        try {
            const res = await axiosPostData(chargeAxiosInstance, `${delChgSummaryDataUrl}`, request);
            const data = res.data;
            return data;
        } catch (err) {
            return [];
        }
    }

    const getDeliveryChargeSummaryCntrs = async (request: DeliveryChargeSummaryEntity) : Promise<string[]> => {
        try {
            const res = await axiosPostData(chargeAxiosInstance, `${searchCntrsUrl}`, request);
            const data = res.data;
            return data;
        } catch (err) {
            return [];
        }

    }
    return {
        getDeliveryChargeSummaryData: getDeliveryChargeSummaryData,
        getDeliveryChargeSummaryCntrs: getDeliveryChargeSummaryCntrs,
    }
}