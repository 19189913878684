export interface EnabledSearchCriteria {
    all: boolean,
    cntrNos:boolean,
    tariffCodes:boolean,
    billToCompanies:boolean,
    chargeOnCompanies:boolean,
    bundleInds:boolean,
    cntrSizes:boolean,
    chargeTypes: boolean,
    subChargeTypes: boolean,
    bundleMasterNo: boolean,
    cntrTypes: boolean,
    adjTypes:boolean,
    arrivalVesselCode: boolean,
    arrivalVoyageCode: boolean,
    statusCOKDs: boolean,
    emptyInd: boolean,
    operationTypes:boolean,
    departureVesselCode: boolean,
    departureVoyageCode: boolean,
    specialHandlingInd:boolean,
    specialHandlingIndD1:boolean,
    cntrStatus:boolean,
    docNo: boolean,
    modalityArrivals: boolean,
    modalityDepartures: boolean,
    states:boolean,
    opsLines: boolean,
    seqNo: boolean,
    marshallingTypes: boolean
    coVslVoy: boolean,
    forwarderCodes: boolean,
    chargeCodes: boolean,
    vesselName: boolean,
    arrivalDatetime: boolean,
    departureDatetime: boolean,
    consortiumCode: boolean,
    vesselCode: boolean,
    voyageCode: boolean,
    vslStartOpsDateTime: boolean,
    vslEndOpsDateTime: boolean,

    [key: string]: boolean,
}

export const DEFAULT_ENABLED_CHARGE_DETAIL_SEARCH_CRITERIA: EnabledSearchCriteria = {
    all: true,
    cntrNos:true,
    tariffCodes:true,
    billToCompanies:true,
    chargeOnCompanies:true,
    bundleInds:true,
    cntrSizes:true,
    chargeTypes: true,
    subChargeTypes: true,
    bundleMasterNo: true,
    cntrTypes: true,
    adjTypes:true,
    arrivalVesselCode: true,
    arrivalVoyageCode: true,
    statusCOKDs: true,
    emptyInd: true,
    operationTypes:true,
    departureVesselCode: true,
    departureVoyageCode: true,
    specialHandlingInd:true,
    specialHandlingIndD1:true,
    cntrStatus:true,
    docNo: true,
    modalityArrivals: true,
    modalityDepartures: true,
    states:true,
    opsLines: true,
    seqNo: true,
    marshallingTypes: true,
    coVslVoy: true,
    forwarderCodes: true,
    chargeCodes: true,
    vesselName: true,
    arrivalDatetime: true,
    departureDatetime: true,
    consortiumCode: true,
    vesselCode: true,
    voyageCode: true,
    vslStartOpsDateTime: true,
    vslEndOpsDateTime: true,
}