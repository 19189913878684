import { InboundContainerSummaryRepoImpl } from "domain/repository/ChargeHeader/InboundContainerSummaryRepoImpl";
import { SearchPreferenceRepoImpl } from "domain/repository/Common/SearchPreferenceRepoImpl";
import { useChargeHeaderMaintenanceTracked } from "presentation/store/ChargeHeaderMaintenance/ChargeHeaderMaintenanceProvider";
import { useInboundContainerSummaryMaintenanceTracked } from "presentation/store/ChargeHeaderMaintenance/InboundContainerSummaryMaintenanceProvider";
import { InboundContainerSummaryMaintenanceVM } from "presentation/viewModel/ChargeHeaderMaintenance/InboundContainerSummaryMaintenanceVM";
import { useMemo } from "react";

export const useInboundContainerSummaryMaintenanceVM = () => {
    const [, setInboundContainerSummaryMaintenanceState] = useInboundContainerSummaryMaintenanceTracked();
    const [, setChargeHeaderMaintenanceState] = useChargeHeaderMaintenanceTracked();
    const inboundContainerSummaryMaintenanceVM = useMemo(() =>
        InboundContainerSummaryMaintenanceVM({
            dispatch: [setInboundContainerSummaryMaintenanceState],
            headerDispatch: [setChargeHeaderMaintenanceState],
            searchPreferenceRepo: SearchPreferenceRepoImpl(),
            ibCntrSummaryRepo: InboundContainerSummaryRepoImpl(),
        }), [setChargeHeaderMaintenanceState, setInboundContainerSummaryMaintenanceState])

    return inboundContainerSummaryMaintenanceVM
}