import { ChargeConstant } from "presentation/constant/ChargeHeader/ChargeConstant";
import { defineColumn, transferRowDataInternal } from "presentation/view/components/TableWrapper/BasicTableConstants";

const CHARGE_HEADER_CONSTANT = ChargeConstant.ChargeHeader;
let domesticDateFieldList:string[] = [];
let domesticDateTimeFieldList:string[] = [];
let shipboundedDateFieldList:string[] = [];
let shipboundedDateTimeFieldList:string[] = [];
let shipboundedInPeriodDateFieldList:string[] = [];
let shipboundedInPeriodDateTimeFieldList:string[] = [];

export const INITIAL_SHIPBOUNDED_CHARGE_HEADER_COL_DEF: any[] = [
    {
        headerName: CHARGE_HEADER_CONSTANT.USER,
        field: 'user',
        enableRowGroup: true,
        enablePivot: true,
        enableValue: true,
        filter: false,
        pinned: "left",
        checkboxSelection: true,
        headerCheckboxSelection: true,
        sortable: true,
        width: 120,
    },
    {
        headerName: CHARGE_HEADER_CONSTANT.SOA,
        field: 'shipOwner',
        enableRowGroup: true,
        enablePivot: true,
        enableValue: true,
        filter: false,
        sortable: true,
        width: 100,
    },
    {
        headerName: CHARGE_HEADER_CONSTANT.VESSEL_CODE,
        field: 'vesselCode',
        enableRowGroup: true,
        enablePivot: true,
        enableValue: true,
        filter: false,
        sortable: true,
        width: 150,
    },
    {
        headerName: CHARGE_HEADER_CONSTANT.VOYAGE_CODE,
        field: 'voyageCode',
        enableRowGroup: true,
        enablePivot: true,
        enableValue: true,
        filter: false,
        sortable: true,
        width: 150,
    },
    {
        headerName: CHARGE_HEADER_CONSTANT.HANDLING_TML,
        field: 'handlingTerminal',
        enableRowGroup: true,
        enablePivot: true,
        enableValue: true,
        filter: false,
        sortable: true,
        width: 200,
    },
    // {
    //     headerName: CHARGE_HEADER_CONSTANT.OPS_DATE,
    //     field: 'opsDate',
    //     enableRowGroup: true,
    //     enablePivot: true,
    //     enableValue: true,
    //     filter: false,
    //     sortable: true,
    //     width: 150,
    //     dataType: "date",
    // },
    {
        headerName: CHARGE_HEADER_CONSTANT.DIVERT_SHIP,
        field: 'divertShip',
        enableRowGroup: true,
        enablePivot: true,
        enableValue: true,
        filter: false,
        sortable: true,
        width: 150,
    },
    {
        headerName: CHARGE_HEADER_CONSTANT.DIVERT_SHIP_FROM,
        field: 'divertShipFrom',
        enableRowGroup: true,
        enablePivot: true,
        enableValue: true,
        filter: false,
        sortable: true,
        width: 200,
    },
    {
        headerName: CHARGE_HEADER_CONSTANT.CHARGE_DETAIL_INFO,
        field: 'chargeDtlInfo',
        enableRowGroup: true,
        enablePivot: true,
        enableValue: true,
        filter: false,
        sortable: true,
        width: 200,        
    },
    {
        headerName: CHARGE_HEADER_CONSTANT.ETD,
        field: 'etd',
        enableRowGroup: true,
        enablePivot: true,
        enableValue: true,
        filter: false,
        sortable: true,
        width: 150,
        dataType: "dateTime",
    },
    {
        headerName: CHARGE_HEADER_CONSTANT.ETA,
        field: 'eta',
        enableRowGroup: true,
        enablePivot: true,
        enableValue: true,
        filter: false,
        sortable: true,
        width: 150,
        dataType: "dateTime",
    },
    {
        headerName: CHARGE_HEADER_CONSTANT.VESSEL_NAME,
        field: 'vesselName',
        enableRowGroup: true,
        enablePivot: true,
        enableValue: true,
        filter: false,
        sortable: true,
    },
    {
        headerName: CHARGE_HEADER_CONSTANT.OPERATING_TML,
        field: 'operatingTml',
        enableRowGroup: true,
        enablePivot: true,
        enableValue: true,
        filter: false,
        sortable: true,
        width: 200,
    },
    {
        headerName: CHARGE_HEADER_CONSTANT.SERVICE_CODE,
        field: 'serviceCode',
        enableRowGroup: true,
        enablePivot: true,
        enableValue: true,
        filter: false,
        sortable: true,
    },
    {
        headerName: CHARGE_HEADER_CONSTANT.VESSEL_TYPE,
        field: 'vesselType',
        enableRowGroup: true,
        enablePivot: true,
        enableValue: true,
        filter: false,
        sortable: true,
    },
].map((col, index) => {
    return defineColumn(col, index, shipboundedDateFieldList, shipboundedDateTimeFieldList, [], {});
});


export const INITIAL_DOMESTIC_CHARGE_HEADER_COL_DEF: any[] = [
    {
        headerName: CHARGE_HEADER_CONSTANT.USER,
        field: 'user',
        enableRowGroup: true,
        enablePivot: true,
        enableValue: true,
        filter: false,
        pinned: "left",
        checkboxSelection: true,
        headerCheckboxSelection: true,
        sortable: true,
        width: 120,
    },
    {
        headerName: CHARGE_HEADER_CONSTANT.BILL_TO_COMPANY,
        field: 'billToCompany',
        enableRowGroup: true,
        enablePivot: true,
        enableValue: true,
        filter: false,
        sortable: true,
        width: 200,
    },
    {
        headerName: CHARGE_HEADER_CONSTANT.OPERATING_TML,
        field: 'operatingTml',
        enableRowGroup: true,
        enablePivot: true,
        enableValue: true,
        filter: false,
        sortable: true,
        width: 200,
    },
    {
        headerName: CHARGE_HEADER_CONSTANT.HANDLING_TML,
        field: 'handlingTml',
        enableRowGroup: true,
        enablePivot: true,
        enableValue: true,
        filter: false,
        sortable: true,
        width: 200,
    },
    {
        headerName: CHARGE_HEADER_CONSTANT.BILLING_CYCLE,
        field: 'billingCycle',
        enableRowGroup: true,
        enablePivot: true,
        enableValue: true,
        filter: false,
        sortable: true,
    },
    {
        headerName: CHARGE_HEADER_CONSTANT.BILLING_CYCLE_CODE,
        field: 'billingCycleCode',
        enableRowGroup: true,
        enablePivot: true,
        enableValue: true,
        filter: false,
        sortable: true,
    },
    {
        headerName: CHARGE_HEADER_CONSTANT.CHARGE_DETAIL_INFO,
        field: 'chargeDtlInfo',
        enableRowGroup: true,
        enablePivot: true,
        enableValue: true,
        filter: false,
        sortable: true,
        width: 200,        
    },
    {
        headerName: CHARGE_HEADER_CONSTANT.OPS_DATE_FROM,
        field: 'opsDateFrom',
        enableRowGroup: true,
        enablePivot: true,
        enableValue: true,
        filter: false,
        sortable: true,
        dataType: "date"
    },
    {
        headerName: CHARGE_HEADER_CONSTANT.OPS_DATE_TO,
        field: 'opsDateTo',
        enableRowGroup: true,
        enablePivot: true,
        enableValue: true,
        filter: false,
        sortable: true,
        dataType: "date",
    },
].map((col, index) => {
    return defineColumn(col, index, domesticDateFieldList, domesticDateTimeFieldList, [], {});
});

export const INITIAL_SHIPBOUNDEDINPERIOD_CHARGE_HEADER_COL_DEF: any[] = [
    {
        headerName: CHARGE_HEADER_CONSTANT.USER,
        field: 'user',
        enableRowGroup: true,
        enablePivot: true,
        enableValue: true,
        filter: false,
        sortable: true,
        pinned: "left",
        checkboxSelection: true,
        headerCheckboxSelection: true,
        width: 120,
    },
    {
        headerName: CHARGE_HEADER_CONSTANT.SOA,
        field: 'shipOwner',
        enableRowGroup: true,
        enablePivot: true,
        enableValue: true,
        filter: false,
        sortable: true,
        width: 120,
    },
    {
        headerName: CHARGE_HEADER_CONSTANT.VESSEL_CODE,
        field: 'vesselCode',
        enableRowGroup: true,
        enablePivot: true,
        enableValue: true,
        filter: false,
        sortable: true,
        width: 200,
    },
    {
        headerName: CHARGE_HEADER_CONSTANT.VOYAGE_CODE,
        field: 'voyageCode',
        enableRowGroup: true,
        enablePivot: true,
        enableValue: true,
        filter: false,
        sortable: true,
        width: 200,
    },
    {
        headerName: CHARGE_HEADER_CONSTANT.HANDLING_TML,
        field: 'handlingTerminal',
        enableRowGroup: true,
        enablePivot: true,
        enableValue: true,
        filter: false,
        sortable: true,
        width: 200,
    },
    {
        headerName: CHARGE_HEADER_CONSTANT.DIVERT_SHIP,
        field: 'divertShip',
        enableRowGroup: true,
        enablePivot: true,
        enableValue: true,
        filter: false,
        sortable: true,
        width: 150,
    },
    {
        headerName: CHARGE_HEADER_CONSTANT.DIVERT_SHIP_FROM,
        field: 'divertShipFrom',
        enableRowGroup: true,
        enablePivot: true,
        enableValue: true,
        filter: false,
        sortable: true,
        width: 200,
    },
    {
        headerName: CHARGE_HEADER_CONSTANT.CHARGE_DETAIL_INFO,
        field: 'chargeDtlInfo',
        enableRowGroup: true,
        enablePivot: true,
        enableValue: true,
        filter: false,
        sortable: true,
        width: 200,        
    },
    {
        headerName: CHARGE_HEADER_CONSTANT.ETD,
        field: 'etd',
        enableRowGroup: true,
        enablePivot: true,
        enableValue: true,
        filter: false,
        width: 150,     
        sortable: true,   
        dataType: "dateTime",
    },
    {
        headerName: CHARGE_HEADER_CONSTANT.ETA,
        field: 'eta',
        enableRowGroup: true,
        enablePivot: true,
        enableValue: true,
        filter: false,
        sortable: true,
        width: 150,
        dataType: "dateTime",
    },
    {
        headerName: CHARGE_HEADER_CONSTANT.VESSEL_NAME,
        field: 'vesselName',
        enableRowGroup: true,
        enablePivot: true,
        enableValue: true,
        sortable: true,
        filter: false,
    },
    {
        headerName: CHARGE_HEADER_CONSTANT.OPERATING_TML,
        field: 'operatingTml',
        enableRowGroup: true,
        enablePivot: true,
        sortable: true,
        enableValue: true,
        filter: false,
    },
    {
        headerName: CHARGE_HEADER_CONSTANT.BILLING_CYCLE,
        field: 'billingCycle',
        enableRowGroup: true,
        enablePivot: true,
        enableValue: true,
        sortable: true,
        filter: false,
    },
    {
        headerName: CHARGE_HEADER_CONSTANT.BILLING_CYCLE_CODE,
        field: 'billingCycleCode',
        enableRowGroup: true,
        enablePivot: true,
        enableValue: true,
        sortable: true,
        filter: false,
    },
    {
        headerName: CHARGE_HEADER_CONSTANT.OPS_DATE_FROM,
        field: 'opsDateFrom',
        enableRowGroup: true,
        enablePivot: true,
        enableValue: true,
        filter: false,
        sortable: true,
        dataType: "date",
    },
    {
        headerName: CHARGE_HEADER_CONSTANT.OPS_DATE_TO,
        field: 'opsDateTo',
        enableRowGroup: true,
        enablePivot: true,
        enableValue: true,
        sortable: true,
        filter: false,
        dataType: "date",
    },
    {
        headerName: CHARGE_HEADER_CONSTANT.SERVICE_CODE,
        field: 'serviceCode',
        enableRowGroup: true,
        enablePivot: true,
        enableValue: true,
        sortable: true,
        filter: false,
    },
    {
        headerName: CHARGE_HEADER_CONSTANT.VESSEL_TYPE,
        field: 'vesselType',
        enableRowGroup: true,
        enablePivot: true,
        sortable: true,
        enableValue: true,
        filter: false,
    },
].map((col, index) => {
    return defineColumn(col, index, shipboundedInPeriodDateFieldList, shipboundedInPeriodDateTimeFieldList, [], {});
    }
);

export const transferShipboundedRowData = (data:any[]) => {
    return transferRowDataInternal(data, shipboundedDateFieldList, shipboundedDateTimeFieldList, [], {});
}

export const transferDomesticRowData = (data:any[]) => {
    return transferRowDataInternal(data, domesticDateFieldList, domesticDateTimeFieldList, [], {});
}

export const transferShipboundedInPeriodRowData = (data:any[]) => {
    return transferRowDataInternal(data, shipboundedInPeriodDateFieldList, shipboundedInPeriodDateTimeFieldList, [], {});
}