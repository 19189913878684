import { CheckboxProps } from "presentation/model/CheckBoxprops"

interface CheckboxPropsList {
    option: CheckboxProps,
    checkboxList?: CheckboxProps[]
}

export const ChargeHeaderCriteriaDirectoryAllCheckboxOption: CheckboxProps = {key: 'all', name: 'All'}

export const ChargeHeaderCriteriaDirectoryVoyageCheckboxOption: CheckboxProps[] = [
    {key: 'voyage', name: 'Voyage'},
    {key: 'loginUser', name: 'Login User'},
    {key: 'soa', name: 'SOA'},
    {key: 'vesselCode', name: 'Vessel Code'},
    {key: 'voyageCode', name: 'Voyage Code'},
    {key: 'vesselName', name: 'Vessel Name'},
    {key: 'etd', name: 'ETD'},
    {key: 'eta', name: 'ETA'},
    {key: 'serviceCodeList', name: 'Service Code'},
    {key: 'vesselTypeList', name: 'Vessel Type'},
    {key: 'stateList', name: 'State'},
    {key: 'toBill', name: 'To Bill'},
]

export const ChargeHeaderCriteriaDirectoryVoyageCheckboxOptionList: CheckboxPropsList = {
    option: {key: 'voyage', name: 'VOYAGE'},
    checkboxList: ChargeHeaderCriteriaDirectoryVoyageCheckboxOption,
}

export const ChargeHeaderCriteriaDirectoryCheckboxOptionList: CheckboxPropsList[] = [
    ChargeHeaderCriteriaDirectoryVoyageCheckboxOptionList,
]