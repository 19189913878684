import { ChargeDetailConstant } from "presentation/constant/ChargeDetailMaintenance/ChargeDetailConstant";
import { useChargeDetailVM } from "presentation/hook/ChargeDetailMaintenance/useChargeDetailVM";
import { ChargeDetailModel } from "presentation/model/ChargeDetailMaintenance/ChargeDetailModel";
import DateTimePickerComponent from "presentation/view/components/DateTimePickerComponent";
import { InputDropdown } from "veronica-ui-component/dist/component/core";
import { CHARGE_TYPE_OUTBOUND, CHARGE_TYPE_TS_INBOUND, CHARGE_TYPE_TS_OUTBOUND } from "../../ChargeDetailAssist";


export const ChangeActionOption = ({
    CREATE_DUMMY_VOY_CNTR: "Create Dummy Voy and Cntr as TS 1st Leg",
    CREATE_DUMMY_CNTR: "Create Dummy Voy and Cntr as TS 1st Leg",
    MAP_EXISTING_TS_LEG: "Map Existing TS Leg",
    CHANGE_CNTR_STATUS_ONLY: "Change Cntr Status Only",
});


export const ChangeActionAccordion = ({chargeDetailState, updateStatusType}:{chargeDetailState:ChargeDetailModel, updateStatusType:string}) => {
    const chargeBatchUpdate = chargeDetailState.chargeBatchUpdate
    const chargeDetailVM = useChargeDetailVM();
    const dropDownOptions = () => {
        if (updateStatusType === CHARGE_TYPE_TS_OUTBOUND) {
            return [
                {
                    dropdownLabel: ChangeActionOption.CREATE_DUMMY_VOY_CNTR, 
                    tagLabel: ChangeActionOption.CREATE_DUMMY_VOY_CNTR, 
                    value: ChangeActionOption.CREATE_DUMMY_VOY_CNTR
                },
                {
                    dropdownLabel: ChangeActionOption.CREATE_DUMMY_CNTR, 
                    tagLabel: ChangeActionOption.CREATE_DUMMY_CNTR, 
                    value: ChangeActionOption.CREATE_DUMMY_CNTR
                },
                {
                    dropdownLabel: ChangeActionOption.MAP_EXISTING_TS_LEG, 
                    tagLabel: ChangeActionOption.MAP_EXISTING_TS_LEG, 
                    value: ChangeActionOption.MAP_EXISTING_TS_LEG
                }
            ]
        } else if (updateStatusType === CHARGE_TYPE_TS_INBOUND) {
            return [
                {
                    dropdownLabel: ChangeActionOption.CHANGE_CNTR_STATUS_ONLY, 
                    tagLabel: ChangeActionOption.CHANGE_CNTR_STATUS_ONLY, 
                    value: ChangeActionOption.CHANGE_CNTR_STATUS_ONLY
                },
                {
                    dropdownLabel: ChangeActionOption.MAP_EXISTING_TS_LEG, 
                    tagLabel: ChangeActionOption.MAP_EXISTING_TS_LEG, 
                    value: ChangeActionOption.MAP_EXISTING_TS_LEG
                }
            ]
        }
    };

    return (
        <>
            <div className={'im-flex-row'} style={{marginBottom:'24px'}}>
                {updateStatusType !== CHARGE_TYPE_OUTBOUND &&
                <div className='im-flex-row-item'>
                    <InputDropdown
                        label='Change Action'
                        width='300px'
                        inputType="freeText"
                        mode={"single"}
                        value={chargeBatchUpdate.changeActionOption}
                        onChange={(e) => chargeDetailVM.onDropdownChange(e, 
                           'changeActionOption', chargeBatchUpdate, 'chargeBatchUpdate')}
                        options={dropDownOptions()}/>
                </div>}

                {updateStatusType === CHARGE_TYPE_OUTBOUND &&
                <div className='im-flex-row-item'>
                    <DateTimePickerComponent
                        label={ChargeDetailConstant.Charge.RECEIVE_DATE_TIME}
                        width='300px'
                        date={chargeBatchUpdate.changeReceiveDatetime}
                        fieldName="changeReceiveDatetime"
                        onDateChange={chargeDetailVM.onDatePickerChange}
                        />
                </div>}
                
            </div>
        </>
    )
}