import { Permission } from "presentation/constant/ANAInfo/PermissionName";
import { ChargeHeaderMaintenanceProvider } from "presentation/store/ChargeHeaderMaintenance/ChargeHeaderMaintenanceProvider";
import { ANAInfoWrapper } from "presentation/view/components/ANAInfo/ANAInfoWrapper";
import { MessageBarWrapper } from "presentation/view/components/MessageBarWrapper";
import { ChargeHeaderMaintenanceLayout } from "presentation/view/section/ChargeHeaderMaintenance/ChargeHeaderMaintenanceLayout";
import { GridStyles } from "veronica-ui-component/dist/component/core/styled/table.styled";

export const ChargeHeaderMaintenanceContainer = () => {
    return <ANAInfoWrapper permission={Permission.CHARGE_PROCESSING}>
        <MessageBarWrapper>
        <ChargeHeaderMaintenanceProvider>
            <GridStyles/>
            <ChargeHeaderMaintenanceLayout />
        </ChargeHeaderMaintenanceProvider>
        </MessageBarWrapper>
    </ANAInfoWrapper>;
};