import { ChargeDetailConstant } from "presentation/constant/ChargeDetailMaintenance/ChargeDetailConstant";
import { WorkspaceConstant } from "presentation/constant/WorkSpaceConstant";
import { useChargeDetailVM } from "presentation/hook/ChargeDetailMaintenance/useChargeDetailVM";
import { useChargeVesselVM } from "presentation/hook/ChargeDetailMaintenance/useChargeVesselVM copy";
import { useMessageBarVM } from "presentation/hook/useMessageBar";
import { useChargeDetailTracked } from "presentation/store/ChargeDetailMaintenance/ChargeDetailProvider";
import { memo, useCallback, useState } from "react";
import { HPHButton, Loader } from "veronica-ui-component/dist/component/core";
import { SidebarActionBar, SidebarTitle, Sidebarheader } from "veronica-ui-component/dist/component/core/styled/uiFramework.styled";
import { CHARGE_TYPE_OLD_NEW_DIFF_RESH, CHARGE_TYPE_TS_INBOUND, CHARGE_TYPE_TS_OUTBOUND } from "../ChargeDetailAssist";
import { ChangeActionOption } from "../RightPanel/Accordion/ChangeActionAccordion";
import { CntrStatusAccordion } from "../RightPanel/Accordion/CntrStatusAccordion";

const UpdateCntrStatusPanel = () => {
    const CHARGEDETAIL_CONSTANT = ChargeDetailConstant.Charge;
    const [chargeDetailState] = useChargeDetailTracked();
    const messageBarVM = useMessageBarVM();
    const chargeVesselVM = useChargeVesselVM();
    const chargeDetailVM = useChargeDetailVM();
    const allFormState = chargeDetailState.allFormState;
    const chargeBatchUpdate = chargeDetailState.chargeBatchUpdate;
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const workspaceConstant = WorkspaceConstant.Common;
    // const [enableApply, setEnableApply] = useState<boolean>(false);

    // const updateCntrStatusSteps = ({
    //     selectNewStatus: "selectNewStatus",
    //     changeAction: "changeAction",
    //     searchVsl: "searchVsl",
    //     selectVsl: "selectVsl"
    // });
    // const [currentStep, setCurrentStep] = useState<string>(updateCntrStatusSteps.selectNewStatus);
    // const [updateStatusType, setUpdateStatusType] = useState<string>("");
    // const [showChangeAction, setShowChangeAction] = useState<boolean>(false);
    // const [showSelectVoy, setShowSelectVoy] = useState<boolean>(false);
    // const [newSubChargeType, setNewSubChargeType] = useState<string>("");
    // const [receiveDateTime, setReceiveDateTime] = useState<Date|null> (null);

    // const calcShowChangeAction = () => {
    //     setUpdateStatusType(getChangeActionType(chargeBatchUpdate.status??"", 
    //         chargeDetailState.selectedChargeDetails)??"");
    //     const subChargeType = chargeDetailState.selectedChargeDetails[0]?.subChargeType;
    //     const chargeType = chargeDetailState.selectedChargeDetails[0]?.chargeType;
    //     if(CHARGE_TYPE_OLD_NEW_DIFF_RESH === updateStatusType){
    //     	//If sub charge type = CY / YH, newStatus = RE / RM / SH / SM, new sub charge 
    //           //type = RESH.
    //     	if((VesselChargeSubChargeTypePolicy.CODE_CY === subChargeType
    // 			||VesselChargeSubChargeTypePolicy.CODE_YH === subChargeType)
    //     		&&(ContainerStatusPolicy().CNTR_STATUS_RE === chargeBatchUpdate.status
    //     		  ||ContainerStatusPolicy().CNTR_STATUS_RM === chargeBatchUpdate.status
    //     		  ||ContainerStatusPolicy().CNTR_STATUS_SH === chargeBatchUpdate.status
    //     		  ||ContainerStatusPolicy().CNTR_STATUS_SM === chargeBatchUpdate.status)){
    //     		setNewSubChargeType(VesselChargeSubChargeTypePolicy.CODE_RESH);
    //     	}
        	
    //     	//If sub charge type = CY-IS / YH-IS, newStatus = RE / RM / SH / SM, new sub  
    //           //charge type = RESH-IS.
    //     	if((VesselChargeSubChargeTypePolicy.CODE_CY_IS === subChargeType
    //     		||VesselChargeSubChargeTypePolicy.CODE_YH_IS === subChargeType)
    //     		&&(ContainerStatusPolicy().CNTR_STATUS_RE === chargeBatchUpdate.status
    //     		  ||ContainerStatusPolicy().CNTR_STATUS_RM === chargeBatchUpdate.status
    //     		  ||ContainerStatusPolicy().CNTR_STATUS_SH === chargeBatchUpdate.status
    //     		  ||ContainerStatusPolicy().CNTR_STATUS_SM === chargeBatchUpdate.status)){
    //             setNewSubChargeType(VesselChargeSubChargeTypePolicy.CODE_RESH_IS);
    //     	}
        	
    //     	//If sub charge type = RESH, newStatus <> RE / RM / SH / SM, new sub charge 
    //           //type = charge type (CY or YH).
    //     	if((VesselChargeSubChargeTypePolicy.CODE_RESH === subChargeType)
    //     		&&!(ContainerStatusPolicy().CNTR_STATUS_RE === chargeBatchUpdate.status
    //     		  ||ContainerStatusPolicy().CNTR_STATUS_RM === chargeBatchUpdate.status
    //     		  ||ContainerStatusPolicy().CNTR_STATUS_SH === chargeBatchUpdate.status
    //     		  ||ContainerStatusPolicy().CNTR_STATUS_SM === chargeBatchUpdate.status)){
    //             setNewSubChargeType(chargeType??"");
    //     	}
    //     	//If sub charge type = RESH-IS, newStatus <> RE / RM / SH / SM, new sub charge 
    //           //type = charge type+"IS" (CY-IS or YH-IS).
    //         if((VesselChargeSubChargeTypePolicy.CODE_RESH_IS === subChargeType)
    //             &&!(ContainerStatusPolicy().CNTR_STATUS_RE === chargeBatchUpdate.status
    //             ||ContainerStatusPolicy().CNTR_STATUS_RM === chargeBatchUpdate.status
    //             ||ContainerStatusPolicy().CNTR_STATUS_SH === chargeBatchUpdate.status
    //             ||ContainerStatusPolicy().CNTR_STATUS_SM === chargeBatchUpdate.status)){
    //             setNewSubChargeType(chargeType+"-IS");
    //         }             
    //     }else if (CHARGE_TYPE_INBOUND !== updateStatusType
    //             && VesselChargeSubChargeTypePolicy.CODE_CY === subChargeType) {
    //         if (CHARGE_TYPE_OUTBOUND !== updateStatusType) {
    //             setShowChangeAction(true);
    //         }
    //     }        
    // }

    // const calcShowSelectVsl = () => {
    //     if (CHARGE_TYPE_OUTBOUND === updateStatusType) {
    //         setReceiveDateTime(null);
    //     } else if (CHARGE_TYPE_TS_OUTBOUND === updateStatusType) {
    //         if (chargeBatchUpdate.changeActionOption === ChangeActionOption.CREATE_DUMMY_VOY_CNTR) {
    //             //dummy vsl and cntr
    //             const newDummyCharge:ChargeDetailEntity = {
    //                 ...EMPTY_CHARGE_DETAIL_ENTITY,
    //                 consortiumCode: "DUMY",
    //                 voyageCode: "99",
    //                 vesselCode: "9999",
    //                 status: ContainerStatusPolicy().CNTR_STATUS_TF,
    //                 owner1: "DUMY",
    //                 dtlState: "X",
    //                 voyageFinalizedInd: VoyageFinalizedIndPolicy.UF,
    //             }
    //             chargeBatchUpdate = {
    //                 ...chargeBatchUpdate,
    //                 otherLegCharge: newDummyCharge,
    //             }
    //         } else {
    //             setShowSelectVoy(true);
    //         }
    //     } else if (CHARGE_TYPE_TS_INBOUND === updateStatusType) {
    //         if (chargeBatchUpdate.changeActionOption !== ChangeActionOption.CHANGE_CNTR_STATUS_ONLY) {
    //             setShowSelectVoy(true);             
    //         }
    //     }
    // }

    const closeButtonClicked = useCallback(() => {
        chargeVesselVM.onCancelButtonClicked();
    }, [chargeVesselVM]);

    // const resetButtonClicked = useCallback(() => {
    //     chargeVesselVM.onResetButtonClicked();
    // }, [chargeVesselVM]);


    // const searchButtonClicked = useCallback(() => {
    //     setIsLoading(true);
    //     chargeVesselVM.searchChargeDetailDynStatus(chargeDetailState.chargeVesselSearchCriteria).then(data => {
    //         setCurrentStep(updateCntrStatusSteps.selectVsl);
    //         setIsLoading(false);
    //     }).catch(error => {
    //         setIsLoading(false);
    //     })
    // }, [chargeDetailState.chargeVesselSearchCriteria, chargeVesselVM, updateCntrStatusSteps.selectVsl]);

    // const nextButtonClicked = () => {
    //     if (currentStep === updateCntrStatusSteps.selectNewStatus) {
    //         calcShowChangeAction();
    //         if (showChangeAction) {
    //             setCurrentStep(updateCntrStatusSteps.changeAction);
    //             setEnableApply(false);
    //         } else {
    //             calcShowSelectVsl();
    //             setEnableApply(true);
    //         }
    //     } else if (currentStep === updateCntrStatusSteps.changeAction) {
    //         calcShowSelectVsl();
    //         if (showSelectVoy) {
    //             setCurrentStep(updateCntrStatusSteps.searchVsl);
    //             setEnableApply(false);
    //         } else {
    //             setEnableApply(true);
    //         }
    //     } else if (currentStep === updateCntrStatusSteps.searchVsl) {
    //         setCurrentStep(updateCntrStatusSteps.selectVsl);
    //         setEnableApply(true);
    //     }
    // }

    // const prevButtonClicked = () => {
    //     if (currentStep === updateCntrStatusSteps.changeAction) {
    //         setCurrentStep(updateCntrStatusSteps.selectNewStatus);
    //         setEnableApply(false);
    //     } else if (currentStep === updateCntrStatusSteps.searchVsl) {
    //         setCurrentStep(updateCntrStatusSteps.changeAction);
    //         setEnableApply(false);
    //     } else if (currentStep === updateCntrStatusSteps.selectVsl) {
    //         setCurrentStep(updateCntrStatusSteps.searchVsl);
    //         setEnableApply(false);
    //     }
    // }

    const applyButtonClicked = async() => {
        setIsLoading(true);
        await chargeVesselVM.onApplyButtonClicked(
            ((chargeBatchUpdate.updateStatusType === CHARGE_TYPE_TS_OUTBOUND && (chargeBatchUpdate.changeActionOption??"") === ChangeActionOption.MAP_EXISTING_TS_LEG && chargeBatchUpdate.consortiumCode && chargeBatchUpdate.vesselCode && chargeBatchUpdate.voyageCode) ? 2 :
             ((chargeBatchUpdate.updateStatusType === CHARGE_TYPE_TS_OUTBOUND && (chargeBatchUpdate.changeActionOption??"") === ChangeActionOption.MAP_EXISTING_TS_LEG && !chargeBatchUpdate.consortiumCode && !chargeBatchUpdate.vesselCode && !chargeBatchUpdate.voyageCode) ? 1 :
              ((chargeBatchUpdate.updateStatusType === CHARGE_TYPE_TS_INBOUND && (chargeBatchUpdate.changeActionOption??"") === ChangeActionOption.MAP_EXISTING_TS_LEG && chargeBatchUpdate.consortiumCode && chargeBatchUpdate.vesselCode && chargeBatchUpdate.voyageCode) ? 1 : 0))), 
            (chargeBatchUpdate.status2Enable ? CHARGE_TYPE_OLD_NEW_DIFF_RESH : chargeBatchUpdate.updateStatusType??""), 
            chargeBatchUpdate.subChargeType??"", null, chargeDetailState.selectedChargeDetails, chargeBatchUpdate.status??"",
            chargeBatchUpdate.consortiumCode??null, chargeBatchUpdate.vesselCode??null, chargeBatchUpdate.voyageCode??null, 
            chargeBatchUpdate.otherLegCharge
        ).then(data => {
                chargeDetailVM.getChargeDetails({
                    ...chargeDetailState.searchCriteria,
                    chargeHdrId: chargeDetailState?.currentChargeHeader?.id
                }).finally(() => {
                    if (allFormState && allFormState["updateCntrStatusFail"]) {
                        messageBarVM.showError(allFormState["updateCntrStatusFail"]?.toString());
                    } else {
                        messageBarVM.showSuccess("Update status successfully.");
                    }
                    chargeVesselVM.onCancelButtonClicked();
                    setIsLoading(false);
                });
        }).catch(error => {
            setIsLoading(false);
        })
    }

    return (
        <div className='side-form-content-wrapper'>
            {isLoading &&
                <Loader Indicator="Spinner" size="Medium"/>
            }
            <div className={'im-flex-row'}>
                <div className={'flex-row-item flex-row-item-full-width'}>
                    <Sidebarheader>
                        <SidebarTitle>{CHARGEDETAIL_CONSTANT.UPDATE_CONTAINER_STATUS}</SidebarTitle>
                    </Sidebarheader>                
                </div>
            </div>
            <div className={'add-edit-form'} style={{ height:'72vh', maxHeight:'72vh' , overflow: 'auto'}}>
                <div className="flex-row-auto">
                    <div className='flex-row-item flex-row-item-full-width'>
                        <CntrStatusAccordion />
                        {/* {currentStep === updateCntrStatusSteps.selectNewStatus && <CntrStatusAccordion chargeDetailState={chargeDetailState}/>}
                        {currentStep === updateCntrStatusSteps.changeAction && showChangeAction &&
                            <ChangeActionAccordion chargeDetailState={chargeDetailState} updateStatusType={updateStatusType}/>}
                        {currentStep === updateCntrStatusSteps.searchVsl && showSelectVoy && <UpdateVesselVoyageMasterSearchForm  chargeDetailState={chargeDetailState}/>}
                        {currentStep === updateCntrStatusSteps.selectVsl && <UpdateChargeVesselTablePanel chargeDetailState={chargeDetailState} updateStatusType={updateStatusType} />} */}
                    </div>                  
                </div>
            </div>
                <SidebarActionBar>
                    <HPHButton label={workspaceConstant.BUTTON_CANCEL} 
                        size={'Small'} theme={'Secondary'} onClick={closeButtonClicked} />
                    {/* <HPHButton label={workspaceConstant.BUTTON_PREV} disabled={currentStep === updateCntrStatusSteps.selectNewStatus}
                        size={'Small'} theme={'Secondary'} onClick={prevButtonClicked} />
                    <HPHButton label={workspaceConstant.BUTTON_NEXT} disabled={currentStep === updateCntrStatusSteps.selectVsl || enableApply}
                        size={'Small'} theme={'Secondary'} onClick={nextButtonClicked} />
                    <HPHButton label={workspaceConstant.BUTTON_RESET} disabled={currentStep !== updateCntrStatusSteps.searchVsl}
                        size={'Small'} theme={'Secondary'} onClick={resetButtonClicked} />
                    <HPHButton label={workspaceConstant.BUTTON_SEARCH} disabled={currentStep !== updateCntrStatusSteps.searchVsl}
                        size={'Small'} theme={'Secondary'} onClick={searchButtonClicked} /> 
                        //disabled = {!enableApply}*/}
                    <HPHButton label={workspaceConstant.BUTTON_UPDATE} 
                        size={'Small'} theme={'Primary'} onClick={applyButtonClicked} />
                </SidebarActionBar>
        </div>
    );
}

export default memo(UpdateCntrStatusPanel);