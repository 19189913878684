export interface VesselSummaryEnableSearchCriteria {   
    all: boolean,
    vesselCode: boolean,
    voyageCode: boolean,
    handlingTerminal: boolean,

    [key: string]: boolean,
}

export const DEFAULT_ENABLED_VESSEL_SUMMARY_SEARCH_CRITERIA : VesselSummaryEnableSearchCriteria = {
    all: false,
    vesselCode: false,
    voyageCode: false,
    handlingTerminal: false
}