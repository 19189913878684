export interface ChargeHeaderEnabledSearchCriteria {   
    all: boolean,
    voyage: boolean,
    loginUser: boolean,
    vesselCode: boolean,
    voyageCode: boolean,
    vesselName: boolean,
    billingCycleList: boolean,
    billingCycleCode: boolean,
    billToCompList: boolean,
    soa: boolean,
    etd: boolean,
    etb: boolean,
    eta: boolean,
    serviceCodeList: boolean,
    vesselTypeList: boolean,
    stateList: boolean,
    toBill: boolean,

    [key: string]: boolean,
}

export const DEFAULT_ENABLED_CHARGE_HEADER_SEARCH_CRITERIA : ChargeHeaderEnabledSearchCriteria = {
    all: true,
    voyage: true,
    loginUser: true,
    vesselCode: true,
    voyageCode: true,
    vesselName: true,
    billingCycleList: true,
    billingCycleCode: true,
    billToCompList: true,
    soa: true,
    etd: true,
    etb: true,
    eta: true,
    serviceCodeList: true,
    vesselTypeList: true,
    stateList: true,
    toBill: true,
}