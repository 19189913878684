import { useChargeDetailVM } from "presentation/hook/ChargeDetailMaintenance/useChargeDetailVM";
import { ChargeDetailModel } from "presentation/model/ChargeDetailMaintenance/ChargeDetailModel";
import { InputDropdown, InputField } from "veronica-ui-component/dist/component/core";

export const OneOffRateAccordion = ({chargeDetailState}:{chargeDetailState:ChargeDetailModel}) => {
    const chargeBatchUpdate = chargeDetailState.chargeBatchUpdate
    const chargeDetailVM = useChargeDetailVM();
    return (
        <>
            <div className={'im-flex-row'} style={{marginBottom:'24px'}}>
                <div className='flex-row-item'>
                    <InputField
                        label='Assign One-off Rate'
                        width='330px'
                        type="number"
                        value={chargeBatchUpdate.rate || ''}
                        onChange={(e) => chargeDetailVM.onInputTextChange(e, 'rate', chargeBatchUpdate, 'chargeBatchUpdate')}
                        errorMessage={chargeBatchUpdate?.allFormState?.rate??""}
                        />
                </div>
            </div>
            
            <div className={'im-flex-row'} style={{marginBottom:'24px'}}>
                <div className='flex-row-item'>
                    <InputDropdown
                        label='Reason Code'
                        width='330px'
                        inputType="freeText"
                        mode={"single"}
                        value={chargeBatchUpdate.oneOffRateReasonCode}
                        onChange={(e) => chargeDetailVM.onDropdownChange(e, 'reasonCode', chargeBatchUpdate, 'chargeBatchUpdate')}
                        options={chargeDetailState.dynamicOptions.reasonCodeDropdownOptions}
                        errorMessage={chargeBatchUpdate?.allFormState?.reasonCode??""}
                        />
                </div>
            </div>
        </>
    )
}