import { DeliveryChargeSummaryRepoImpl } from "domain/repository/ChargeHeader/DeliveryChargeSummaryRepoImpl";
import { SearchPreferenceRepoImpl } from "domain/repository/Common/SearchPreferenceRepoImpl";
import { MasterDataRepoImpl } from "domain/repository/MasterData/MasterDataRepoImpl";
import { ParameterDetailRepoImpl } from "domain/repository/parameter/ParameterDetailRepoImpl";
import { useChargeDataSearchTracked } from "presentation/store/ChargeDataEnquiry/ChargeDataSearchProvider";
import { useChargeHeaderMaintenanceTracked } from "presentation/store/ChargeHeaderMaintenance/ChargeHeaderMaintenanceProvider";
import { useDeliveryChargeSummaryMaintenanceTracked } from "presentation/store/ChargeHeaderMaintenance/DeliveryChargeSummaryMaintenanceProvider";
import { DeliveryChargeSummaryMaintenanceVM } from "presentation/viewModel/ChargeHeaderMaintenance/DeliveryChargeSummaryMaintenanceVM";
import { useMemo } from "react";

export const useDeliveryChargeSummaryMaintenanceVM = () => {
    const [, setDeliveryChargeSummaryMaintenanceState] = useDeliveryChargeSummaryMaintenanceTracked();
    const [, setChargeHeaderMaintenanceState] = useChargeHeaderMaintenanceTracked();
    const [, setChargeDataSearchState] = useChargeDataSearchTracked();
    const deliveryChargeSummaryMaintenanceVM = useMemo(() =>
        DeliveryChargeSummaryMaintenanceVM({
            dispatch: [setDeliveryChargeSummaryMaintenanceState],
            headerDispatch: [setChargeHeaderMaintenanceState],
            chargeDataSearchDispatch: [setChargeDataSearchState],
            searchPreferenceRepo: SearchPreferenceRepoImpl(),
            delChgSummaryRepo: DeliveryChargeSummaryRepoImpl(),
            parameterDetailRepo: ParameterDetailRepoImpl(),
            masterDataRepo: MasterDataRepoImpl(),
        }), [setChargeDataSearchState, setChargeHeaderMaintenanceState, setDeliveryChargeSummaryMaintenanceState])

    return deliveryChargeSummaryMaintenanceVM
}