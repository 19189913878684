export interface VesselSummaryEnabledHeaderField {   
    all: boolean,
    vesselType: boolean,
    vesselCode: boolean,
    voyageCode: boolean,
    consortiumCode: boolean,
    etd: boolean,
    lashing: boolean,
    gearbox: boolean,
    isoHatch: boolean,
    lateArrival: boolean,
    shiftingBB: boolean,
    restowBSB: boolean,
    handlingTml: boolean,

    [key: string]: boolean,
}

export const DEFAULT_ENABLED_VESSEL_SUMMARY_HEADER_FIELD : VesselSummaryEnabledHeaderField = {
    all: true,
    vesselType: true,
    vesselCode: true,
    voyageCode: true,
    consortiumCode: true,
    etd: true,
    lashing: true,
    gearbox: true,
    isoHatch: true,
    lateArrival: true,
    shiftingBB: true,
    restowBSB: true,
    handlingTml: true
}