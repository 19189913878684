import { SearchModeEntity } from "domain/entity/ChargeDataEnquiry/SearchModeEntity"
import { DeliveryChargeSummaryEntity } from "domain/entity/ChargeHeader/DeliveryChargeSummaryEntity"
import { BasicSearchCriteriaEntity } from "domain/entity/Common/BasicSearchCriteriaEntity"
import { DeliveryChargeSummaryRepository } from "domain/repository/ChargeHeader/DeliveryChargeSummaryRepo"
import { SearchPreferenceRepository } from "domain/repository/Common/SearchPreferenceRepo"
import { MasterDataRepository } from "domain/repository/MasterData/MasterDataRepo"
import { ParameterDetailRepository } from "domain/repository/parameter/ParameterDetailRepo"
import _ from "lodash"
import { DeliveryChargeSummaryEnableSearchCriteria } from "presentation/constant/ChargeHeader/DeliveryChargeSummary/DeliveryChargeSummaryEnableSearchCriteria"
import { DeliveryChargeSummarySearchCriteria, EMPTY_DELIVERY_CHARGE_SUMMARY_SEARCH_CRITERIA } from "presentation/constant/ChargeHeader/DeliveryChargeSummary/DeliveryChargeSummarySearchCriteria"
import { ChargeDataSearchModel } from "presentation/model/ChargeDataEnquiry/ChargeDataSearchModel"
import { ChargeHeaderMaintenanceModel } from "presentation/model/ChargeHeaderMaintenance/ChargeHeaderMaintenanceModel"
import { DeliveryChargeSummaryMaintenanceModel } from "presentation/model/ChargeHeaderMaintenance/DeliveryChargeSummaryMaintenanceModel"
import { DropdownProps } from "presentation/model/DropdownProps"
import { ChangeEvent, Dispatch, SetStateAction } from "react"
import BaseViewModel from "../BaseViewModel"


interface DeliveryChargeSummaryVMProps extends BaseViewModel {
    dispatch: [
        Dispatch<SetStateAction<DeliveryChargeSummaryMaintenanceModel>> | ((value: SetStateAction<DeliveryChargeSummaryMaintenanceModel>) => void),
    ],
    headerDispatch: [
        Dispatch<SetStateAction<ChargeHeaderMaintenanceModel>> | ((value: SetStateAction<ChargeHeaderMaintenanceModel>) => void),
    ],
    chargeDataSearchDispatch: [
        Dispatch<SetStateAction<ChargeDataSearchModel>> | ((value: SetStateAction<ChargeDataSearchModel>) => void),
    ],
    searchPreferenceRepo: SearchPreferenceRepository<DeliveryChargeSummarySearchCriteria,DeliveryChargeSummaryEnableSearchCriteria>,
    delChgSummaryRepo: DeliveryChargeSummaryRepository,
    parameterDetailRepo: ParameterDetailRepository,
    masterDataRepo: MasterDataRepository,
}

export const DeliveryChargeSummaryMaintenanceVM = ({ dispatch, headerDispatch, chargeDataSearchDispatch, searchPreferenceRepo, delChgSummaryRepo,parameterDetailRepo,  masterDataRepo}: DeliveryChargeSummaryVMProps) => {
    const [chargeHeaderDispatch] = headerDispatch;
    const [delChgSummaryDispatch] = dispatch;
    const [chargeSearchDispatch] = chargeDataSearchDispatch;

    const onPageLoad = async (headerCriteria: DeliveryChargeSummarySearchCriteria) => {  
        try {
            delChgSummaryDispatch(prevState => ({  
                ...prevState,  
                searchCriteria: headerCriteria,  
            })); 
        } catch (error) {  
            console.error('Error during onPageLoad:', error);  
        } finally{
        } 
    };

    const onSearch = async (searchCriteria: DeliveryChargeSummarySearchCriteria, searchModeEntity: SearchModeEntity) => {
        const groupCriteriaList: BasicSearchCriteriaEntity[] = searchModeEntity.groupKeys.map((item, index) => ({
            key: searchModeEntity.rowGroupCols[index], value: item
        }))

        await delChgSummaryRepo.getDeliveryChargeSummaryData({
            searchCriteria: searchCriteria,
            startRow: searchModeEntity.startRow,
            endRow: searchModeEntity.endRow,
            searchSort: searchModeEntity.searchSort,
            currentGroup: searchModeEntity.currentGroup,
            groupCriteria: groupCriteriaList,
        }).then((data)=>{
            delChgSummaryDispatch(prevState => ({  
                ...prevState,  
                deliveryChargeSummaryDataList: data,  
                searchCriteria: {...searchCriteria}
            }));  
        }).catch((error) => {                
            delChgSummaryDispatch(prevState => ({  
                ...prevState,  
                deliveryChargeSummaryDataList: [], 
                searchCriteria: {...searchCriteria}
            }));  
            console.error('Error during onSearch:', error);  
        }).finally(()=>{
        });
    }

    const loadDropdownOption = () => {
        parameterDetailRepo.getAllParameterDtlsByParameterCode("BILLING_CYCLE").then(
            parDtls => {
                const billingCycleDropdownOptions = parDtls?.map((parDtl) => ({
                    dropdownLabel: parDtl.parameterDtlCode,
                    tagLabel: parDtl.parameterDtlCode,
                    value: parDtl.parameterDtlCode,
                })) ?? []

                delChgSummaryDispatch(prevState => ({
                    ...prevState,
                    dynamicOptions: {
                        ...prevState.dynamicOptions,
                        billingCycleDropdownOptions: [
                            ...billingCycleDropdownOptions]
                    }
                }))
            }
        )
        masterDataRepo.getMasterDataByKey('ServiceEntity').then(
            serviceCodes => {
                const serviceCodeDropdownOptions = serviceCodes?.map((serviceCode)=>({
                    dropdownLabel: serviceCode.code,
                    tagLabel: serviceCode.code,
                    value: serviceCode.code,
                })) ?? []

                delChgSummaryDispatch(prevState => ({
                    ...prevState,
                    dynamicOptions: {
                        ...prevState.dynamicOptions,
                        serviceCodeDropdownOptions: [
                            ...serviceCodeDropdownOptions]
                    }
                }))
            }           
        )
    }

    const onCloseClick = () => {
        chargeHeaderDispatch(prevState => {
            return {
                ...prevState,
                isShowDeliveryChargeSummary: false,
                isRedirectFromChargeDetail: true,
            }
        }); 
    }

    
    const onShowLoading = () => {
        delChgSummaryDispatch(prevState => {
            return {
                ...prevState,
                isLoading: true,
            }
        })
    }

    const onHideLoading = () => {
        delChgSummaryDispatch(prevState => {
            return {
                ...prevState,
                isLoading: false,
            }
        })
    }

    const onMultipleDropdownChange = (e: any, fieldName: string) => {
        delChgSummaryDispatch(prevState => {
            return {
                ...prevState,
                searchCriteria: {
                    ...prevState.searchCriteria,
                    [fieldName]: _.uniq(e?.map((item: DropdownProps) => item.value)),
                }
            }
        });
    }

    const onInputTextChange = (e: ChangeEvent<HTMLInputElement>, fieldName: string, toUpperCase:boolean=false) => {
        const val = toUpperCase || fieldName === 'uniqueCntrNo' ? e.target.value.toString().toUpperCase() : e.target.value;
        delChgSummaryDispatch(prevState => ({
            ...prevState,
            searchCriteria: {
                ...prevState.searchCriteria,
                [fieldName]: val,
            }
        }))
    };

    const onDateRangeChange = (startDate: any, endDate: any,  dateFields:{startField:string, endField: string}) => {
        delChgSummaryDispatch(prevState => ({
            ...prevState,
            searchCriteria: {
                ...prevState.searchCriteria,
                [dateFields.startField]: startDate,
                [dateFields.endField]: endDate,
            }
        }))
    }

    
    const onRemoveAllSearchCriteria = () => {
        delChgSummaryDispatch(prevState => {
            prevState.currentTableRef?.current?.api.setServerSideDatasource({
                getRows: (params: any) => {
                    params.success({
                        rowData: [],
                        rowCount: 0
                    })
                },
            })
            
            return {
                ...prevState, searchCounter: 0, searchCriteria: {...EMPTY_DELIVERY_CHARGE_SUMMARY_SEARCH_CRITERIA}
            }
        })
    }

    const onSelectedDeliveryChargeData = (data: DeliveryChargeSummaryEntity) => {
        delChgSummaryDispatch(prevState => {
            return {...prevState,currentRow: data, }
        })
    }


    const onDifferent = (request: DeliveryChargeSummaryEntity) => {
        delChgSummaryRepo.getDeliveryChargeSummaryCntrs(request).then((data) => {
            chargeSearchDispatch(prevState => ({
                ...prevState,
                isShowContainerDetail: false,
                searchCriteria:{
                    ...prevState.searchCriteria,
                    cntrList: data,
                    handlingTmlList:  _.toArray(request.handlingTerminal),
                    vsl: request.vesselCode,
                    voy: request.voyageCode,
                    billingCycleCode: request.billingCycleCode,                    
                }
             }))
        })
    }

    const onSearchClick = () => {
        delChgSummaryDispatch(prevState => ({
            ...prevState,
            isShowCriteriaPanel: !prevState.isShowCriteriaPanel,
        }))
    };


    return {
        onPageLoad: onPageLoad,
        onCloseClick: onCloseClick,
        onSearch: onSearch,
        onShowLoading: onShowLoading,
        onHideLoading: onHideLoading,
        onMultipleDropdownChange: onMultipleDropdownChange,
        onRemoveAllSearchCriteria: onRemoveAllSearchCriteria,
        onInputTextChange: onInputTextChange,
        onDateRangeChange: onDateRangeChange,
        loadDropdownOption: loadDropdownOption,
        onSelectedDeliveryChargeData: onSelectedDeliveryChargeData,
        onDifferent: onDifferent,
        onSearchClick: onSearchClick,
    }
}

