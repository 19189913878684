export const ChargeDetailStatePolicy = ({
    STATE_R: "R",
    STATE_F: "F",
    STATE_C: "C",
    STATE_T: "T",
    STATE_I: "I",
    STATE_E: "E",
    STATE_H: "H",
    STATE_SH: "SH",
    STATE_MH: "MH",
    STATE_MT: "MT",
    STATE_Z: "Z",
    STATE_X: "X",
    STATE_SG: "SG",
    STATE_DI: "DI",
    STATE_SA: "SA",
})