import { ChargeDetailEntity, EMPTY_CHARGE_DETAIL_ENTITY } from "domain/entity/ChargeDetailMaintenance/ChargeDetailEntity";
import { ChargeDetailSearchCriteria, EMPTY_CHARGE_DETAIL_SEARCH_CRITERIA } from "domain/entity/ChargeDetailMaintenance/ChargeDetailSearchCriteria";
import { ChargeDetailSeqCriteria, EMPTY_CHARGE_DETAIL_SEQ_CRITERIA_ENTITY } from "domain/entity/ChargeDetailMaintenance/ChargeDetailSeqCriteria";
import { ChargeSummaryMap, EMPTY_CHARGE_SUMMARY_MAP } from "domain/entity/ChargeDetailMaintenance/ChargeDetailSummaryEntity";
import { ChargeVesselSearchCriteria, EMPTY_CHARGE_VESSEL_SEARCH_CRITERIA } from "domain/entity/ChargeDetailMaintenance/ChargeVesselSearchCriteria";
import { EMPTY_VESSEL_VOYAGE_MASTER_ENTITY, VesselVoyageMasterEntity } from "domain/entity/ChargeDetailMaintenance/VesselVoyageMasterEntity";
import { EMPTY_VESSEL_VOYAGE_MASTER_SEARCH_CRITERIA, VesselVoyageMasterSearchCriteria } from "domain/entity/ChargeDetailMaintenance/VesselVoyageMasterSearchCriteria";
import { ChargeHeaderEntity, EMPTY_CHARGE_HEADER_ENTITY } from "domain/entity/ChargeHeader/ChargeHeaderEntity";
import { ChargeBatchUpdateEntity, EMPTY_CHARGE_BATCH_UPDATE_ENTITY } from "presentation/constant/ChargeDetailMaintenance/ChargeBatchUpdateEntity";
import { DEFAULT_ENABLED_CHARGE_DETAIL_SEARCH_CRITERIA, EnabledSearchCriteria } from "presentation/constant/ChargeDetailMaintenance/EnabledSearchCriteria";
import { BaseViewChangeSate } from "../BaseViewChangeState";
import { DropdownProps } from "../DropdownProps";


export interface ChargeDetailDropdownOptions {
    chgOnCompDropdownOptions: DropdownProps[],
    billToCompDropdownOptions: DropdownProps[],
    chgTypeDropdownOptions: DropdownProps[],
    subChgTypeDropdownOptions: { [key: string]: DropdownProps[] },
    stateDropdownOptions: DropdownProps[],
    operationTypeDropdownOptions: DropdownProps[],
    bundleIndDropdownOptions: DropdownProps[],
    sizeGroupDropdownOptions: DropdownProps[],
    typeDropdownOptions: DropdownProps[],
    modalityArrivalDropdownOptions: DropdownProps[] ,
    modalityDepartureDropdownOptions: DropdownProps[],
    specHandingCodeDropdownOptions: DropdownProps[],
    specHandingCodeD1DropdownOptions: DropdownProps[],
    tarCodeDropdownOptions: DropdownProps[],
    adjTypeDropdownOptions: DropdownProps[],
    statusCOKDDropdownOptions: DropdownProps[],
    statusDropdownOptions: DropdownProps[],
    opsLineDropdownOptions: DropdownProps[],
    billingTypeDropdownOptions: DropdownProps[],
    empytIndDropdownOptions: DropdownProps[],
    reasonCodeDropdownOptions: DropdownProps[],
    operatingTmlDropdownOptions: DropdownProps[],
    manualTsLegChargeIndDropdownOptions: DropdownProps[],
    ignoreCntrStatusValidDropdownOptions: DropdownProps[],
    marshallingTypeDropdownOptions: DropdownProps[],
    forwarderCodeDropdownOptions: DropdownProps[],
    specialIndDropdownOptions: DropdownProps[],
    chargeCodeDropdownOptions: DropdownProps[],
    consortiumCodeDropdownOptions: DropdownProps[],
    seqDropdownOptions: DropdownProps[],
    serviceCodeOptions: DropdownProps[],
    uomDropdownOptions: DropdownProps[],
}

export interface ChargeDetailViewChangeState extends BaseViewChangeSate {
    currentSelectedData: ChargeDetailEntity
}

export interface VesselVoyageMasterViewChangeState extends BaseViewChangeSate {
    currentSelectedVslVoyData: VesselVoyageMasterEntity
}

export interface ChargeDetailModel {
    isLoading:boolean,
    isShowSummaryPanel: boolean,
    isShowRightEditPanel: boolean,
    isShowBatchUpdatePanel: boolean,
    isAssignOneOffRate: boolean,
    isAccTml: boolean,
    isUptAbsolutelyCommonAc: boolean,
    isCopyCommonAc: boolean,
    isAdjustment: boolean,
    isShowCntrInfoPanel: boolean,
    isShowUpdateVesselVoyageMasterSearchPanel: boolean,
    isShowUpdateVesselVoyageMasterTablePanel: boolean,
    isShowAssignSeqNoPanel: boolean,
    searchCriteria: ChargeDetailSearchCriteria,    
    searchVslVoyCriteria: VesselVoyageMasterSearchCriteria,
    searchSeqCriteria: ChargeDetailSeqCriteria,
    dynamicOptions: ChargeDetailDropdownOptions,
    currentChargeHeader: ChargeHeaderEntity,
    chargeDetails: ChargeDetailEntity[],
    vesselVoyageMasters: VesselVoyageMasterEntity[],
    chargeDetailSummary: ChargeSummaryMap,
    chargeDetailSummaryButtonState: {[key:string]:boolean},
    allChargeDetails: ChargeDetailEntity[],
    chargeBatchUpdate: ChargeBatchUpdateEntity,
    chargeDetailState: ChargeDetailViewChangeState,
    vslVoyState: VesselVoyageMasterViewChangeState,
    selectedChargeDetails: ChargeDetailEntity[],
    selectedVesselVoyageMasters: VesselVoyageMasterEntity[],
    isBatchUpdate: boolean,
    allFormState: {[key:string]:string},
    enabledSearchCriteria: EnabledSearchCriteria,
    isShowCriteriaPanel: boolean,
    isShowLegend: boolean,
    isShowCloseIcon: boolean,
    isDisableApply: boolean,
    isDisableConfirm: boolean,
    isDisableUnconfirm: boolean,
    isDisableAdjustment: boolean,
    isDisableDelete: boolean,
    isShowUpdateStatus: boolean,
    chargeVesselSearchCriteria: ChargeVesselSearchCriteria,
    chargeVessels: ChargeDetailEntity[],
    selectedChargeVessels: ChargeDetailEntity[],
    refreshBedge: boolean,
    isRefreshButton: boolean,
}

export const EMPTY_CHARGE_DETAIL_MODEL: ChargeDetailModel = {
    isLoading: true,
    isShowRightEditPanel: false,
    isShowSummaryPanel: false,
    isShowBatchUpdatePanel: false,
    isAssignOneOffRate:false,
    isAccTml:false,
    isUptAbsolutelyCommonAc: false,
    isCopyCommonAc: false,
    isAdjustment: false,
    isShowCntrInfoPanel: false,
    isShowUpdateVesselVoyageMasterSearchPanel: false,
    isShowUpdateVesselVoyageMasterTablePanel: false,
    isShowAssignSeqNoPanel: false,
    searchCriteria: { ...EMPTY_CHARGE_DETAIL_SEARCH_CRITERIA },
    searchVslVoyCriteria: { ...EMPTY_VESSEL_VOYAGE_MASTER_SEARCH_CRITERIA },
    searchSeqCriteria: { ...EMPTY_CHARGE_DETAIL_SEQ_CRITERIA_ENTITY },
    selectedVesselVoyageMasters: [],
    selectedChargeDetails: [],
    isBatchUpdate: false,
    allFormState: {},
    enabledSearchCriteria: { ...DEFAULT_ENABLED_CHARGE_DETAIL_SEARCH_CRITERIA },
    isShowCriteriaPanel: false,
    isShowLegend: false,
    isShowCloseIcon: true,
    isShowUpdateStatus: false,
    dynamicOptions: {
        chgOnCompDropdownOptions: [],
        billToCompDropdownOptions: [],
        chgTypeDropdownOptions: [],
        subChgTypeDropdownOptions: {},
        stateDropdownOptions: [],
        operationTypeDropdownOptions: [],
        bundleIndDropdownOptions: [],
        sizeGroupDropdownOptions: [],
        typeDropdownOptions: [],
        modalityArrivalDropdownOptions: [] ,
        modalityDepartureDropdownOptions: [],
        specHandingCodeDropdownOptions: [],
        specHandingCodeD1DropdownOptions: [],
        tarCodeDropdownOptions: [],
        adjTypeDropdownOptions: [],
        statusCOKDDropdownOptions: [],
        statusDropdownOptions: [],
        opsLineDropdownOptions: [],
        billingTypeDropdownOptions: [],
        empytIndDropdownOptions: [],
        reasonCodeDropdownOptions: [],
        operatingTmlDropdownOptions: [],
        manualTsLegChargeIndDropdownOptions: [],
        ignoreCntrStatusValidDropdownOptions: [],
        marshallingTypeDropdownOptions: [],
        forwarderCodeDropdownOptions: [],
        specialIndDropdownOptions: [],
        chargeCodeDropdownOptions: [],
        consortiumCodeDropdownOptions: [],
        seqDropdownOptions: [],
        serviceCodeOptions: [],
        uomDropdownOptions: [],
    },
    currentChargeHeader: EMPTY_CHARGE_HEADER_ENTITY,
    chargeDetails: [],
    vesselVoyageMasters: [],
    chargeDetailSummary: EMPTY_CHARGE_SUMMARY_MAP,
    chargeBatchUpdate: EMPTY_CHARGE_BATCH_UPDATE_ENTITY,
    chargeDetailState: {
        currentSelectedData: EMPTY_CHARGE_DETAIL_ENTITY,
        isSliderOpen: false, // true - if stack form open
        isTabularDataActive: true, // false - If list view is active
        isAdd: false, // true - if use add new record
        isRead: false, // true - if user click to view record
        isEditable: false, // true - if user edit existing record
        isSaveClicked: false, // true - if user clicked on save button to save record
        lastEditRowId: "",
        isSelected: false,
        allFormState: {},

        slider: {
            isAdd: false, // true - if use add new record
            isRead: false, // true - if user click to view record
            isEditable: false, // true - if user edit existing record
        }
    },
    vslVoyState : {
        currentSelectedVslVoyData: EMPTY_VESSEL_VOYAGE_MASTER_ENTITY,
        isSliderOpen: false, // true - if stack form open
        isTabularDataActive: true, // false - If list view is active
        isAdd: false, // true - if use add new record
        isRead: false, // true - if user click to view record
        isEditable: false, // true - if user edit existing record
        isSaveClicked: false, // true - if user clicked on save button to save record
        lastEditRowId: "",
        isSelected: false,
        allFormState: {},

        slider: {
            isAdd: false, // true - if use add new record
            isRead: false, // true - if user click to view record
            isEditable: false, // true - if user edit existing record
        }
    },
    isDisableApply: true,
    isDisableConfirm: true,
    isDisableUnconfirm: true,
    isDisableAdjustment: true,
    isDisableDelete: true,
    chargeVesselSearchCriteria: EMPTY_CHARGE_VESSEL_SEARCH_CRITERIA,
    chargeVessels: [],
    selectedChargeVessels: [],
    chargeDetailSummaryButtonState: {},
    allChargeDetails: [],
    refreshBedge:false,
    isRefreshButton:false,
}