import { InboundContainerSummaryEntity } from "domain/entity/ChargeHeader/InboundContainerSummaryEntity";
import { InboundContainerSummaryRequestEntity } from "domain/entity/ChargeHeader/InboundContainerSummaryRequestEntity";
import { axiosPostData } from "../axios/AxiosBasicImpl";
import chargeAxiosInstance from "../axios/chargeAxiosInstance";
import { InboundContainerSummaryRepository } from "./InboundContainerSummaryRepo";

export const InboundContainerSummaryRepoImpl = () : InboundContainerSummaryRepository => {
    const ibCntrSummaryDataUrl = "/v1/inboundcontainersummarydatasearch"


    const getInboundContainerSummaryData = async (request: InboundContainerSummaryRequestEntity) : Promise<InboundContainerSummaryEntity[]> => {
        try {
            const res = await axiosPostData(chargeAxiosInstance, `${ibCntrSummaryDataUrl}`, request);
            const data = res.data;
            return data;
        } catch (err) {
            return [];
        }
    }

    return {
        getInboundContainerSummaryData: getInboundContainerSummaryData,
    }
}