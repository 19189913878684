import { INITIAL_INBOUND_CONTAINER_SUMMARY_HEADER_COL_DEF, transferRowData } from "presentation/constant/ChargeHeader/InboundContainerSummary/InboundContainerSummaryColumnDefinition";
import { useChargeHeaderMaintenanceTracked } from "presentation/store/ChargeHeaderMaintenance/ChargeHeaderMaintenanceProvider";
import { useInboundContainerSummaryMaintenanceTracked } from "presentation/store/ChargeHeaderMaintenance/InboundContainerSummaryMaintenanceProvider";
import { TableWrapper } from "presentation/view/components/TableWrapper/TableWrapper";
import { useMemo } from "react";
import { HPHTable } from "veronica-ui-component/dist/component/core";


export const InboundContainerSummaryTablePanel: React.FC = () => {
    const [ibCntrSummaryState] = useInboundContainerSummaryMaintenanceTracked();
    const [chargeHeaderState] = useChargeHeaderMaintenanceTracked();
    
    const memoGroupTable = useMemo(() => {
        return (            
            <HPHTable
            id={'Inbound Container Summary Table'}
            isNewColumnSetting={true}
            // headerLabel={IB_CNTR_SUMMARY_CONSTANT.INBOUND_CONTAINER_SUMMARY}
            columns={INITIAL_INBOUND_CONTAINER_SUMMARY_HEADER_COL_DEF}
            data={transferRowData(ibCntrSummaryState.inboundContainerSummaryDataList, (chargeHeaderState?.currentChargeHeader?.id??0))}
            showPaginator={false}
            editable={false}            
            showAddIcon={false}
            showDeleteButton={false}
            showReloadIcon={false}
            isScrollHighlighted={true}
            gridHeight="customHeight"
            customHeight="calc(100vh - 125px)" />
        );
    }, [ibCntrSummaryState.inboundContainerSummaryDataList, chargeHeaderState?.currentChargeHeader?.id])

    return <TableWrapper>{memoGroupTable}</TableWrapper>
}


