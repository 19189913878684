import { OpsLineEntity } from "domain/entity/OpsLine/OpsLineEntity";
import { axiosGetData } from "../axios/AxiosBasicImpl";
import referenceAxiosInstance from "../axios/referenceAxiosInstance";
import { OpsLineRepository } from "./OpsLineRepo";

export const OpsLineRepoImpl = ():OpsLineRepository => {
    const url = '/v1/opsLine'
    const getAllOpsLines = async() : Promise<OpsLineEntity[]> => {
        return axiosGetData(referenceAxiosInstance, url,[]).then(res => {
            return res.data;
        }).catch(err => {
            return [];
        })
    }

    return{
        getAllOpsLines: getAllOpsLines
    }
}