import { useChargeDetailVM } from "presentation/hook/ChargeDetailMaintenance/useChargeDetailVM";
import { ChargeDetailModel } from "presentation/model/ChargeDetailMaintenance/ChargeDetailModel";
import { InputField } from "veronica-ui-component/dist/component/core";

export const DgDuContentAccordion = ({chargeDetailState}:{chargeDetailState:ChargeDetailModel}) => {
    const chargeBatchUpdate = chargeDetailState.chargeBatchUpdate
    const chargeDetailVM = useChargeDetailVM();
    return (
        <>
            <div className={'im-flex-row'} style={{marginBottom:'24px'}}>
                <div className='im-flex-row-item'>
                    <InputField
                        label='DG Content'
                        width='150px'
                        type="text"
                        value={chargeBatchUpdate.dgContent || ''}
                        onChange={(e: any) => chargeDetailVM.onInputTextChange(e, 'dgContent', chargeBatchUpdate, 'chargeBatchUpdate')}/>
                </div>
                <div className='im-flex-row-item'>
                    <InputField
                        label='DU Content'
                        width='150px'
                        type="text"
                        value={chargeBatchUpdate.duContent || ''}
                        onChange={(e: any) => chargeDetailVM.onInputTextChange(e, 'duContent', chargeBatchUpdate, 'chargeBatchUpdate')}/>
                </div>
            </div>
        </>
    )
}