import { VesselVoyageMasterRepoImpl } from "domain/repository/ChargeDetailMaintenance/VesselVoyageMasterRepoImpl";
import { VesselSummaryRepoImpl } from "domain/repository/ChargeHeader/VesselSummaryRepoImpl";
import { VesselSummaryTdrRepoImpl } from "domain/repository/ChargeHeader/VesselSummaryTdrRepoImpl";
import { SearchPreferenceRepoImpl } from "domain/repository/Common/SearchPreferenceRepoImpl";
import { useChargeHeaderMaintenanceTracked } from "presentation/store/ChargeHeaderMaintenance/ChargeHeaderMaintenanceProvider";
import { useVesselSummaryMaintenanceTracked } from "presentation/store/ChargeHeaderMaintenance/VesselSummaryMaintenanceProvider";
import { VesselSummaryMaintenanceVM } from "presentation/viewModel/ChargeHeaderMaintenance/VesselSummaryMaintenanceVM";
import { useMemo } from "react";

export const useVesselSummaryMaintenanceVM = () => {
    const [, setVesselSummaryMaintenanceState] = useVesselSummaryMaintenanceTracked();
    const [, setChargeHeaderMaintenanceState] = useChargeHeaderMaintenanceTracked();
    const vesselSummaryMaintenanceVM = useMemo(() =>
        VesselSummaryMaintenanceVM({
            dispatch: [setVesselSummaryMaintenanceState],
            headerDispatch: [setChargeHeaderMaintenanceState],
            searchPreferenceRepo: SearchPreferenceRepoImpl(),
            vslSummaryRepo: VesselSummaryRepoImpl(),
            vslSummaryTdrRepo: VesselSummaryTdrRepoImpl(),
            vesselVoyageMasterRepo: VesselVoyageMasterRepoImpl(),
        }), [setChargeHeaderMaintenanceState, setVesselSummaryMaintenanceState])

    return vesselSummaryMaintenanceVM
}