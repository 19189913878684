export interface VesselSummaryHdrEntity {

    visitId: string | null,
    restowCount: number | null,
    shiftCount: number | null,
    sizeUnit: string | null,
    lateArrivalCount: number | null,
    lashingCount: number | null,
    isohCount: number | null,
    gearboxCount: number | null,
    isLashing: string | null,
    vesselCode: string | null,
    voyageCode: string | null,
    consortiumCode: string | null,
    handlingTerminal: string | null,
    vesselType: string | null,
}

export const EMPTY_VESSEL_SUMMARY_HEADER_ENTITY: VesselSummaryHdrEntity = {
    visitId: null,
    restowCount: null,
    shiftCount: null,
    sizeUnit: null,
    lateArrivalCount: null,
    lashingCount: null,
    isohCount: null,
    gearboxCount: null,
    isLashing: null,
    vesselCode: null,
    voyageCode: null,
    consortiumCode: null,
    handlingTerminal: null,
    vesselType: null
}