import _ from "lodash";
import { DropdownProps } from "presentation/model/DropdownProps";

export const ChargeHeaderCriteriaDirectoryDropdownOption: DropdownProps[] = _.orderBy([
    { dropdownLabel: 'Voyage', value: 'voyage', tagLabel: 'Voyage' },
    { dropdownLabel: 'Login User', value: 'loginUser', tagLabel: 'Login User' },
    { dropdownLabel: 'SOA', value: 'soa', tagLabel: 'SOA' },
    { dropdownLabel: 'Vessel Code', value: 'vesselCode', tagLabel: 'Vessel Code' },
    { dropdownLabel: 'Voyage Code', value: 'voyageCode', tagLabel: 'Voyage Code' },
    { dropdownLabel: 'ETD', value: 'etd', tagLabel: 'ETD' },
    { dropdownLabel: 'ETA', value: 'eta', tagLabel: 'ETA' },
    { dropdownLabel: 'Service Code', value: 'serviceCodeList', tagLabel: 'Service Code' },
    { dropdownLabel: 'Vessel Type', value: 'vesselTypeList', tagLabel: 'Vessel Type' },
    { dropdownLabel: 'State', value: 'hdrState', tagLabel: 'State' },
    { dropdownLabel: 'To Bill', value: 'toBill', tagLabel: 'To Bill' },
], 'dropdownLabel')
