import { InboundContainerSummaryEntity } from "domain/entity/ChargeHeader/InboundContainerSummaryEntity";
import { SearchPreferenceEntity } from "domain/entity/Common/SearchPreferenceEntity";
import { DEFAULT_ENABLED_INBOUND_CONTAINER_SUMMARY_SEARCH_CRITERIA, InboundContainerSummaryEnableSearchCriteria } from "presentation/constant/ChargeHeader/InboundContainerSummary/InboundContainerSummaryEnableSearchCriteria";
import { EMPTY_INBOUND_CONTAINER_SUMMARY_SEARCH_CRITERIA, InboundContainerSummarySearchCriteria } from "presentation/constant/ChargeHeader/InboundContainerSummary/InboundContainerSummarySearchCriteria";
import { RefObject } from "react";


export interface InboundContainerSummaryMaintenanceDropdownOptions {
}

export interface InboundContainerSummaryMaintenanceModel {
    isLoading: boolean,
    isShowCriteriaPanel: boolean,
    enabledSearchCriteria: InboundContainerSummaryEnableSearchCriteria,
    searchCriteria: InboundContainerSummarySearchCriteria,
    highlightedCheckboxKey?: string | null
    currentTableRef?: RefObject<any>,
    searchCounter: number,
    dynamicOptions: InboundContainerSummaryMaintenanceDropdownOptions,
    isShowSaveNewConfirmModal: boolean,
    isShowDeleteConfirmModal: boolean,
    searchPreferences: SearchPreferenceEntity<InboundContainerSummarySearchCriteria,InboundContainerSummaryEnableSearchCriteria>[],
    currentSearchPreference: SearchPreferenceEntity<InboundContainerSummarySearchCriteria,InboundContainerSummaryEnableSearchCriteria> | null,
    inboundContainerSummaryDataList: InboundContainerSummaryEntity[],
    vesselType?: string | null,
}

export const EMPTY_IBOUND_CONTAINER_SUMMARY_MODEL: InboundContainerSummaryMaintenanceModel = {
    isLoading: true,
    isShowCriteriaPanel: false,
    enabledSearchCriteria: { ...DEFAULT_ENABLED_INBOUND_CONTAINER_SUMMARY_SEARCH_CRITERIA },
    searchCriteria: { ...EMPTY_INBOUND_CONTAINER_SUMMARY_SEARCH_CRITERIA },
    searchCounter: 0,
    isShowSaveNewConfirmModal: false,
    isShowDeleteConfirmModal: false,
    searchPreferences: [],
    currentSearchPreference: null,
    dynamicOptions: [],
    inboundContainerSummaryDataList: [],
    vesselType: null,
}