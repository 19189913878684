import { ChargeDetailSearchCriteria } from "domain/entity/ChargeDetailMaintenance/ChargeDetailSearchCriteria";
import { ChargeDetailConstant } from "presentation/constant/ChargeDetailMaintenance/ChargeDetailConstant";
import { useChargeDetailVM } from "presentation/hook/ChargeDetailMaintenance/useChargeDetailVM";
import { useChargeDetailTracked } from "presentation/store/ChargeDetailMaintenance/ChargeDetailProvider";
import { useChargeHeaderMaintenanceTracked } from "presentation/store/ChargeHeaderMaintenance/ChargeHeaderMaintenanceProvider";
import { useCallback, useState } from "react";
import { HPHButton, IconButton, Loader } from "veronica-ui-component/dist/component/core";
import { SidebarActionBar, SidebarActionCross, SidebarTitle, Sidebarheader } from "veronica-ui-component/dist/component/core/styled/uiFramework.styled";
import ChargeSearchForm from "./ChargeSearchForm";

const ChargeSearchPanel = () => {
    const CHARGEDETAIL_CONSTANT = ChargeDetailConstant.Charge;
    const chargeDetailVM = useChargeDetailVM();
    const [chargeDetailState] = useChargeDetailTracked();
    const [chargeHeaderState] = useChargeHeaderMaintenanceTracked();
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const chargeHeader = chargeHeaderState?.currentChargeHeader ? chargeHeaderState?.currentChargeHeader : chargeDetailState?.currentChargeHeader;

    const closeButtonClicked = useCallback(() => {
        chargeDetailVM.onSearchClick();
    }, [chargeDetailVM]);

    const resetButtonClicked = useCallback(() => {
        chargeDetailVM.onSearchCriteriaResetClick();
    }, [chargeDetailVM]);
    
    const searchButtonClicked = useCallback(() => {
        setIsLoading(true);
        const criteria = {...chargeDetailState.searchCriteria, chargeHdrId: Number(chargeHeader?.id)??0}
        chargeDetailVM.getChargeDetails(criteria).then((data) => {
            setIsLoading(false);
        }).catch(error => {
            setIsLoading(false);
        })
        
    }, [chargeDetailState.searchCriteria, chargeDetailVM, chargeHeader?.id])

    return (
        <div className='side-form-content-left-wrapper'>
            {isLoading &&
                <Loader Indicator="Spinner" size="Medium"/>
            }

            <div className={'flex-row'}>
                <div className={'flex-row-item flex-row-item-full-width'}>
                    <Sidebarheader>
                        <SidebarTitle>{CHARGEDETAIL_CONSTANT.SEARCH_CRITERIA}</SidebarTitle>
                        
                        <SidebarActionCross>
                            <IconButton
                                fileName="Icon-cross" size="medium" toolTipText='Close' toolTipArrow={false} onClick={closeButtonClicked} />
                        </SidebarActionCross>
                    </Sidebarheader>                
                </div>
            </div>
                        
            <>
            <div className={'add-edit-form'} style={{ maxHeight:'68vh' , height:'68vh', overflow: 'auto'}}>
                <div className="flex-row-auto">
                    <div className={'flex-row-item'}>
                        
                        {/* Stack Form Start */}
                        <ChargeSearchForm/>
                    </div>                  
                </div>
            </div>
            <SidebarActionBar>
                <HPHButton label={'Reset'} size={'Small'} theme={'Secondary'} onClick={resetButtonClicked} />
                <HPHButton label={'Search'} size={'Small'} theme={'Primary'} onClick={() => searchButtonClicked()} />
            </SidebarActionBar></>
        </div>
    );
}

export default ChargeSearchPanel;