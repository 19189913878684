export const ChargeTypePolicy = ({
    CHARGE_TYPE_CODCOV: "CODCOV",
    CHARGE_TYPE_COO: "COO",
    CHARGE_TYPE_COS: "COS",
    CHARGE_TYPE_CSR: "CSR",
    CHARGE_TYPE_CY: "CY",
    CHARGE_TYPE_DEL: "DEL",
    CHARGE_TYPE_EMO: "EMO",
    CHARGE_TYPE_EMP: "EMP",
    CHARGE_TYPE_GS: "GS",
    CHARGE_TYPE_HOL: "HOL",
    CHARGE_TYPE_HPI: "HPI",
    CHARGE_TYPE_HPV: "HPV",
    CHARGE_TYPE_ITM: "ITM",
    CHARGE_TYPE_MAR: "MAR",
    CHARGE_TYPE_NOM: "NOM",
    CHARGE_TYPE_OBS: "OBS",
    CHARGE_TYPE_OFL: "OFL",
    CHARGE_TYPE_ONL: "ONL",
    CHARGE_TYPE_PTI: "PTI",
    CHARGE_TYPE_RFH: "RFH",
    CHARGE_TYPE_RPR: "RPR",
    CHARGE_TYPE_GEN: "GEN",
    CHARGE_TYPE_STF: "STF",        
    CHARGE_TYPE_TSS: "TSS",
    CHARGE_TYPE_YH: "YH",
    CHARGE_TYPE_T3: "T3",
    CHARGE_TYPE_RAIL: "RAIL",
    CHARGE_TYPE_EXYH: "EXYH",
    CHARGE_TYPE_HDL: "HDL",
    CHARGE_TYPE_SVR_ODR: "SVR_ODR",
	CHARGE_TYPE_EXM: "EXM",
	//fix UAT#103741 by vic
	CHARGE_TYPE_VGM: "VGM",
    
    //for charge lock
    CHARGE_TYPE_CY_CH_FOR_LOCK: "CYCH",
    CHARGE_TYPE_CY_VS_FOR_LOCK: "CYVC",
    
    CHARGE_TYPE_NO: "NO",
    CHARGE_TYPE_SP: "SP",
    CHARGE_TYPE_CR: "CR",
    
    //counter customer spec
    CHARGE_TYPE_CC: "CC",
})