import { ForwarderCodeEntity } from "domain/entity/ForwarderCode/ForwarderCodeEntity";
import { axiosGetData } from "../axios/AxiosBasicImpl";
import referenceAxiosInstance from "../axios/referenceAxiosInstance";
import { ForwarderCodeRepository } from "./ForwarderCodeRepo";

export const ForwarderCodeRepoImpl = ():ForwarderCodeRepository => {
    const url = '/v1/forwarderCode'
    const getAllForwarderCodes = async() : Promise<ForwarderCodeEntity[]> => {
        return axiosGetData(referenceAxiosInstance, url,[]).then(res => {
            return res.data;
        }).catch(err => {
            return [];
        })
    }

    return{
        getAllForwarderCodes: getAllForwarderCodes
    }
}