import { useChargeHeaderMaintenanceTracked } from "presentation/store/ChargeHeaderMaintenance/ChargeHeaderMaintenanceProvider";
import { ChargeHeaderSearchCriteriaDirectoryPanel } from "../ChargeHeaderSearchCriteriaDirectoryPanel/ChargeHeaderSearchCriteriaDirectoryPanel";
import { DivertShipPanel } from "../DivertShip/DivertShipPanel";


export const ChargeHeaderSearchRightPanel = () => {
    const [chargeDataSearchState] = useChargeHeaderMaintenanceTracked();
    const isFilterSearchCriteria = chargeDataSearchState.isFilterSearchCriteria;
    const isShowRightCriteriaPanel = chargeDataSearchState.isShowRightPanel;    
    const isShowDivertShipPanel = chargeDataSearchState.isShowDivertShipPanel;

    return <div className='main-comp-wrapper'>
        {isShowDivertShipPanel && <><DivertShipPanel /></>}
        {isShowRightCriteriaPanel && !isFilterSearchCriteria && <><ChargeHeaderSearchCriteriaDirectoryPanel /></>}
    </div>;
};
