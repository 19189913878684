import { EMPTY_DELIVERY_CHARGE_SUMMARY_MODEL } from 'presentation/model/ChargeHeaderMaintenance/DeliveryChargeSummaryMaintenanceModel';
import { useState } from 'react';
import { createContainer } from "react-tracked";

const {
    Provider: DeliveryChargeSummaryMaintenanceProvider,
    useTracked: useDeliveryChargeSummaryMaintenanceTracked
} = createContainer(() => useState(EMPTY_DELIVERY_CHARGE_SUMMARY_MODEL), {concurrentMode: true});
export { DeliveryChargeSummaryMaintenanceProvider, useDeliveryChargeSummaryMaintenanceTracked };

