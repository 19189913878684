import { ChargeConstant } from "presentation/constant/ChargeHeader/ChargeConstant";
import { useVesselSummaryMaintenanceVM } from "presentation/hook/ChargeHeaderMaintenance/useVesselSummaryMaintenanceVM";
import { useChargeHeaderMaintenanceTracked } from "presentation/store/ChargeHeaderMaintenance/ChargeHeaderMaintenanceProvider";
import { useVesselSummaryMaintenanceTracked } from "presentation/store/ChargeHeaderMaintenance/VesselSummaryMaintenanceProvider";
import { HeaderWithBackButton } from "presentation/view/components/HeaderWithBackButton/HeaderWithBackButton";
import { memo } from "react";
import { HPHBreadcrumb, IconButton } from "veronica-ui-component/dist/component/core";
import { Breadcrumb, Sidebarheader, StyledAction } from "veronica-ui-component/dist/component/core/styled/uiFramework.styled";


const VesselSummaryTitleBar:React.FC = () => {
    const VSL_SUMMARY_CONSTANT = ChargeConstant.VesselSummary;
    const vslSummaryVM = useVesselSummaryMaintenanceVM();
    const [chargeHdrState] = useChargeHeaderMaintenanceTracked();
    const [vslSumMainState] = useVesselSummaryMaintenanceTracked();


    return <Sidebarheader style={{width: '100%'}}>
        <HeaderWithBackButton callback={vslSummaryVM.onCloseClick}>
            <Breadcrumb>
                <HPHBreadcrumb breadcrumbData={[{ title: ChargeConstant.ChargeHeader.CHARGE_PROCESSING }, { title: VSL_SUMMARY_CONSTANT.VESSEL_SUMMARY }]} onCurrentClick={()=>{}}></HPHBreadcrumb>
            </Breadcrumb>
        </HeaderWithBackButton>
        <StyledAction>
            <IconButton fileName='Icon-doc' disabled={false} size='medium' toolTipText={ChargeConstant.VesselSummary.TDR} toolTipArrow={false} onClick={(e:any)=>{vslSummaryVM.toggleTdrPanel(vslSumMainState.isShowTdrPanel, chargeHdrState.currentChargeHeader)}} />
            {/* <IconButton fileName="Icon-cross" size="medium" disabled={false} toolTipArrow={false} toolTipPlacement="left" toolTipText={'Close'} onClick={vslSummaryVM.onCloseClick} /> */}
        </StyledAction>
    </Sidebarheader>

}

export default memo(VesselSummaryTitleBar);