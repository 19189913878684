import { PhysicalHandlingCodeEntity } from "domain/entity/PhysicalHandlingCode/PhysicalHandlingCodeEntity";
import { axiosGetData } from "../axios/AxiosBasicImpl";
import referenceAxiosInstance from "../axios/referenceAxiosInstance";
import { PhysicalHandlingCodeRepository } from "./PhysicalHandlingCodeRepo";

export const PhysicalHandlingCodeRepoImpl = ():PhysicalHandlingCodeRepository => {
    const url = '/v1/physicalHandlingCode'
    const getPhysicalHandlingCodeForComboBox = async() : Promise<PhysicalHandlingCodeEntity[]> => {
        return axiosGetData(referenceAxiosInstance, url,[]).then(res => {
            return res.data;
        }).catch(err => {
            return [];
        })
    }

    return{
        getPhysicalHandlingCodeForComboBox: getPhysicalHandlingCodeForComboBox
    }
}