import { EMPTY_SEARCH_MODE_ENTITY } from "domain/entity/ChargeDataEnquiry/SearchModeEntity";
import { ChargeDetailConstant } from "presentation/constant/ChargeDetailMaintenance/ChargeDetailConstant";
import { MessageConstant } from "presentation/constant/MessageConstant";
import { useChargeDetailVM } from "presentation/hook/ChargeDetailMaintenance/useChargeDetailVM";
import { useChargeHeaderMaintenanceVM } from "presentation/hook/ChargeHeaderMaintenance/useChargeHeaderMaintenanceVM";
import { useMessageBarVM } from "presentation/hook/useMessageBar";
import { useChargeDetailTracked } from "presentation/store/ChargeDetailMaintenance/ChargeDetailProvider";
import { useChargeHeaderMaintenanceTracked } from "presentation/store/ChargeHeaderMaintenance/ChargeHeaderMaintenanceProvider";
import { memo, useCallback, useState } from "react";
import { HPHButton, Loader } from "veronica-ui-component/dist/component/core";
import { SidebarActionBar, SidebarTitle, Sidebarheader } from "veronica-ui-component/dist/component/core/styled/uiFramework.styled";
import CntrInfoForm from "./CntrInfoForm";

const CntrInfoPanel = () => {
    const CHARGEDETAIL_CONSTANT = ChargeDetailConstant.Charge;
    const chargeDetailVM = useChargeDetailVM();
    const chargeHeaderVM = useChargeHeaderMaintenanceVM();
    const [chargeDetailState] = useChargeDetailTracked();
    const [chargeHeaderState] = useChargeHeaderMaintenanceTracked();
    const {chargeBatchUpdate, allFormState, selectedChargeDetails} = chargeDetailState;
    const messageBarVM = useMessageBarVM();
    const [isLoading, setIsLoading] = useState<boolean>(false);

    const closeButtonClicked = useCallback(() => {
        chargeDetailVM.onCntrInfoCloseClick();
    }, [chargeDetailVM]);
    
    const saveButtonClicked = useCallback(async() => {
        let newSearchCriteria = chargeDetailState.searchCriteria;
        newSearchCriteria = {...newSearchCriteria, chargeHdrId: Number(chargeDetailState?.currentChargeHeader?.id??chargeHeaderState?.currentChargeHeader?.id)??0}
        
        setIsLoading(true);
        const newChargeBatchUpdate = {
            ...chargeBatchUpdate,
            bulkEditAction : ChargeDetailConstant.Charge.UPDATE_CONTAINER_INFO
        };
        chargeDetailVM.onBatchUpdateSave(newChargeBatchUpdate, selectedChargeDetails).then((data) => {
            // if (allFormState && allFormState["batchUpdteFail"]) {
            //     messageBarVM.showError(allFormState["batchUpdteFail"]?.toString());
            // } else {
                // Promise.allSettled([
                //     chargeDetailVM.handleApply(selectedChargeDetails),                        
                // ]).then(data => {
                    Promise.allSettled([
                        chargeDetailVM.getChargeDetails(newSearchCriteria),                        
                        chargeHeaderVM.onSearch({
                            ...chargeDetailState.searchCriteria,
                            id: chargeDetailState?.currentChargeHeader?.id??chargeHeaderState?.currentChargeHeader?.id
                        }, EMPTY_SEARCH_MODE_ENTITY, true, true)
                    ]).finally(() => {
                        if (allFormState && allFormState["batchUpdteFail"]) {
                            messageBarVM.showError(allFormState["batchUpdteFail"]?.toString());
                        } else {
                            messageBarVM.showSuccess(`${MessageConstant.common.OPERATION_DONE_SUCCESSFUL.replace(
                                "{operationDone}", ChargeDetailConstant.Charge.UPDATE_CONTAINER_INFO)}`);
                        }
                        
                        setIsLoading(false);
                    });
                // });
            // }
        }).catch(() => {
            setIsLoading(false);
        })
    }, [chargeDetailState.searchCriteria, chargeDetailState?.currentChargeHeader?.id, chargeHeaderState?.currentChargeHeader?.id, 
        chargeBatchUpdate, chargeDetailVM, selectedChargeDetails, chargeHeaderVM, allFormState, messageBarVM])

    return (
        <div className='side-form-content-wrapper'>
            {isLoading && <Loader size="Medium" Indicator="Spinner"></Loader>}
            <div className='flex-row-item flex-row-item-full-width'>
                <div className='side-form-content-wrapper'>
                    <div className={'flex-row'}>
                        <div className={'flex-row-item flex-row-item-full-width'}>
                            <Sidebarheader>
                                <SidebarTitle>{CHARGEDETAIL_CONSTANT.UPDATE_CONTAINER_INFO}</SidebarTitle>
                            </Sidebarheader>                
                        </div>
                    </div>
                    <div className={'add-edit-form'} style={{ maxHeight:'67vh' , overflow: 'auto'}}>
                        <div className="flex-row-auto">
                            <div className={'flex-row-item'}>
                                
                                {/* Stack Form Start */}
                                <CntrInfoForm/>
                            </div>                  
                        </div>
                    </div>
                        <SidebarActionBar>
                            <HPHButton label={'Close'} size={'Small'} theme={'Secondary'} onClick={closeButtonClicked} />
                            <HPHButton label={'Save'} size={'Small'} theme={'Primary'} onClick={saveButtonClicked} />
                        </SidebarActionBar>
                </div>
            </div>
        </div>
    );
}

export default memo(CntrInfoPanel);