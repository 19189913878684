import { ChargeDetailRepoImpl } from "domain/repository/ChargeDetailMaintenance/ChargeDetailRepoImpl";
import { useChargeDetailTracked } from "presentation/store/ChargeDetailMaintenance/ChargeDetailProvider";
import { ChargeVesselVM } from "presentation/viewModel/ChargeDetailMaintenance/ChargeVesselVM";
import { useMemo } from "react";

export const useChargeVesselVM = () => {
    const [, setChargeDetailState] = useChargeDetailTracked();
    const chargeDetailVM = useMemo(() =>
        ChargeVesselVM({
            dispatch: [setChargeDetailState],
            chargeDetailRepo: ChargeDetailRepoImpl(),
        }), [setChargeDetailState])

    return chargeDetailVM
}