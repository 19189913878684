import { ChargeConstant } from "presentation/constant/ChargeHeader/ChargeConstant";
import { useInboundContainerSummaryMaintenanceVM } from "presentation/hook/ChargeHeaderMaintenance/useInboundContainerSummaryMaintenanceVM";
import { HeaderWithBackButton } from "presentation/view/components/HeaderWithBackButton/HeaderWithBackButton";
import { memo, useCallback } from "react";
import { HPHBreadcrumb, IconButton } from "veronica-ui-component/dist/component/core";
import { Breadcrumb, Sidebarheader, StyledAction } from "veronica-ui-component/dist/component/core/styled/uiFramework.styled";


const InboundContainerSummaryTitleBar:React.FC = () => {
    const IB_CNTR_SUMMARY_CONSTANT = ChargeConstant.InboundContainerSummary;
    const vslSummaryVM = useInboundContainerSummaryMaintenanceVM();

    const handleSearchClick = useCallback(() => {
        vslSummaryVM.onSearchClick();
    }, [vslSummaryVM]);

    return <Sidebarheader style={{width: '100%'}}>
        <HeaderWithBackButton callback={vslSummaryVM.onCloseClick}>
            <Breadcrumb>
                <HPHBreadcrumb breadcrumbData={[{ title: ChargeConstant.ChargeHeader.CHARGE_PROCESSING }, { title: IB_CNTR_SUMMARY_CONSTANT.INBOUND_CONTAINER_SUMMARY }]} onCurrentClick={()=>{}}></HPHBreadcrumb>
            </Breadcrumb>
        </HeaderWithBackButton>
        <StyledAction>
            <IconButton fileName='Icon-search' disabled={false} size='medium' toolTipText={'Search'} toolTipArrow={false} onClick={handleSearchClick} />
            {/* <IconButton fileName="Icon-cross" size="medium" disabled={false} toolTipArrow={false} toolTipPlacement="left" toolTipText={'Close'} onClick={vslSummaryVM.onCloseClick} /> */}
        </StyledAction>
    </Sidebarheader>

}

export default memo(InboundContainerSummaryTitleBar);