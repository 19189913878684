import { CheckboxProps } from "presentation/model/CheckBoxprops"

interface CheckboxPropsList {
    option: CheckboxProps,
    checkboxList?: CheckboxProps[]
}

export const CriteriaDirectoryAllCheckboxOption: CheckboxProps = {key: 'all', name: 'All'}

export const CriteriaDirectoryChargeCheckboxOption: CheckboxProps[] = [
    {key: 'charge', name: 'CHARGE'},
    {key: 'cntrNos', name: 'Container No.'},
    {key: 'chargeOnCompanies', name: 'Charge-on Company'},
    {key: 'billToCompanies', name: 'Bill-to Company'},
    {key: 'states', name: 'State'},
    {key: 'tariffCodes', name: 'Tariff Code'},
    {key: 'bundleInds', name: 'Bundle Ind.'},
    {key: 'bundleMasterNo', name: 'Bundle Master No.'},
    {key: 'chargeTypes', name: 'Container Type'},
    {key: 'subChargeTypes', name: 'Sub Charge Type'},    
    {key: 'cntrSizes', name: 'Container Size'},
    {key: 'cntrTypes', name: 'Container Type'},    
    {key: 'adjTypes', name: 'Adj Type'},    
    {key: 'arrivalVesselCode', name: 'Vsl Code(Arrival)'},    
    {key: 'arrivalVoyageCode', name: 'Voy Code(Arrival)'},    
    {key: 'statusCOKDs', name: 'status (COKD)'},    
    {key: 'emptyInd', name: 'Empty Ind.'},    
    {key: 'operationTypes', name: 'Operating Type'},    
    {key: 'departureVesselCode', name: 'Vsl Code(Departure)'},    
    {key: 'departureVoyageCode', name: 'Voy Code(Departure)'},    
    {key: 'specialHandlingInd', name: 'Sp Code'},    
    {key: 'specialHandlingIndD1', name: 'Sp Code(D1)'},    
    {key: 'cntrStatus', name: 'Container Status'},    
    {key: 'docNo', name: 'Invoice No.'},    
    {key: 'modalityArrivals', name: 'Modality of Arrival'},    
    {key: 'modalityDepartures', name: 'Modality of Departure'},    
    {key: 'opsLines', name: 'Ops Line'},    
    {key: 'coVslVoy', name: 'Co/Vsl/Voy'},  
    {key: 'forwarderCodes', name: 'Forwarder Code'},  
    {key: 'chargeCodes', name: 'Charge Code'},  
    {key: 'vesselName', name: 'Vessel Name'},        
]

export const CriteriaDirectoryChargeCheckboxOptionList: CheckboxPropsList = {
    option: {key: 'charge', name: 'CHARGE'},
    checkboxList: CriteriaDirectoryChargeCheckboxOption,
}

export const CriteriaDirectoryCheckboxOptionList: CheckboxPropsList[] = [
    CriteriaDirectoryChargeCheckboxOptionList
]