import { useChargeDetailVM } from "presentation/hook/ChargeDetailMaintenance/useChargeDetailVM";
import { ChargeDetailModel } from "presentation/model/ChargeDetailMaintenance/ChargeDetailModel";
import { InputField } from "veronica-ui-component/dist/component/core";

export const FspAccordion = ({chargeDetailState}:{chargeDetailState:ChargeDetailModel}) => {
    const chargeBatchUpdate = chargeDetailState.chargeBatchUpdate
    const chargeDetailVM = useChargeDetailVM();
    return (
        <>
            <div className={'im-flex-row'} style={{marginBottom:'24px'}}>
                <div className='im-flex-row-item'>
                    <InputField
                        label={`1st Tier FSP`}
                        width='150px'
                        type="number"
                        value={chargeBatchUpdate.firstTierFsp || ''}
                        onChange={(e: any) => chargeDetailVM.onInputTextChange(e, 'firstTierFsp', chargeBatchUpdate, 'chargeBatchUpdate')}/>
                </div>
                <div className='im-flex-row-item'>
                    <InputField
                        label={`2nd Tier FSP`}
                        width='150px'
                        type="number"
                        value={chargeBatchUpdate.secondTierFsp || ''}
                        onChange={(e: any) => chargeDetailVM.onInputTextChange(e, 'secondTierFsp', chargeBatchUpdate, 'chargeBatchUpdate')}/>
                </div>
            </div>
        </>
    )
}