export interface DeliveryChargeSummaryEnableSearchCriteria {   
    all: boolean,
    loginUser: boolean,
    vesselCode: boolean,
    voyageCode: boolean,
    vesselName: boolean,
    billingCycleList: boolean,
    billingCycleCode: boolean,
    soa: boolean,
    etd: boolean,
    eta: boolean,
    serviceCodeList: boolean,

    [key: string]: boolean,
}

export const DEFAULT_ENABLED_DELIVERY_CHARGE_SUMMARY_SEARCH_CRITERIA : DeliveryChargeSummaryEnableSearchCriteria = {
    all: true,
    loginUser: true,
    vesselCode: true,
    voyageCode: true,
    vesselName: true,
    billingCycleList: true,
    billingCycleCode: true,
    soa: true,
    etd: true,
    eta: true,
    serviceCodeList: true
}