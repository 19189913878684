import { ConsortiumEntity } from "domain/entity/ChargeDetailMaintenance/ConsortiumEntity";
import _ from "lodash";
import { axiosGetData } from "../axios/AxiosBasicImpl";
import referenceAxiosInstance from "../axios/referenceAxiosInstance";
import { ConsortiumRepository } from "./ConsortiumRepo";

export const ConsortiumRepoImpl = () : ConsortiumRepository => {
    const url = "/v1/consortium"

    const getAllConsortiums = async() : Promise<ConsortiumEntity[]> => {
        return axiosGetData(referenceAxiosInstance, url, []).then(res => {
            const data = res.data;
            return _.sortBy(data, ["key"]);
        }).catch(err => {
            return [];
        });
    }

    return {
        getAllConsortiums: getAllConsortiums,
    }
}