import { ChargeConstant } from "presentation/constant/ChargeHeader/ChargeConstant";
import { useDeliveryChargeSummaryMaintenanceVM } from "presentation/hook/ChargeHeaderMaintenance/useDeliveryChargeSummaryMaintenanceVM";
import { HeaderWithBackButton } from "presentation/view/components/HeaderWithBackButton/HeaderWithBackButton";
import { memo, useCallback } from "react";
import { HPHBreadcrumb, IconButton } from "veronica-ui-component/dist/component/core";
import { Breadcrumb, Sidebarheader, StyledAction } from "veronica-ui-component/dist/component/core/styled/uiFramework.styled";


const DeliveryChargeSummaryTitleBar:React.FC = () => {
    const DEL_CHG_SUMMARY_CONSTANT = ChargeConstant.DeliveryChargeSummary;
    const delChgSummaryVM = useDeliveryChargeSummaryMaintenanceVM();

    const handleSearchClick = useCallback(() => {
        delChgSummaryVM.onSearchClick();
    }, [delChgSummaryVM]);

    return <Sidebarheader style={{width: '100%'}}>
        <HeaderWithBackButton callback={delChgSummaryVM.onCloseClick}>
            <Breadcrumb>
                <HPHBreadcrumb breadcrumbData={[{ title: ChargeConstant.ChargeHeader.CHARGE_PROCESSING }, { title: DEL_CHG_SUMMARY_CONSTANT.DELIVERY_CHARGE_SUMMARY }]} onCurrentClick={()=>{}}></HPHBreadcrumb>
            </Breadcrumb>
        </HeaderWithBackButton>
        <StyledAction>
            <IconButton fileName='Icon-search' disabled={false} size='medium' toolTipText={'Search'} toolTipArrow={false} onClick={handleSearchClick} />
            {/*<IconButton fileName="Icon-cwp-basicview" size="medium" disabled={!currentRow?.differentCount || currentRow?.differentCount < 1} toolTipArrow={false} toolTipPlacement="left" toolTipText={'Different'} onClick={handleDifferentClick} />*/}
            {/* <IconButton fileName="Icon-cross" size="medium" disabled={false} toolTipArrow={false} toolTipPlacement="left" toolTipText={'Close'} onClick={delChgSummaryVM.onCloseClick} /> */}
        </StyledAction>
    </Sidebarheader>

}

export default memo(DeliveryChargeSummaryTitleBar);