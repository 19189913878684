import { ChargeDetailConstant } from "presentation/constant/ChargeDetailMaintenance/ChargeDetailConstant";
import { useChargeDetailVM } from "presentation/hook/ChargeDetailMaintenance/useChargeDetailVM";
import { useChargeDetailTracked } from "presentation/store/ChargeDetailMaintenance/ChargeDetailProvider";
import { memo, useEffect, useState } from "react";
import { IconButton, Loader } from "veronica-ui-component/dist/component/core";
import { SidebarActionCross, SidebarTitle, Sidebarheader } from "veronica-ui-component/dist/component/core/styled/uiFramework.styled";
import { ChargeDetailSummaryTablePanel } from "./ChargeDetailSummaryTablePanel";

const SummaryPanel = () => {
    const [chargeDetailState] = useChargeDetailTracked();
    const {chargeDetailSummary} = chargeDetailState;
    const CHARGEDETAIL_CONSTANT = ChargeDetailConstant.Charge;
    const chargeDetailVM = useChargeDetailVM();
    const [isLoading, setIsLoading] = useState<boolean>(false);

    const closeButtonClicked = () => {
        chargeDetailVM.onCloseSummary();
    }

    useEffect(() => {
        if (chargeDetailState.isShowSummaryPanel) {
            setIsLoading(true);
            chargeDetailVM.getChargeDetailSummary(chargeDetailState.currentChargeHeader).finally(() => {                
                setIsLoading(false);
            })
        }
    }, [chargeDetailState.currentChargeHeader, chargeDetailState.isShowSummaryPanel, chargeDetailVM]);

    if (!chargeDetailSummary || !chargeDetailState.isShowSummaryPanel) return <></>

    return <>
        <div className='side-form-content-left-wrapper'>
            {isLoading && <Loader Indicator="Spinner" size="Medium" />}
            <div className={'flex-row'}>
                <div className={'flex-row-item flex-row-item-full-width'}>
                    <Sidebarheader>
                        <SidebarTitle>{CHARGEDETAIL_CONSTANT.SUMMARY_BUT}</SidebarTitle>

                        <SidebarActionCross>
                            <IconButton
                                fileName="Icon-cross" size="medium" toolTipText='Close' toolTipArrow={false} onClick={closeButtonClicked} />
                        </SidebarActionCross>
                    </Sidebarheader>
                </div>
            </div>
            {/* <div className='im-charge-data-search-criteria-panel'>
            <div className='im-charge-data-search-criteria-content'>
                <div className='im-charge-data-search-criteria-group'>
                    {_.map(_.toPairs(chargeDetailSummary), ([key, value]) => {
                        return (
                            <>
                            <HPHGroupTitle id='im-preview-tag-group-title' titleText={key}/>
                            {_.map(_.toPairs(value), ([key, value]) => {
                                return <Tags
                                    label={`${key} : ${value}`}
                                    width='fit-content'
                                    rounded={true}/>
                            })}
                            </>
                        )}
                    )}                    
                </div>
            </div>
            </div> */}
            <div className={'add-edit-form'} style={{ maxHeight:'75vh' , height:'75vh', overflow: 'auto'}}>
                <div className="flex-row-auto">
                    <div className={'flex-row-item'}>
                        
                        {/* Stack Form Start */}
                        <ChargeDetailSummaryTablePanel/>
                    </div>                  
                </div>
            </div>
        </div>        
    </>
}

export default memo(SummaryPanel);