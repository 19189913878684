import { ChargeDetailEntity } from "domain/entity/ChargeDetailMaintenance/ChargeDetailEntity";
import { VesselVoyageMasterSearchCriteria } from "domain/entity/ChargeDetailMaintenance/VesselVoyageMasterSearchCriteria";
import { ChargeDetailConstant } from "presentation/constant/ChargeDetailMaintenance/ChargeDetailConstant";
import { useChargeDetailVM } from "presentation/hook/ChargeDetailMaintenance/useChargeDetailVM";
import { useChargeDetailTracked } from "presentation/store/ChargeDetailMaintenance/ChargeDetailProvider";
import { memo, useCallback, useState } from "react";
import { HPHButton, Loader } from "veronica-ui-component/dist/component/core";
import { SidebarActionBar, SidebarTitle, Sidebarheader } from "veronica-ui-component/dist/component/core/styled/uiFramework.styled";
import UpdateVesselVoyageMasterSearchForm from "./UpdateVesselVoyageMasterSearchForm";

const UpdateVesselVoyageMasterSearchPanel = () => {
    const CHARGEDETAIL_CONSTANT = ChargeDetailConstant.Charge;
    const chargeDetailVM = useChargeDetailVM();
    const [chargeDetailState] = useChargeDetailTracked();
    const [isLoading, setIsLoading] = useState<boolean>(false);

    const closeButtonClicked = useCallback(() => {
        chargeDetailVM.onVslVoyCloseClick();
    }, [chargeDetailVM]);

    const resetButtonClicked = useCallback(() => {
        chargeDetailVM.onVslVoySearchCriteriaResetClick();
    }, [chargeDetailVM]);


    const searchButtonClicked = useCallback((criteria:VesselVoyageMasterSearchCriteria, chargeList:ChargeDetailEntity[]) => {
        if (chargeList.length >=1) {
            if (chargeList[0].vesselCode) {
                criteria.currentVesselCode = chargeList[0].vesselCode;
            }
            if (chargeList[0].voyageCode) {
                criteria.currentVoyageCode = chargeList[0].voyageCode;
            }
            if (chargeList[0].operatingTml) {
                criteria.currentHandlingTerminal = chargeList[0].operatingTml;
            }
        }

        setIsLoading(true);
        criteria = {...criteria}
        chargeDetailVM.getVesselVoyageMaster(criteria).then((data) => {
            chargeDetailVM.onUpdateVslVoyTableClick();
            setIsLoading(false);
        }).catch(error => {
            setIsLoading(false);
        })

    }, [chargeDetailVM])

    return (
        <div className='side-form-content-wrapper'>
            {isLoading &&
                <Loader Indicator="Spinner" size="Medium"/>
            }
            <div className={'flex-row'}>
                <div className={'flex-row-item flex-row-item-full-width'}>
                    <Sidebarheader>
                        <SidebarTitle>{CHARGEDETAIL_CONSTANT.UPDATE_VESSEL_VOYAGE}</SidebarTitle>
                    </Sidebarheader>                
                </div>
            </div>
            <div className={'add-edit-form'} style={{ height:'69vh', maxHeight:'69vh' , overflow: 'auto'}}>
                <div className="flex-row-auto">
                    <div className='flex-row-item flex-row-item-full-width'>
                        
                        {/* Stack Form Start */}
                        <UpdateVesselVoyageMasterSearchForm chargeDetailState={chargeDetailState}/>
                    </div>                  
                </div>
            </div>
                <SidebarActionBar>
                <HPHButton label={'Close'} size={'Small'} theme={'Secondary'} onClick={closeButtonClicked} />
                <HPHButton label={'Reset'} size={'Small'} theme={'Secondary'} onClick={resetButtonClicked} />
                    <HPHButton label={'Search'} size={'Small'} theme={'Primary'} onClick={() => searchButtonClicked(chargeDetailState.searchVslVoyCriteria, chargeDetailState.chargeDetails)} />
                </SidebarActionBar>
        </div>
    );
}

export default memo(UpdateVesselVoyageMasterSearchPanel);