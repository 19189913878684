import { ChargeHeaderEntity } from "domain/entity/ChargeHeader/ChargeHeaderEntity"

export interface ChargeHeaderSearchCriteria {    
    loginUser?: string | null,
    vesselCode?: string | null,
    voyageCode?: string | null,
    vesselName?: string | null,
    billingCycleList?: string[] | null,
    billingCycleCode?: string | null,
    billToCompList?: string[] | null,
    soa?: string | null,
    etdDateFrom?: Date | null,
    etdDateTo?: Date | null,
    etbDateFrom?: Date | null,
    etbDateTo?: Date | null,
    etaDateFrom?: Date | null,
    etaDateTo?: Date | null,
    serviceCodeList?: string[] | null,
    vesselTypeList?: string[] | null,
    stateList?: string[] | null,
    hdrGroupName?: string | undefined,
    toBill?: boolean,
    id?: number | null,
    tempHdrGroupName?: string | null,
    screenInited?: boolean | null,
    type?: string | null,
    chargeHdr?: ChargeHeaderEntity | null

    [key: string]: string[] | string | boolean | number | Date | null | undefined | ChargeHeaderEntity
}

export const EMPTY_CHARGE_HEADER_SEARCH_CRITERIA : ChargeHeaderSearchCriteria = {
    loginUser: null,
    vesselCode:null,
    voyageCode: null,
    vesselName: null,
    billingCycleList: [],
    billingCycleCode: null,
    billToCompList: [] ,
    soa: null,
    etdDateFrom: null,
    etdDateTo: null,
    etbDateFrom: null,
    etbDateTo: null,
    etaDateFrom: null,
    etaDateTo: null,
    serviceCodeList: [],
    vesselTypeList: [],
    stateList: [],
    hdrGroupName: 'SHIPBOUNDED',
    toBill: false,
    id: null,
    tempHdrGroupName: 'SHIPBOUNDED',
    screenInited: false,
    type: "",
    chargeHdr: null
}