import { useInboundContainerSummaryMaintenanceVM } from "presentation/hook/ChargeHeaderMaintenance/useInboundContainerSummaryMaintenanceVM";
import { useChargeHeaderMaintenanceTracked } from "presentation/store/ChargeHeaderMaintenance/ChargeHeaderMaintenanceProvider";
import { useInboundContainerSummaryMaintenanceTracked } from "presentation/store/ChargeHeaderMaintenance/InboundContainerSummaryMaintenanceProvider";
import { useEffect } from "react";
import { Loader, SliderPanel } from "veronica-ui-component/dist/component/core";
import { InboundContainerSummarySearchCriteriaPanel } from "./InboundContainerSummarySearchCriteriaPanel";
import { InboundContainerSummaryTablePanel } from "./InboundContainerSummaryTablePanel";
import InboundContainerSummaryTitleBar from "./InboundContainerSummaryTitleBar";


export const InboundContainerSummaryComp: React.FC = () => {
    const [ibCntrSummaryState] = useInboundContainerSummaryMaintenanceTracked();
    const isShowCriteriaPanel = ibCntrSummaryState.isShowCriteriaPanel;

    const [chargeHdrState] = useChargeHeaderMaintenanceTracked();
    const ibCntrSumMainVM = useInboundContainerSummaryMaintenanceVM();
    const editingHdr = chargeHdrState.currentChargeHeader;

    useEffect(() => {
        ibCntrSumMainVM.onShowLoading();
        ibCntrSumMainVM.onPageLoad(editingHdr).then(data => {
            ibCntrSumMainVM.onHideLoading();
        }).then(error => {
            ibCntrSumMainVM.onHideLoading();
        })
    }, [editingHdr, ibCntrSumMainVM]);

    //if (ibCntrSummaryState.isLoading) return <Loader Indicator="Stripe" size="Large" />;
    
    return <>
        <div className={`main-comp-wrapper${isShowCriteriaPanel ? '' : ' im-hide-side-form-draggable'}`}>
            <InboundContainerSummaryTitleBar/>    
            {ibCntrSummaryState.isLoading && <Loader Indicator="Stripe" size="Large" />}        
            <SliderPanel
                isOpen={true}
                draggable={false}
                leftSectionWidth={isShowCriteriaPanel?"20%":"0%"}
                rightSectionWidth={isShowCriteriaPanel?"80%":"100%"}
                leftChildren={<InboundContainerSummarySearchCriteriaPanel/>}
                rightChildren={<InboundContainerSummaryTablePanel/>}
               />
        </div>
    </>
}