import { useChargeDetailVM } from "presentation/hook/ChargeDetailMaintenance/useChargeDetailVM";
import { ChargeDetailModel } from "presentation/model/ChargeDetailMaintenance/ChargeDetailModel";
import { InputField } from "veronica-ui-component/dist/component/core";

export const UpdateAbsolutelyCommonAcAccordion = ({chargeDetailState}:{chargeDetailState:ChargeDetailModel}) => {
    const chargeBatchUpdate = chargeDetailState.chargeBatchUpdate
    const chargeDetailVM = useChargeDetailVM();
    return (
        <>
            <div className={'im-flex-row'}  style={{marginBottom:'24px'}}>
                <div className='im-flex-row-item'>
                    <InputField
                        label='Charge Qty'
                        width='330px'
                        type="number"
                        value={chargeBatchUpdate.cmAcFixQty || ''}
                        onChange={(e) => chargeDetailVM.onInputTextChange(e, 'cmAcFixQty', chargeBatchUpdate, 'chargeBatchUpdate')}
                        />
                </div>
            </div>
        </>
    )
}