import { Permission } from "presentation/constant/ANAInfo/PermissionName";
import { ChargeDetailProvider } from "presentation/store/ChargeDetailMaintenance/ChargeDetailProvider";
import { ANAInfoWrapper } from "presentation/view/components/ANAInfo/ANAInfoWrapper";
import { MessageBarWrapper } from "presentation/view/components/MessageBarWrapper";
import ChargeDetailComp from "presentation/view/section/ChargeDetailMaintenance/ChargeDetailComp";
import { GridStyles } from "veronica-ui-component/dist/component/core/styled/table.styled";

const ChargeDetailContMain = () => {
    return <ANAInfoWrapper permission={Permission.CHARGE_DEATIL_MAINTENANCE}>
        <MessageBarWrapper>
            <ChargeDetailProvider>
                <GridStyles/>
                <ChargeDetailComp/>
            </ChargeDetailProvider>
        </MessageBarWrapper>
    </ANAInfoWrapper>
}

export default ChargeDetailContMain;