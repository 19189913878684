import { TerminalEntity } from "domain/entity/Terminal/TerminalEntity";
import { axiosGetData } from "../axios/AxiosBasicImpl";
import referenceAxiosInstance from "../axios/referenceAxiosInstance";
import { TerminalRepository } from "./TerminalRepo";

export const TerminalRepoImpl = ():TerminalRepository => {
    const url = '/v1/terminalSearchForComboBox'
    const getTerminalsForComboBox = async() : Promise<TerminalEntity[]> => {
        return axiosGetData(referenceAxiosInstance, url,[]).then(res => {
            return res.data;
        }).catch(err => {
            return [];
        })
    }

    return{
        getTerminalsForComboBox: getTerminalsForComboBox
    }
}