import { AbstractEntity } from "../Common/AbstractEntity";

export interface VesselSummaryTdrEntity extends AbstractEntity{

    id: number | null,
    chargeHdrId: number | null,
    dischargeFullSize20: number | null,
    dischargeFullSize40: number | null,
    dischargeFullSize45: number | null,
    dischargeFullSize48: number | null,
    dischargeFullSize53: number | null,
    dischargeEmptySize20: number | null,
    dischargeEmptySize40: number | null,
    dischargeEmptySize45: number | null,
    dischargeEmptySize48: number | null,
    dischargeEmptySize53: number | null,
    loadingFullSize20: number | null,
    loadingFullSize40: number | null,
    loadingFullSize45: number | null,
    loadingFullSize48: number | null,
    loadingFullSize53: number | null,
    loadingEmptySize20: number | null,
    loadingEmptySize40: number | null,
    loadingEmptySize45: number | null,
    loadingEmptySize48: number | null,
    loadingEmptySize53: number | null,
    restowSize20: number | null,
    restowSize40: number | null,
    restowSize45: number | null,
    restowSize48: number | null,
    restowSize53: number | null,
    shiftSize20: number | null,
    shiftSize40: number | null,
    shiftSize45: number | null,
    shiftSize48: number | null,
    shiftSize53: number | null,
    remark: string | null,    

}

export const EMPTY_VESSEL_SUMMARY_TDR_ENTITY: VesselSummaryTdrEntity = {
    id: null,
    chargeHdrId: null,
    dischargeFullSize20: null,
    dischargeFullSize40: null,
    dischargeFullSize45: null,
    dischargeFullSize48: null,
    dischargeFullSize53: null,
    dischargeEmptySize20: null,
    dischargeEmptySize40: null,
    dischargeEmptySize45: null,
    dischargeEmptySize48: null,
    dischargeEmptySize53: null,
    loadingFullSize20: null,
    loadingFullSize40: null,
    loadingFullSize45: null,
    loadingFullSize48: null,
    loadingFullSize53: null,
    loadingEmptySize20: null,
    loadingEmptySize40: null,
    loadingEmptySize45: null,
    loadingEmptySize48: null,
    loadingEmptySize53: null,
    restowSize20: null,
    restowSize40: null,
    restowSize45: null,
    restowSize48: null,
    restowSize53: null,
    shiftSize20: null,
    shiftSize40: null,
    shiftSize45: null,
    shiftSize48: null,
    shiftSize53: null,
    remark: null,
    operatingUnit: null,
    operatingCompany: null,
    createdBy: null,
    createdDateTime: null,
    updatedBy: null,
    updatedDateTime: null,
    versionStamp: 0,
    deleteInd: 'N',
}