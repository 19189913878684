import { ChargeDetailEntity } from "./ChargeDetailEntity";	

export interface ChargeDetailSeqCriteria{
	chargeList?: ChargeDetailEntity[] | null,	
	billToCompany?: string | null,	
	toSeq?: string | null,		
	yourRef?: string | null,
	ourRef?: string | null,		
	poNo?: string | null,		
	slVesselCode?: string | null,		
	slIbVoyageCode?: string | null,		
	slObVoyageCode?: string | null,	
	seqList?: string[] | null,
    [key: string]: string[] | string | boolean | null | Object | undefined
}

export const EMPTY_CHARGE_DETAIL_SEQ_CRITERIA_ENTITY : ChargeDetailSeqCriteria = {
	chargeList: null,	
	billToCompany: null,	
	toSeq: null,		
	yourRef: null,
	ourRef: null,		
	poNo: null,		
	slVesselCode: null,		
	slIbVoyageCode: null,		
	slObVoyageCode: null,	
	seqList: null,
}