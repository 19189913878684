import { INITIAL_VESSEL_SUMMARY_HEADER_COL_DEF, transferRowData } from "presentation/constant/ChargeHeader/VesselSummary/VesselSummaryColumnDefinition";
import { useChargeHeaderMaintenanceTracked } from "presentation/store/ChargeHeaderMaintenance/ChargeHeaderMaintenanceProvider";
import { useVesselSummaryMaintenanceTracked } from "presentation/store/ChargeHeaderMaintenance/VesselSummaryMaintenanceProvider";
import { TableWrapper } from "presentation/view/components/TableWrapper/TableWrapper";
import { useMemo } from "react";
import { HPHTable } from "veronica-ui-component/dist/component/core";


export const VesselSummaryTablePanel: React.FC = () => {
    const [vslSumMainState] = useVesselSummaryMaintenanceTracked();
    const [chargeHeaderState] = useChargeHeaderMaintenanceTracked();
    
    const memoGroupTable = useMemo(() => {
        return (            
            <HPHTable
            id={'Vessel Summary Table'}
            isNewColumnSetting={true}
            // headerLabel={VSL_SUMMARY_CONSTANT.VESSEL_SUMMARY}
            columns={INITIAL_VESSEL_SUMMARY_HEADER_COL_DEF}
            data={transferRowData(vslSumMainState.vesselSummaryData?.cntrInfoList, (chargeHeaderState?.currentChargeHeader?.id??0))}
            showPaginator={false}
            editable={false}            
            showAddIcon={false}
            showDeleteButton={false}
            showReloadIcon={false}
            isScrollHighlighted={true}
            gridHeight="customHeight"
            customHeight="calc(79vh)" />
        );
    }, [vslSumMainState.vesselSummaryData?.cntrInfoList, chargeHeaderState?.currentChargeHeader?.id])

    return <TableWrapper>{memoGroupTable}</TableWrapper>
}


