import { useChargeDetailTracked } from "presentation/store/ChargeDetailMaintenance/ChargeDetailProvider";
import { memo, useMemo } from "react";
import { SliderPanel } from "veronica-ui-component/dist/component/core";
import ChargeDetailMaintenancePanel from "./ChargeDetailMaintenancePanel";
import ChargeDetailTablePanel from "./ChargeDetailTablePanel";
import ChargeSearchPanel from "./ChargeSearchPanel";

const ChargeRightPanel = () => {
    const [chargeDetailState] = useChargeDetailTracked();
    const { isShowBatchUpdatePanel, isShowRightEditPanel, isShowCntrInfoPanel, isShowUpdateVesselVoyageMasterSearchPanel, 
        isShowUpdateVesselVoyageMasterTablePanel, isShowAssignSeqNoPanel, isShowUpdateStatus  } = chargeDetailState;
    
    const memoChargeDetailTable = useMemo(() => {
        return <ChargeDetailTablePanel/>
    },[])

    const memoChargeDetailMaintenance = useMemo(() => {
        return <ChargeDetailMaintenancePanel/>
    },[])

    const memoSearchCriteriaPanel = useMemo(() => {
        return <ChargeSearchPanel/>
    }, [])

    const showRightSection = isShowBatchUpdatePanel || isShowRightEditPanel || isShowCntrInfoPanel
                || isShowUpdateVesselVoyageMasterSearchPanel || isShowUpdateVesselVoyageMasterTablePanel
                || isShowAssignSeqNoPanel || isShowUpdateStatus;

    const leftPanel = useMemo(() => {
        return <div className={`main-comp-wrapper${chargeDetailState.isShowCriteriaPanel && !showRightSection ? '' : ' im-hide-side-form-draggable'}`}>
            <SliderPanel
                isOpen={true}
                draggable={false}
                leftSectionWidth={chargeDetailState.isShowCriteriaPanel && !showRightSection?"25%":"0%"}
                rightSectionWidth={chargeDetailState.isShowCriteriaPanel && !showRightSection?"75%":"100%"}
                leftChildren={memoSearchCriteriaPanel}
                rightChildren={memoChargeDetailTable}
            />
        </div>
    }, [chargeDetailState.isShowCriteriaPanel, memoChargeDetailTable, memoSearchCriteriaPanel, showRightSection]);

    return <>
        <div className={`main-comp-wrapper${showRightSection ? '' : ' im-hide-side-form-draggable'}`}>
            {/* left is table panel
                right is search criteria panel when the isShowSearchCriteria
                      is batch edit panel when the isShowBatchUpdate*/}                      
            <SliderPanel
                isOpen={showRightSection?true:false}
                draggable={true}
                leftSectionWidth={showRightSection?"75%":"100%"}
                rightSectionWidth={showRightSection?"25%":"0%"}
                leftChildren={leftPanel}
                rightChildren={memoChargeDetailMaintenance}
            />
        </div>
    </>
}

export default memo(ChargeRightPanel);