import { useChargeDetailVM } from "presentation/hook/ChargeDetailMaintenance/useChargeDetailVM";
import { ChargeDetailModel } from "presentation/model/ChargeDetailMaintenance/ChargeDetailModel";
import { InputField } from "veronica-ui-component/dist/component/core";

export const PooPolPodFdAccordion = ({chargeDetailState}:{chargeDetailState:ChargeDetailModel}) => {
    const chargeBatchUpdate = chargeDetailState.chargeBatchUpdate
    const chargeDetailVM = useChargeDetailVM();
    return (
        <>
            <div className={'im-flex-row'} style={{marginBottom:'24px'}}>
                <div className='im-flex-row-item'>
                    <InputField
                        label='Poo'
                        width='150px'
                        type="text"
                        value={chargeBatchUpdate.poo || ''}
                        onChange={(e: any) => chargeDetailVM.onInputTextChange(e, 'poo', chargeBatchUpdate, 'chargeBatchUpdate')}/>
                </div>
                <div className='im-flex-row-item'>
                    <InputField
                        label='Pol'
                        width='150px'
                        type="text"
                        value={chargeBatchUpdate.pol || ''}
                        onChange={(e: any) => chargeDetailVM.onInputTextChange(e, 'pol', chargeBatchUpdate, 'chargeBatchUpdate')}/>
                </div>
            </div>
            <div className={'im-flex-row'} style={{marginBottom:'24px'}}>
                <div className='im-flex-row-item'>
                    <InputField
                        label='Pod'
                        width='150px'
                        type="text"
                        value={chargeBatchUpdate.pod || ''}
                        onChange={(e: any) => chargeDetailVM.onInputTextChange(e, 'pod', chargeBatchUpdate, 'chargeBatchUpdate')}/>
                </div>
                <div className='im-flex-row-item'>
                    <InputField
                        label='Fd'
                        width='150px'
                        type="text"
                        value={chargeBatchUpdate.fd || ''}
                        onChange={(e: any) => chargeDetailVM.onInputTextChange(e, 'fd', chargeBatchUpdate, 'chargeBatchUpdate')}/>
                </div>
            </div>
        </>
    )
}