import { DeliveryChargeSummaryEntity } from "domain/entity/ChargeHeader/DeliveryChargeSummaryEntity";
import { E_Custom_Dispatch_Event, E_Type_Of_Event, customDispatchEvent } from "helpers/Events";
import { INITIAL_DELIVERY_CHARGE_SUMMARY_HEADER_COL_DEF, transferRowData } from "presentation/constant/ChargeHeader/DeliveryChargeSummary/DeliveryChargeSummaryColumnDefinition";
import { MessageConstant } from "presentation/constant/MessageConstant";
import { useChargeDetailVM } from "presentation/hook/ChargeDetailMaintenance/useChargeDetailVM";
import { useMessageBarVM } from "presentation/hook/useMessageBar";
import { useDeliveryChargeSummaryMaintenanceTracked } from "presentation/store/ChargeHeaderMaintenance/DeliveryChargeSummaryMaintenanceProvider";
import { TableWrapper } from "presentation/view/components/TableWrapper/TableWrapper";
import { useCallback, useMemo } from "react";
import { HPHTable } from "veronica-ui-component/dist/component/core";


export const DeliveryChargeSummaryTablePanel: React.FC = () => {
    const [ibCntrSummaryState] = useDeliveryChargeSummaryMaintenanceTracked();
    const chargeDetailVM = useChargeDetailVM();
    const messageBarVM = useMessageBarVM();    

    const tableDoubleClicked = useCallback(async(row:any) => {
        if (row) {
            const summaryRow:DeliveryChargeSummaryEntity = row.data as DeliveryChargeSummaryEntity;
            if (!summaryRow.billingCycleCode) {
                messageBarVM.showWarining(MessageConstant.charge.DELIVERY_RECORD_MISSING_BILLING_CYCLE_CODE);
                return;
            }
            chargeDetailVM.getChargeDetailsForDeliverySummary(row.data as DeliveryChargeSummaryEntity).then(resData => {
                if (resData === "Missing CY/YH Charges") {
                    messageBarVM.showWarining(MessageConstant.charge.DELIVERY_RECORD_MISSING_CY_YH);
                    return;
                }
                customDispatchEvent(E_Type_Of_Event.CHARGE_DETAIL_REDIRECT_EVENT,
                    E_Custom_Dispatch_Event.REDIRECT_CHARGE_DETAIL_FROM_DELIVERY_SUMMARY,
                    {data:
                        {
                            ...resData,
                            redirectType: "doubleClick",
                        }
                    }
                );
            });            
        }
    }, [chargeDetailVM, messageBarVM]);
    
    const memoGroupTable = useMemo(() => {
        return (            
            <HPHTable
            id={'Inbound Container Summary Table'}
            isNewColumnSetting={true}
            columns={INITIAL_DELIVERY_CHARGE_SUMMARY_HEADER_COL_DEF}
            // headerLabel={IB_CNTR_SUMMARY_CONSTANT.DELIVERY_CHARGE_SUMMARY}
            data={transferRowData(ibCntrSummaryState.deliveryChargeSummaryDataList)}
            onRowDoubleClick={tableDoubleClicked}
            showPaginator={false}
            editable={false}            
            showAddIcon={false}
            showDeleteButton={false}
            showReloadIcon={false}
            isScrollHighlighted={true}
            selectionMode={false}
            gridHeight="customHeight"
            customHeight="calc(100vh - 125px)" 
            // onRowClick={(row: any)=>delChgSummaryVM.onSelectedDeliveryChargeData(row.data)}
            />
        );
    }, [ibCntrSummaryState.deliveryChargeSummaryDataList, tableDoubleClicked])

    return <TableWrapper>{memoGroupTable}</TableWrapper>
}


