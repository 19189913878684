import { ChargeDataEnquiryRepoImpl } from "domain/repository/ChargeDataEnquiry/ChargeDataEnquiryRepoImpl";
import { ChargeHeaderRepoImpl } from "domain/repository/ChargeHeader/ChargeHeaderRepoImpl";
import { ChargeTypeRepoImpl } from "domain/repository/ChargeType/ChargeTypeRepoImpl";
import { SearchPreferenceRepoImpl } from "domain/repository/Common/SearchPreferenceRepoImpl";
import { CompanyRepoImpl } from "domain/repository/Company/CompanyRepoImpl";
import { MasterDataRepoImpl } from "domain/repository/MasterData/MasterDataRepoImpl";
import { SpecialHandlingIndMappingRepoImpl } from "domain/repository/SpecialHanldingInd/SpecialHandlingIndMappingRepoImpl";
import { StandardTariffCodeRepoImpl } from "domain/repository/TariffCode/StandardTariffCodeRepoImpl";
import { ParameterDetailRepoImpl } from "domain/repository/parameter/ParameterDetailRepoImpl";
import { useChargeHeaderMaintenanceTracked } from "presentation/store/ChargeHeaderMaintenance/ChargeHeaderMaintenanceProvider";
import { useWorkspaceTracked } from "presentation/store/NavigationMenu/NavigationMenu";
import { ChargeHeaderMaintenanceVM } from "presentation/viewModel/ChargeHeaderMaintenance/ChargeHeaderMaintenanceVM";
import { useMemo } from "react";

export const useChargeHeaderMaintenanceVM = () => {
    const [, setChargeHeaderMaintenanceState] = useChargeHeaderMaintenanceTracked();
    const [, setWorkspaceState] = useWorkspaceTracked();
    const chargeHeaderMaintenanceVM = useMemo(() =>
    ChargeHeaderMaintenanceVM({
            dispatch: [setChargeHeaderMaintenanceState, setWorkspaceState],
            companyRepo: CompanyRepoImpl(),
            chargeTypeRepo: ChargeTypeRepoImpl(),
            standardTariffCodeRepo: StandardTariffCodeRepoImpl(),
            parameterDetailRepo: ParameterDetailRepoImpl(),
            specialhandlingIndMappingRepo: SpecialHandlingIndMappingRepoImpl(),
            chargeDataEnquiryRepo: ChargeDataEnquiryRepoImpl(),
            searchPreferenceRepo: SearchPreferenceRepoImpl(),
            chargeHeaderRepo: ChargeHeaderRepoImpl(),
            masterDataRepo: MasterDataRepoImpl(),
        }), [setChargeHeaderMaintenanceState, setWorkspaceState])

    return chargeHeaderMaintenanceVM
}