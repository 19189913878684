import { useChargeDetailVM } from "presentation/hook/ChargeDetailMaintenance/useChargeDetailVM";
import { ChargeDetailModel } from "presentation/model/ChargeDetailMaintenance/ChargeDetailModel";
import DateTimePickerComponent from "presentation/view/components/DateTimePickerComponent";
import { InputField } from "veronica-ui-component/dist/component/core";

export const RFInfoAccordion = ({chargeDetailState}:{chargeDetailState:ChargeDetailModel}) => {
    const chargeBatchUpdate = chargeDetailState.chargeBatchUpdate
    const chargeDetailVM = useChargeDetailVM();
    return (
        <>
            <div className={'im-flex-row'} style={{marginBottom:'24px'}}>
                <div className='im-flex-row-item'>
                    <InputField
                        label='Charging Qty'
                        width='330px'
                        type="number"
                        value={chargeBatchUpdate.noOfLift || ''}
                        onChange={(e: any) => chargeDetailVM.onInputTextChange(e, 'noOfLift', chargeBatchUpdate, 'chargeBatchUpdate')}/>
                </div>
            </div>
            
            <div className={'im-flex-row'} style={{marginBottom:'24px'}}>
                <div className='im-flex-row-item'>
                    <DateTimePickerComponent
                        label='Create Date/Time'
                        width='330px'
                        date={chargeBatchUpdate.createDatetime}
                        onDateChange={chargeDetailVM.onDatePickerChange}
                        fieldName={"createDatetime"}/>
                </div>
            </div>

            <div className={'im-flex-row'} style={{marginBottom:'24px'}}>
                <div className='im-flex-row-item'>
                    <DateTimePickerComponent
                        label='Req. Pickup Date/Time'
                        width='330px'
                        date={chargeBatchUpdate.reqPickupDatetime}
                        onDateChange={chargeDetailVM.onDatePickerChange}
                        fieldName={"reqPickupDatetime"}/>
                </div>
            </div>

            <div className={'im-flex-row'} style={{marginBottom:'24px'}}>
                <div className='im-flex-row-item'>
                    <DateTimePickerComponent
                        label='Power Plug-On Date/Time'
                        width='330px'
                        date={chargeBatchUpdate.plugOnDatetime}
                        onDateChange={chargeDetailVM.onDatePickerChange}
                        fieldName={"plugOnDatetime"}/>
                </div>
            </div>
            
            <div className={'im-flex-row'} style={{marginBottom:'24px'}}>
                <div className='im-flex-row-item'>
                    <DateTimePickerComponent
                        label='Power Plug-Off Date/Time'
                        width='330px'
                        date={chargeBatchUpdate.plugOffDatetime}
                        onDateChange={chargeDetailVM.onDatePickerChange}
                        fieldName={"plugOffDatetime"}/>
                </div>
            </div>

            <div className={'im-flex-row'} style={{marginBottom:'24px'}}>
                <div className='im-flex-row-item'>
                    <DateTimePickerComponent
                        label='Power Cons. Start Date/Time'
                        width='330px'
                        date={chargeBatchUpdate.consStartDatetime}
                        onDateChange={chargeDetailVM.onDatePickerChange}
                        fieldName={"consStartDatetime"}/>
                </div>
            </div>
            
            <div className={'im-flex-row'} style={{marginBottom:'24px'}}>
                <div className='im-flex-row-item'>
                    <DateTimePickerComponent
                        label='Power Cons. End Date/Time'
                        width='330px'
                        date={chargeBatchUpdate.consEndDatetime}
                        onDateChange={chargeDetailVM.onDatePickerChange}
                        fieldName={"consEndDatetime"}/>
                </div>
            </div>
        </>
    )
}