export interface VesselSummaryHeaderField {   
    vesselType?: string | null,
    vesselCode?: string | null,
    voyageCode?: string | null,
    consortiumCode?: string | null,
    etd?: Date | null,
    lashing?: number | null,
    gearbox?: number | null,
    isoHatch?: number | null,
    lateArrival?: number | null,
    shiftingBB?: number | null,
    restowBSB?: number | null,
    handlingTml?: string | null,

    [key: string]: string | Date | number | null | undefined,
}

export const DEFAULT_VESSEL_SUMMARY_HEADER_FIELD : VesselSummaryHeaderField = {
    
    vesselType: 'test',
    vesselCode: 'test',
    voyageCode: 'test',
    consortiumCode: 'test',
    etd: null,
    lashing: 1,
    gearbox: 2,
    isoHatch: 3,
    lateArrival: 4,
    shiftingBB: 5,
    restowBSB: 6,
    handlingTml: 'dct',
}