import { EMPTY_SEARCH_MODE_ENTITY } from "domain/entity/ChargeDataEnquiry/SearchModeEntity";
import { ChargeDetailConstant } from "presentation/constant/ChargeDetailMaintenance/ChargeDetailConstant";
import { MessageConstant } from "presentation/constant/MessageConstant";
import { useChargeDetailVM } from "presentation/hook/ChargeDetailMaintenance/useChargeDetailVM";
import { useChargeHeaderMaintenanceVM } from "presentation/hook/ChargeHeaderMaintenance/useChargeHeaderMaintenanceVM";
import { useMessageBarVM } from "presentation/hook/useMessageBar";
import { useChargeDetailTracked } from "presentation/store/ChargeDetailMaintenance/ChargeDetailProvider";
import { useChargeHeaderMaintenanceTracked } from "presentation/store/ChargeHeaderMaintenance/ChargeHeaderMaintenanceProvider";
import { memo, useCallback, useState } from "react";
import { HPHButton, Loader } from "veronica-ui-component/dist/component/core";
import { SidebarActionBar, SidebarTitle, Sidebarheader } from "veronica-ui-component/dist/component/core/styled/uiFramework.styled";
import AssignSeqNoForm from "./AssignSeqNoForm";

const AssignSeqNoPanel = () => {
    const CHARGEDETAIL_CONSTANT = ChargeDetailConstant.Charge;
    const chargeDetailVM = useChargeDetailVM();
    const chargeHeaderVM = useChargeHeaderMaintenanceVM();
    const [chargeDetailState] = useChargeDetailTracked();
    const [chargeHeaderState] = useChargeHeaderMaintenanceTracked();
    const messageBarVM = useMessageBarVM();
    const [isLoading, setIsLoading] = useState<boolean>(false);

    const closeButtonClicked = useCallback(() => {
        chargeDetailVM.onAssignSeqNoCloseClick();
    }, [chargeDetailVM]);
    
    const saveButtonClicked = useCallback(async() => {        
        let newSearchCriteria = chargeDetailState.searchCriteria;
        newSearchCriteria = {...newSearchCriteria, chargeHdrId: Number(chargeDetailState?.currentChargeHeader?.id??chargeHeaderState?.currentChargeHeader?.id)??0}

        setIsLoading(true);
        chargeDetailVM.onChargeDetailSeqUpdateSave(chargeDetailState.searchSeqCriteria).then(async(data) => {
            Promise.allSettled([
                chargeDetailVM.getChargeDetails(newSearchCriteria),                        
                chargeHeaderVM.onSearch({
                    ...chargeDetailState.searchCriteria,
                    id: chargeDetailState?.currentChargeHeader?.id??chargeHeaderState?.currentChargeHeader?.id
                }, EMPTY_SEARCH_MODE_ENTITY, true, true)
            ]).finally(() => {
                if (data && data["assignSeqFail"]) {
                    const err:string = data["assignSeqFail"]?.toString()??"";
                    messageBarVM.showError(err.replace("Error:", ""));
                } else {
                    messageBarVM.showSuccess(`${MessageConstant.common.OPERATION_DONE_SUCCESSFUL.replace("{operationDone}", ChargeDetailConstant.Charge.ASSIGN_SEQ_NO)}`);
                }                        
                setIsLoading(false);
            });
        }).catch(() => {
            setIsLoading(false);
        })
    }, [chargeDetailVM, chargeDetailState.searchSeqCriteria, messageBarVM, chargeDetailState.searchCriteria, 
        chargeDetailState?.currentChargeHeader?.id, chargeHeaderState?.currentChargeHeader?.id, chargeHeaderVM])

    return (
        <div className='side-form-content-wrapper'>
            {isLoading && <Loader size="Medium" Indicator="Spinner"></Loader>}
            <div className='flex-row-item flex-row-item-full-width'>
                <div className='side-form-content-wrapper'>
                    <div className={'flex-row'}>
                        <div className={'flex-row-item flex-row-item-full-width'}>
                            <Sidebarheader>
                                <SidebarTitle>{CHARGEDETAIL_CONSTANT.ASSIGN_SEQ_NO}</SidebarTitle>
                            </Sidebarheader>                
                        </div>
                    </div>
                    <div className={'add-edit-form'} style={{ maxHeight:'75vh' , overflow: 'auto'}}>
                        <div className="flex-row-auto">
                            <div className={'flex-row-item'}>
                                
                                {/* Stack Form Start */}
                                <AssignSeqNoForm/>
                            </div>                  
                        </div>
                    </div>
                        <SidebarActionBar>
                            <HPHButton label={'Close'} size={'Small'} theme={'Secondary'} onClick={closeButtonClicked} />
                            <HPHButton label={'Save'} size={'Small'} theme={'Primary'} onClick={saveButtonClicked} />
                        </SidebarActionBar>
                </div>
            </div>
        </div>
    );
}

export default memo(AssignSeqNoPanel);