import { VesselSummaryEntity } from "domain/entity/ChargeHeader/VesselSummaryEntity";
import { VesselSummaryHdrEntity } from "domain/entity/ChargeHeader/VesselSummaryHdrEntity";
import { VesselSummaryRequestEntity } from "domain/entity/ChargeHeader/VesselSummaryRequestEntity";
import { axiosPostData } from "../axios/AxiosBasicImpl";
import chargeAxiosInstance from "../axios/chargeAxiosInstance";
import { VesselSummaryRepository } from "./VesselSummaryRepo";

export const VesselSummaryRepoImpl = () : VesselSummaryRepository => {
    const vslSummaryHdrUrl = "/v1/vesselsummaryhdrsearch"
    const vslSummaryDataUrl = "/v1/vesselsummarydatasearch"

    const getVesselSummaryHdr = (request: VesselSummaryRequestEntity) : Promise<VesselSummaryHdrEntity> => {
        return axiosPostData(chargeAxiosInstance, `${vslSummaryHdrUrl}`, request).then(res => {
            const data = res.data;
            return data;
        }).catch(err => {
            return [];
        });
    }

    const getVesselSummaryData = (request: VesselSummaryRequestEntity) : Promise<VesselSummaryEntity> => {
        return axiosPostData(chargeAxiosInstance, `${vslSummaryDataUrl}`, request).then(res => {
            const data = res.data;
            return data;
        }).catch(err => {
            return [];
        });
    }

    return {
        getVesselSummaryHdr: getVesselSummaryHdr,
        getVesselSummaryData: getVesselSummaryData,
    }
}