import { VesselSummaryTdrEntity } from "domain/entity/ChargeHeader/VesselSummaryTdrEntity";
import { axiosGetData, axiosPostData, axiosPutData } from "../axios/AxiosBasicImpl";
import chargeAxiosInstance from "../axios/chargeAxiosInstance";
import { VesselSummaryTdrRepository } from "./VesselSummaryTdrRepo";

export const VesselSummaryTdrRepoImpl = () : VesselSummaryTdrRepository => {
    const vslSummaryDataTdrUrl = "/v1/vesselsummarytdr"


    
    const getVesselSummaryTdrData = async (editingHdrId: number|null) : Promise<VesselSummaryTdrEntity> => {
        return axiosGetData(chargeAxiosInstance, `${vslSummaryDataTdrUrl}/${editingHdrId}`, []).then(res => {
            const data = res.data;
            return data;
        }).catch(err => {
            return [];
        });
    }

    const saveVesselSummaryTdrData = async (newData: VesselSummaryTdrEntity) : Promise<VesselSummaryTdrEntity> => {
        

        const result = await axiosPostData(chargeAxiosInstance, `${vslSummaryDataTdrUrl}`, newData).then(res => {
            const data = res.data;
            return data;
        }).catch(err => {
            let errorMessage = "Save TDR Data Failed."
           
            throw new Error(errorMessage);
        });
        return result;
    }

    const replaceVesselSummaryTdrData = async (request: VesselSummaryTdrEntity) : Promise<VesselSummaryTdrEntity> => {
        const result = await axiosPutData(chargeAxiosInstance, `${vslSummaryDataTdrUrl}`, request).then(res => {
            const data = res.data;
            return data
        }).catch(error => {
            return null
        })
        return result
    }

    return {
        getVesselSummaryTdrData: getVesselSummaryTdrData,
        saveVesselSummaryTdrData: saveVesselSummaryTdrData,
        replaceVesselSummaryTdrData: replaceVesselSummaryTdrData,
    }
}