import { ChargeDetailRepoImpl } from "domain/repository/ChargeDetailMaintenance/ChargeDetailRepoImpl";
import { ChargeDetailSeqCriteriaRepoImpl } from "domain/repository/ChargeDetailMaintenance/ChargeDetailSeqCriteriaRepoImpl";
import { ConsortiumRepoImpl } from "domain/repository/ChargeDetailMaintenance/ConsortiumRepoImpl";
import { VesselVoyageMasterRepoImpl } from "domain/repository/ChargeDetailMaintenance/VesselVoyageMasterRepoImpl";
import { ChargeTypeRepoImpl } from "domain/repository/ChargeType/ChargeTypeRepoImpl";
import { CompanyRepoImpl } from "domain/repository/Company/CompanyRepoImpl";
import { ForwarderCodeRepoImpl } from "domain/repository/ForwarderCode/ForwarderCodeRepoImpl";
import { MasterDataRepoImpl } from "domain/repository/MasterData/MasterDataRepoImpl";
import { OpsLineRepoImpl } from "domain/repository/OpsLine/OpsLineRepoImpl";
import { PhysicalHandlingCodeRepoImpl } from "domain/repository/PhysicalHandlingCode/PhysicalHandlingCodeRepoImpl";
import { SpecialHandlingIndMappingRepoImpl } from "domain/repository/SpecialHanldingInd/SpecialHandlingIndMappingRepoImpl";
import { SpecialIndRepoImpl } from "domain/repository/SpecialInd/SpecialIndRepoImpl";
import { StandardTariffCodeRepoImpl } from "domain/repository/TariffCode/StandardTariffCodeRepoImpl";
import { TerminalRepoImpl } from "domain/repository/Terminal/TerminalRepoImpl";
import { ParameterDetailRepoImpl } from "domain/repository/parameter/ParameterDetailRepoImpl";
import { useChargeDetailTracked } from "presentation/store/ChargeDetailMaintenance/ChargeDetailProvider";
import { useChargeHeaderMaintenanceTracked } from "presentation/store/ChargeHeaderMaintenance/ChargeHeaderMaintenanceProvider";
import { ChargeDetailVM } from "presentation/viewModel/ChargeDetailMaintenance/ChargeDetailVM";
import { useMemo } from "react";

export const useChargeDetailVM = () => {
    const [, setChargeDetailState] = useChargeDetailTracked();
    const [, setChargeHeaderState] = useChargeHeaderMaintenanceTracked();
    const chargeDetailVM = useMemo(() =>
        ChargeDetailVM({
            dispatch: [setChargeDetailState],
            headerDispatch: [setChargeHeaderState],
            companyRepo: CompanyRepoImpl(),
            chargeTypeRepo: ChargeTypeRepoImpl(),
            standardTariffCodeRepo: StandardTariffCodeRepoImpl(),
            parameterDetailRepo: ParameterDetailRepoImpl(),
            specialhandlingIndMappingRepo: SpecialHandlingIndMappingRepoImpl(),
            chargeDetailRepo: ChargeDetailRepoImpl(),
            forwarderCodeRepo: ForwarderCodeRepoImpl(),
            opsLineRepo: OpsLineRepoImpl(),
            terminalRepo: TerminalRepoImpl(),
            physicalHandlingCodeRepo: PhysicalHandlingCodeRepoImpl(),
            specialIndRepo: SpecialIndRepoImpl(),
            vesselVoyageMasterRepo: VesselVoyageMasterRepoImpl(),
            consortiumRepo: ConsortiumRepoImpl(),
            chargeDetailSeqCriteriaRepo: ChargeDetailSeqCriteriaRepoImpl(),
            masterDataRepo: MasterDataRepoImpl(),
        }), [setChargeDetailState, setChargeHeaderState])

    return chargeDetailVM
}