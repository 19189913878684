import { Permission } from "presentation/constant/ANAInfo/PermissionName";
import { InboundContainerSummaryMaintenanceProvider } from "presentation/store/ChargeHeaderMaintenance/InboundContainerSummaryMaintenanceProvider";
import { ANAInfoWrapper } from "presentation/view/components/ANAInfo/ANAInfoWrapper";
import { InboundContainerSummaryComp } from "presentation/view/section/ChargeHeaderMaintenance/InboundContainerSummary/InboundContainerSummaryComp";
import { GridStyles } from "veronica-ui-component/dist/component/core/styled/table.styled";

export const InboundContainerSummaryMaintenanceContainer = () => {
    return <ANAInfoWrapper permission={Permission.LOGIN}>
        <InboundContainerSummaryMaintenanceProvider>
            <GridStyles/>
            <InboundContainerSummaryComp />
        </InboundContainerSummaryMaintenanceProvider>
    </ANAInfoWrapper>;
};