import { useChargeDetailVM } from "presentation/hook/ChargeDetailMaintenance/useChargeDetailVM";
import { ChargeDetailModel } from "presentation/model/ChargeDetailMaintenance/ChargeDetailModel";
import { InputField } from "veronica-ui-component/dist/component/core";
import DateTimePickerComponent from "presentation/view/components/DateTimePickerComponent";

export const VesselInfoAccordion  = ({chargeDetailState}:{chargeDetailState:ChargeDetailModel}) => {
    const chargeBatchUpdate = chargeDetailState.chargeBatchUpdate
    const chargeDetailVM = useChargeDetailVM();
    return (
        <>
            <div className={'im-flex-row'} style={{marginBottom:'24px'}}>
                <div className='im-flex-row-item'>
                    <InputField
                        label={`Voy. Operating Tml`}
                        width='150px'
                        type="text"
                        maxLength={10}
                        value={chargeBatchUpdate.voyOperatingTml || ''}
                        onChange={(e: any) => chargeDetailVM.onInputTextChange(e, 'voyOperatingTml', chargeBatchUpdate, 'chargeBatchUpdate')}/>
                </div>
                <div className='im-flex-row-item'>
                    <InputField
                        label={`Voy. Service Code`}
                        width='150px'
                        type="text"
                        maxLength={50}
                        value={chargeBatchUpdate.voyServiceCode || ''}
                        onChange={(e: any) => chargeDetailVM.onInputTextChange(e, 'voyServiceCode', chargeBatchUpdate, 'chargeBatchUpdate')}/>
                </div>
            </div>
            
            <div className={'im-flex-row'} style={{marginBottom:'24px'}}>
                <div className='im-flex-row-item'>
                    <DateTimePickerComponent
                        label='First Discharge Date/Time'
                        width='330px'
                        date={chargeBatchUpdate.firstDischargeDatetime}
                        onDateChange={chargeDetailVM.onDatePickerChange}
                        fieldName={"firstDischargeDatetime"}/>
                        
                </div>
            </div>

            <div className={'im-flex-row'} style={{marginBottom:'24px'}}>
                <div className='im-flex-row-item'>
                    <DateTimePickerComponent
                        label='Last Discharge Date/Time'
                        width='330px'
                        date={chargeBatchUpdate.lastDischargeDatetime}
                        onDateChange={chargeDetailVM.onDatePickerChange}
                        fieldName={"lastDischargeDatetime"}/>
                        
                </div>
            </div>

            <div className={'im-flex-row'} style={{marginBottom:'24px'}}>
                <div className='im-flex-row-item'>
                    <DateTimePickerComponent
                        label='First Loading Date/Time'
                        width='330px'
                        date={chargeBatchUpdate.firstLoadingDatetime}
                        onDateChange={chargeDetailVM.onDatePickerChange}
                        fieldName={"firstLoadingDatetime"}/>
                        
                </div>
            </div>

            <div className={'im-flex-row'} style={{marginBottom:'24px'}}>
                <div className='im-flex-row-item'>
                    <DateTimePickerComponent
                        label='Last Loading Date/Time'
                        width='330px'
                        date={chargeBatchUpdate.lastLoadingDatetime}
                        onDateChange={chargeDetailVM.onDatePickerChange}
                        fieldName={"lastLoadingDatetime"}/>
                        
                </div>
            </div>
        </>
    )
}