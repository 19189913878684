import { EMPTY_IBOUND_CONTAINER_SUMMARY_MODEL } from 'presentation/model/ChargeHeaderMaintenance/InboundContainerSummaryMaintenanceModel';
import { useState } from 'react';
import { createContainer } from "react-tracked";

const {
    Provider: InboundContainerSummaryMaintenanceProvider,
    useTracked: useInboundContainerSummaryMaintenanceTracked
} = createContainer(() => useState(EMPTY_IBOUND_CONTAINER_SUMMARY_MODEL), {concurrentMode: true});
export { InboundContainerSummaryMaintenanceProvider, useInboundContainerSummaryMaintenanceTracked };

