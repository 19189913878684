import { AdjustmentTypePolicy } from "constants/charge/AdjustmentTypePolicy";
import { ChargeDetailStatePolicy } from "constants/charge/ChargeDetailStatePolicy";
import { ContainerStatusPolicy } from "constants/charge/ContainerStatusPolicy";
import { ChargeDetailEntity } from "domain/entity/ChargeDetailMaintenance/ChargeDetailEntity";
import { EMPTY_CHARGE_DETAIL_SEARCH_CRITERIA } from "domain/entity/ChargeDetailMaintenance/ChargeDetailSearchCriteria";
import { ContainerInformationEntity } from "domain/entity/ChargeHeader/ContainerInformationEntity";
import { InboundContainerSummaryEntity } from "domain/entity/ChargeHeader/InboundContainerSummaryEntity";
import { MessageConstant } from "presentation/constant/MessageConstant";


export const CHARGE_TYPE_OUTBOUND = "changeType1";
export const CHARGE_TYPE_INBOUND = "changeType4";
export const CHARGE_TYPE_TS_INBOUND = "changeType3";
export const CHARGE_TYPE_TS_OUTBOUND = "changeType2";
export const CHARGE_TYPE_OLD_NEW_RESH = "changeType5";
export const CHARGE_TYPE_OLD_NEW_DIFF_RESH = "changeType6";

/**
 * initial search criteria for charge detail screen
 * the condition pass from vessel summary screen
 * @param vesselSummaryData
 * @returns 
 */
export const initalVesselSummarySearchCriteria = (vesselSummaryData:any) => {
    let newSearchCriteria = {...EMPTY_CHARGE_DETAIL_SEARCH_CRITERIA};

    if (vesselSummaryData.redirectType === "RESH_DETAIL") {
        newSearchCriteria = { ...newSearchCriteria, 
            chargeHdrId: Number(vesselSummaryData.chargeHdrId)??0, 
            chargeTypes: vesselSummaryData.chargeType,
            subChargeTypes: vesselSummaryData.subChargeType,
            vslCode: vesselSummaryData.vslCode,
            voyCode: vesselSummaryData.voyCode,
            handlingTmls: [vesselSummaryData.handlingTml],            
            adjTypes: ["NORMAL"],
            chargeShareType: "RESH_DETAIL"
        };
    } else if (vesselSummaryData.redirectType === "Summary_Column") {
        const data = vesselSummaryData as ContainerInformationEntity;
        const allModalityArrivals = ["T", "B", "R"];

        let cntrStatus = "";
        if (data.sizeUnit === "M" && vesselSummaryData.fieldName === "dischargeLocal") {
            cntrStatus = "IM";
        } else if (data.sizeUnit === "F" && vesselSummaryData.fieldName === "dischargeLocal") {
            cntrStatus = "IF"
        } else if (data.sizeUnit === "M" && vesselSummaryData.fieldName === "dischargeTranshipment") {
            cntrStatus = "TM";
        } else if (data.sizeUnit === "F" && vesselSummaryData.fieldName === "dischargeTranshipment") {
            cntrStatus = "TF";
        } else if (data.sizeUnit === "M" && vesselSummaryData.fieldName === "loadingLocal" ) {
            cntrStatus = "XM";
        } else if (data.sizeUnit === "F" && vesselSummaryData.fieldName === "loadingLocal") {
            cntrStatus = "XF";
        } else if (data.sizeUnit === "M" && vesselSummaryData.fieldName === "loadingTranshipment") {
            cntrStatus = "OM";
        } else if (data.sizeUnit === "F" && vesselSummaryData.fieldName === "loadingTranshipment") {
            cntrStatus = "OF";
        }

        let modalityArrivals:string[] = [];
        let chargeType:string = "";
        let shipmentType:string = "";
        let subChargeType:string = "";
        let operationType:string = "";
                
        if(vesselSummaryData.fieldName === "d1CyOog"){
        	chargeType = "OOGARR";
        	shipmentType = "OG";
        	operationType = "D1";
        	modalityArrivals = allModalityArrivals;
        } else if(vesselSummaryData.fieldName === "d1CyImo"){
        	chargeType = "IMOARR";
        	shipmentType = "DG";
        	operationType = "D1";
        	modalityArrivals = allModalityArrivals;
        } else if(vesselSummaryData.fieldName === "d1CyBm"){
        	operationType = "D1";
        	chargeType = "CY";
        	subChargeType = "CY";
        } else if(vesselSummaryData.fieldName === "d1CyBs"){
        	operationType = "D1";
        	chargeType = "CY";
        	subChargeType = "CY";
        } else if(vesselSummaryData.fieldName === "l2CyOog"){
        	chargeType = "OOGDEP";
        	shipmentType = "OG";
        	operationType = "L2";
        } else if(vesselSummaryData.fieldName === "l2CyImo"){
        	chargeType = "IMODEP";
        	shipmentType = "DG";
        	operationType = "L2";
        } else if(vesselSummaryData.fieldName === "l2CyBm"){
        	operationType = "L2";
        	chargeType = "CY";
        	subChargeType = "CY";
        } else if(vesselSummaryData.fieldName === "l2CyBs"){
        	operationType = "L2";
        	chargeType = "CY";
        	subChargeType = "CY";
        } else if(vesselSummaryData.fieldName === "d1YhOog"){
        	chargeType = "OOGARR";
        	shipmentType = "OG";
        	operationType = "D1";
        	modalityArrivals = allModalityArrivals;
        } else if(vesselSummaryData.fieldName === "d1YhImo"){
        	chargeType = "IMOARR";
        	shipmentType = "DG";
        	operationType = "D1";
        	modalityArrivals = allModalityArrivals;
        } else if(vesselSummaryData.fieldName === "d1YhBm"){
        	operationType = "D1";
        	chargeType = "YH";
        	subChargeType = "YH";
        } else if(vesselSummaryData.fieldName === "d1YhBs"){
        	operationType = "D1";
        	chargeType = "YH";
        	subChargeType = "YH";
        }

        newSearchCriteria = { ...newSearchCriteria, 
            chargeHdrId: Number(vesselSummaryData.chargeHdrId)??0, 
            modalityArrivals: modalityArrivals??[],
            cntrSizes: data.sizeStr?[data.sizeStr]:[],
            cntrStatus: cntrStatus?[cntrStatus]:[],
            chargeTypes: chargeType?[chargeType]:[],
            subChargeTypes: subChargeType?[subChargeType]:[],
            opsLines: data.cntrOwnerAll?[data.cntrOwnerAll]:[],
            operationTypes: operationType? [operationType]:[],
            shipmentTypes: shipmentType? [shipmentType]: [],
        };
    } else if (vesselSummaryData.redirectType === "inbound_Summary_Column") {
        const data = vesselSummaryData as InboundContainerSummaryEntity;
        newSearchCriteria = {
            ...newSearchCriteria, 
            chargeHdrId: Number(vesselSummaryData.chargeHdrId)??0,
            cntrNos: data.cntrNo?[data.cntrNo]:[]
        }
    }

    return newSearchCriteria;
}

/**
 * If selected rows > 0, adjustment, delete, apply, confirm, unconfirm button will be enable else disabled
 * @param selectedCharges the selected rows in charge detail maintenance screen
 * @returns 
 */
export const updateButtonState = (selectedCharges?:ChargeDetailEntity[]) => {
    if (selectedCharges) {
        if (selectedCharges.length > 0) {
            const validateMsg = validateChargeDelete(selectedCharges);
            let isDisableDel:boolean = false;
            let isDisableAdj:boolean = false;
            if (validateMsg) {
                isDisableDel = true;
            }
            selectedCharges.forEach(function(chargeDtl) {
                if (chargeDtl.dtlState !== ChargeDetailStatePolicy.STATE_I) {
                    isDisableAdj = true;
                }
            });
            return {
                isDisableAdjustment: isDisableAdj,            
                isDisableApply: false,
                isDisableConfirm: false,
                isDisableDelete: isDisableDel,
                isDisableUnconfirm: false,
            }
        }
        return {
            isDisableAdjustment: true,            
            isDisableApply: true,
            isDisableConfirm: true,
            isDisableDelete: true,
            isDisableUnconfirm: true,
        };
    } else {
        return {
            isDisableAdjustment: true,            
            isDisableApply: true,
            isDisableConfirm: true,
            isDisableDelete: true,
            isDisableUnconfirm: true,
        };
    }
}

/**
 * If user click the apply button, if validate fail show warning message
 * @param selectedCharges
 */
export const validateApply = (selectedCharges?:ChargeDetailEntity[]) => {
    if (selectedCharges) {
        const rfIssueData = selectedCharges.find(value => value.commonAcType!=="QTY" &&
            ((value.rfTemp && !value.rfTempUnit) ||
            (!value.rfTemp && value.rfTempUnit)));
        if (rfIssueData) {
            return "RF Temp and RF Temp Unit must be inputted together."
        }
    }
}

export const validateSelectedSubChargeType = (selectedCharges?:ChargeDetailEntity[]) => {
    if (selectedCharges) {
        let subChargeTypes:string[] = [];
        selectedCharges.forEach(function(charge) {
            if (!subChargeTypes.includes(charge.subChargeType??"")) {
                subChargeTypes.push(charge.subChargeType??"");
            }
        });
        if (subChargeTypes && subChargeTypes.length > 1) {
            return MessageConstant.charge.DIFFERENCE_SUB_CHARGE_TYPES;
        }
    }
}

export const getSelectedRowsStatus = (selectedCharges?:ChargeDetailEntity[]) => {
    if (selectedCharges) {
        let allStatus:string[] = [];
        selectedCharges.forEach(function(charge) {
            if (!allStatus.includes(charge.status??"")) {
                allStatus.push(charge.status??"");
            }
        });
        return allStatus;
    }
}

export const getChangeActionType = (cntrStatus:string, selectedCharges?:ChargeDetailEntity[]) => {
    if (!cntrStatus || !selectedCharges) return;

    const statusList:string[] = getSelectedRowsStatus(selectedCharges)??[];

    let isRE = false;
    let isRM = false;
    let isSH = false;
    let isSM = false;

    if (statusList.includes(ContainerStatusPolicy().CNTR_STATUS_RE)) {
        isRE = true;
    }
    if (statusList.includes(ContainerStatusPolicy().CNTR_STATUS_RM)) {
        isRM = true;
    }
    if (statusList.includes(ContainerStatusPolicy().CNTR_STATUS_SH)) {
        isSH = true;
    }
    if (statusList.includes(ContainerStatusPolicy().CNTR_STATUS_SM)) {
        isSM = true;
    }
    
    if (!(isRE || isRM || isSH || isSM) &&
        (ContainerStatusPolicy().CNTR_STATUS_XF === cntrStatus ||
        ContainerStatusPolicy().CNTR_STATUS_XI === cntrStatus ||
        ContainerStatusPolicy().CNTR_STATUS_XM === cntrStatus)) {
        return CHARGE_TYPE_OUTBOUND;          
    } else if (!(isRE || isRM || isSH || isSM) && (
            ContainerStatusPolicy().CNTR_STATUS_OF === cntrStatus ||
            ContainerStatusPolicy().CNTR_STATUS_OM === cntrStatus)
        ) {
        return CHARGE_TYPE_TS_OUTBOUND;            
    } else if (!(isRE || isRM || isSH || isSM) && (
        ContainerStatusPolicy().CNTR_STATUS_TF === cntrStatus ||
        ContainerStatusPolicy().CNTR_STATUS_TM === cntrStatus
        )) {
        return CHARGE_TYPE_TS_INBOUND; 
    } else if (!(isRE || isRM || isSH || isSM) && (
        ContainerStatusPolicy().CNTR_STATUS_EM === cntrStatus ||
        ContainerStatusPolicy().CNTR_STATUS_IF === cntrStatus ||
        ContainerStatusPolicy().CNTR_STATUS_IM === cntrStatus
        )) {
        return CHARGE_TYPE_INBOUND; 
    } else if ((isRE || isRM || isSH || isSM ) &&
        (ContainerStatusPolicy().CNTR_STATUS_RE === cntrStatus ||
        ContainerStatusPolicy().CNTR_STATUS_RM === cntrStatus ||
        ContainerStatusPolicy().CNTR_STATUS_SH === cntrStatus ||
        ContainerStatusPolicy().CNTR_STATUS_SM === cntrStatus )) {
        return CHARGE_TYPE_OLD_NEW_RESH;          
    } else if ((!(isRE || isRM ||isSH || isSM) && (
            ContainerStatusPolicy().CNTR_STATUS_RE === cntrStatus ||
            ContainerStatusPolicy().CNTR_STATUS_RM === cntrStatus ||
            ContainerStatusPolicy().CNTR_STATUS_SH === cntrStatus ||
            ContainerStatusPolicy().CNTR_STATUS_SM === cntrStatus
            ))
      ||((isRE || isRM ||isSH || isSM) &&                
           !(ContainerStatusPolicy().CNTR_STATUS_RE === cntrStatus ||
             ContainerStatusPolicy().CNTR_STATUS_RM === cntrStatus ||
             ContainerStatusPolicy().CNTR_STATUS_SH === cntrStatus ||
             ContainerStatusPolicy().CNTR_STATUS_SM === cntrStatus
           )
         )
      ) {
        return CHARGE_TYPE_OLD_NEW_DIFF_RESH; 
    }

    return null;
}

export const validateChargeDelete = (selectedCharges?:ChargeDetailEntity[]) => {
    if (!selectedCharges) return;

    let exceptionMsg = null;
    selectedCharges.forEach(function(chargeDtl) {
        if (chargeDtl.adjType === AdjustmentTypePolicy.ADJ_TYPE_NORMAL &&
            !chargeDtl.rebillChargeId) {
            exceptionMsg = MessageConstant.charge.CAN_NOT_DELETE_NORMAL;
        }
        if (chargeDtl.adjType !== AdjustmentTypePolicy.ADJ_TYPE_NORMAL &&
            chargeDtl.dtlState === ChargeDetailStatePolicy.STATE_I) {
            exceptionMsg = MessageConstant.charge.WHEN_DELETE_STATE_I;
        }
        if (chargeDtl.rebillChargeId &&
            !(chargeDtl.dtlState === ChargeDetailStatePolicy.STATE_R ||
                chargeDtl.dtlState === ChargeDetailStatePolicy.STATE_F ||
                chargeDtl.dtlState === ChargeDetailStatePolicy.STATE_E
            )
        ) {
            exceptionMsg = MessageConstant.charge.CAN_NOT_DELETE_REBILL_CHG;
        }
        if (chargeDtl.adjType === AdjustmentTypePolicy.ADJ_TYPE_RECAL) {
            exceptionMsg = MessageConstant.charge.WHEN_DELETE_ADJTYPE_RECAL;
        }
    });
    return exceptionMsg;
}