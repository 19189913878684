import { ChargeHeaderEntity } from "domain/entity/ChargeHeader/ChargeHeaderEntity";
import { ChargeHeaderRequestEntity } from "domain/entity/ChargeHeader/ChargeHeaderRequestEntity";
import { DivertShipRequestEntity } from "domain/entity/ChargeHeader/DivertShipRequestEntity";
import { ResponseEntity } from "domain/entity/Common/ResponseEntity";
import { axiosPostData } from "../axios/AxiosBasicImpl";
import chargeAxiosInstance from "../axios/chargeAxiosInstance";
import { ChargeHeaderRepository } from "./ChargeHeaderRepo";

export const ChargeHeaderRepoImpl = () : ChargeHeaderRepository => {
    const url = "/v1/chargeHeaderSearch"
    const refreshUrl = "/v1/chargeHeaderRefreshState"
    const valDivertShipUrl = "/v1/divertShipValidation"
    const divertShipUrl = "/v1/chargeHeaderDivertShip"

    const getChargeHeader = async (request: ChargeHeaderRequestEntity) : Promise<any> => {
        let res = null;
        try {
            res = await axiosPostData(chargeAxiosInstance, `${url}`, request) as ResponseEntity;            
        } catch (err) {
            throw err;
        }
        if(res.success){
            const data = res.data;
            return data;
        }else{
            return "Error:" + res.data; 
        }
            
    }

    const validateDivertShip = async(chargeHeader: ChargeHeaderEntity): Promise<any> => {
        const result = await axiosPostData(chargeAxiosInstance, `${valDivertShipUrl}`, chargeHeader).then(res => {
            if (res.msg === "OK") {
                return res.data;
            } else {
                let errorMessage = "Divert Ship Failure."
                if (res.data && typeof (res.data) === "string") {
                    errorMessage = res.data;
                }
                return "Error:" + errorMessage; 
            }
        }).catch(err => {
            return "Error:" + err.message; 
        });
        return result;
    }

    const doDivertShip = async(request: DivertShipRequestEntity): Promise<string> => {
        const result = await axiosPostData(chargeAxiosInstance, `${divertShipUrl}`, request).then(res => {
            const data = res.data;
            return data;
        }).catch(err => {
            let errorMessage = "Divert Ship Failure"
            if (err && err.response && err.response.data && typeof (err.response.data) === "string") {
                errorMessage = err.response.data
            }
            throw new Error(errorMessage);
        });
        return result;
    }

    const refreshHeaderState = async(hdrIds: number[]): Promise<ResponseEntity> =>{
        const result = await axiosPostData(chargeAxiosInstance, `${refreshUrl}`, hdrIds).catch(err => {
            let errorMessage = "Refresh State Failure"
            if (err && err.response && err.response.data && typeof (err.response.data) === "string") {
                errorMessage = err.response.data
            }
            throw new Error(errorMessage);
        });
        return result;
    }
    return {
        getChargeHeader: getChargeHeader,
        validateDivertShip: validateDivertShip,
        doDivertShip: doDivertShip,
        refreshHeaderState: refreshHeaderState,
        
    }
}