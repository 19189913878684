export interface ChargeHeaderEntity {
    id: number | null,
    versionIdentifier: {
        version?: string
    },
    consortiumCode: string | null,
    vesselCode: string | null,
    voyageCode: string | null,
    etd: Date | null,
    opsDate: Date | null,
    hdrState: string | null,
    confirmType: string | null,
    chargeGroup: string | null,
    shipOwner: string | null,
    billToCompany: string | null,
    groupType: string | null,
    operatingTml: string | null,
    billingCycle: string | null,
    billingCycleCode: string | null,
    opsDateFrom: Date | null,
    opsDateTo: Date | null,
    vesselName: string | null,
    eta: Date | null,
    serviceCode: string | null,
    handlingTerminal: string | null,
    divertShip: string | null,
    divertShipFrom: string | null,
    vesselType: string | null,
    staffCode: string | null,
    chargeDtlInfo: string | null,
    user: string | null,

    [key: string]: string | boolean | null | Object | number | undefined
}

export const EMPTY_CHARGE_HEADER_ENTITY : ChargeHeaderEntity = {
    id: null,
    versionIdentifier: {},
    co: "",
    vsl: "",
    voy: "",
    etd: null,
    opsDate: null,
    hdrState: null,
    confirmType: null,
    chargeGroup: null,
    shipOwner: null,
    billToCompany: null,
    groupType: null,
    operatingTml: null,
    billingCycle: null,
    billingCycleCode: null,
    opsDateFrom: null,
    opsDateTo: null,
    serviceCode: null,
    handlingTerminal: null,
    consortiumCode: "",
    vesselCode: "",
    voyageCode: "",
    vesselName: null,
    eta: null,
    divertShip: null,
    divertShipFrom: null,
    vesselType: null,
    staffCode: null,
    chargeDtlInfo: null,
    user: "",
}