export interface ChargeVesselSearchCriteria{
    consortiumCode?: string | null,
    vesselCode?: string | null,
    voyageCode?: string | null,
    operatingTml?: string[] | null,
    serviceCode?: string[] | null,
    state?: string[] | null,
    finalized?: string[] | null,
    etb?: Date | null,
    etd?: Date | null,
    changeActionOption?: number | null,
    updateStatusType?: string | null,

    [key: string]: string | Date | null | undefined | string[] | number
}

export const EMPTY_CHARGE_VESSEL_SEARCH_CRITERIA : ChargeVesselSearchCriteria = {
    consortiumCode: null,
    vesselCode: null,
    voyageCode: null,
    operatingTml: [],
    serviceCode: [],
    state: [],
    finalized: [],
    etb: null,
    etd: null,
    changeActionOption: null,
    updateStatusType: "",
}