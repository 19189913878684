import { ignoreCntrStatusValidDropdownOption } from "presentation/constant/DropDownOptions/StaticDropdownOptions";
import { useChargeDetailVM } from "presentation/hook/ChargeDetailMaintenance/useChargeDetailVM";
import { ChargeDetailModel } from "presentation/model/ChargeDetailMaintenance/ChargeDetailModel";
import { InputDropdown } from "veronica-ui-component/dist/component/core";

export const IgnoreCntrStatusValidAccordion = ({chargeDetailState}:{chargeDetailState:ChargeDetailModel}) => {
    const chargeBatchUpdate = chargeDetailState.chargeBatchUpdate
    const chargeDetailVM = useChargeDetailVM();
    return (
        <>
            <div className={'im-flex-row'} style={{marginBottom:'24px'}}>
                <div className='im-flex-row-item'>
                    <InputDropdown
                        label='Ignore Cont. Status Validation'
                        width='330px'
                        inputType="freeText"
                        mode={"single"}
                        value={chargeBatchUpdate.ignoreCntrStatusValid}
                        onChange={(e) => chargeDetailVM.onDropdownChange(e, 'ignoreCntrStatusValid', chargeBatchUpdate, 'chargeBatchUpdate')}
                        options={ignoreCntrStatusValidDropdownOption}/>
                </div>

            </div>
        </>
    )
}