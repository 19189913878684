import { DeliveryChargeSummaryEntity } from "domain/entity/ChargeHeader/DeliveryChargeSummaryEntity";
import { SearchPreferenceEntity } from "domain/entity/Common/SearchPreferenceEntity";
import { DEFAULT_ENABLED_DELIVERY_CHARGE_SUMMARY_SEARCH_CRITERIA, DeliveryChargeSummaryEnableSearchCriteria } from "presentation/constant/ChargeHeader/DeliveryChargeSummary/DeliveryChargeSummaryEnableSearchCriteria";
import { DeliveryChargeSummarySearchCriteria, EMPTY_DELIVERY_CHARGE_SUMMARY_SEARCH_CRITERIA } from "presentation/constant/ChargeHeader/DeliveryChargeSummary/DeliveryChargeSummarySearchCriteria";
import { RefObject } from "react";
import { DropdownProps } from "../DropdownProps";


export interface DeliveryChargeSummaryMaintenanceDropdownOptions {
    billingCycleDropdownOptions: DropdownProps[],
    serviceCodeDropdownOptions: DropdownProps[],
}

export interface DeliveryChargeSummaryMaintenanceModel {
    isLoading: boolean,
    isShowCriteriaPanel: boolean,
    enabledSearchCriteria: DeliveryChargeSummaryEnableSearchCriteria,
    searchCriteria: DeliveryChargeSummarySearchCriteria,
    highlightedCheckboxKey?: string | null
    currentTableRef?: RefObject<any>,
    searchCounter: number,
    dynamicOptions: DeliveryChargeSummaryMaintenanceDropdownOptions,
    isShowSaveNewConfirmModal: boolean,
    isShowDeleteConfirmModal: boolean,
    searchPreferences: SearchPreferenceEntity<DeliveryChargeSummarySearchCriteria,DeliveryChargeSummaryEnableSearchCriteria>[],
    currentSearchPreference: SearchPreferenceEntity<DeliveryChargeSummarySearchCriteria,DeliveryChargeSummaryEnableSearchCriteria> | null,
    deliveryChargeSummaryDataList: DeliveryChargeSummaryEntity[],
    currentRow: DeliveryChargeSummaryEntity | null,
}

export const EMPTY_DELIVERY_CHARGE_SUMMARY_MODEL: DeliveryChargeSummaryMaintenanceModel = {
    isLoading: true,
    isShowCriteriaPanel: false,
    enabledSearchCriteria: { ...DEFAULT_ENABLED_DELIVERY_CHARGE_SUMMARY_SEARCH_CRITERIA },
    searchCriteria: { ...EMPTY_DELIVERY_CHARGE_SUMMARY_SEARCH_CRITERIA },
    searchCounter: 0,
    isShowSaveNewConfirmModal: false,
    isShowDeleteConfirmModal: false,
    searchPreferences: [],
    currentSearchPreference: null,
    dynamicOptions: {
        billingCycleDropdownOptions: [],
        serviceCodeDropdownOptions: [],
    },
    deliveryChargeSummaryDataList: [],
    currentRow: null,
}