export interface InboundContainerSummaryEnableSearchCriteria {   
    all: boolean,
    vesselCode: boolean,
    voyageCode: boolean,
    handlingTerminal: boolean,

    [key: string]: boolean,
}

export const DEFAULT_ENABLED_INBOUND_CONTAINER_SUMMARY_SEARCH_CRITERIA : InboundContainerSummaryEnableSearchCriteria = {
    all: false,
    vesselCode: false,
    voyageCode: false,
    handlingTerminal: false
}