import { useChargeDetailVM } from "presentation/hook/ChargeDetailMaintenance/useChargeDetailVM";
import { ChargeDetailModel } from "presentation/model/ChargeDetailMaintenance/ChargeDetailModel";
import { useEffect, useMemo, useState } from "react";
import { Accordion } from "veronica-ui-component/dist/component/core";
import { AccountTerminalAccordion } from "./AccountTerminalAccordion";
import { AdjustmentAccordion } from "./AdjustmentAccordion";
import { BillingTypeAccordion } from "./BillingTypeAccordion";
import { CntrSizeTypeAccordion } from "./CntrSizeTypeAccordion";
import { CompanyAccordion } from "./CompanyAccordion";
import { CopyCommonAcAccordion } from "./CopyCommonAcAccordion";
import { DgDuContentAccordion } from "./DgDuContentAccordion";
import { DirectDischargeIndAccordion } from "./DirectDischargeIndAccordion";
import { EmptyIndAccordion } from "./EmptyIndAccordion";
import { EmptyQtyAccordion } from "./EmptyQtyAccordion";
import { IgnoreCntrStatusValidAccordion } from "./IgnoreCntrStatusValidAccordion";
import { ManualAdjAccordion } from "./ManualAdjAccordion";
import { ManualTsLegChargeIndAccordion } from "./ManualTsLegChargeIndAccordion";
import { OneOffRateAccordion } from "./OneOffRateAccordion";
import { OperatingTmlAccordion } from "./OperatingTmlAccordion";
import { PermitNumberAccordion } from "./PermitNumberAccordion";
import { PooPolPodFdAccordion } from "./PooPolPodFdAccordion";
import { RFInfoAccordion } from "./RFInfoAccordion";
import { RFTempAccordion } from "./RFTempAccordion";
import { SecondLegInfoAccordion } from "./SecondLegInfoAccordion";
import { SoNumberAccordion } from "./SoNumberAccordion";
import { SpecialIndForwarderRefAccordion } from "./SpecialIndForwarderRefAccordion";
import { UpdateAbsolutelyCommonAcAccordion } from "./UpdateAbsolutelyCommonAcAccordion";
import { WaiverNumberAccordion } from "./WaiverNumberAccordion";
import { BundleMasterNumberAccordion } from "./BundleMasterNumberAccordion";
import { FspAccordion } from "./FspAccordion";
import { VoltageAccordion } from "./VoltageAccordion";
import { OffStdMeasurementModeAccordion } from "./OffStdMeasurementModeAccordion";
import { MarshallingTypeAccordion } from "./MarshallingTypeAccordion";
import { SpecialHandlingCodeAccordion } from "./SpecialHandlingCodeAccordion";
import { VesselInfoAccordion } from "./VesselInfoAccordion";
import { TSVesselInfoAccordion } from "./TSVesselInfoAccordion";

export const AccordionPanel = ({chargeDetailState}:{chargeDetailState:ChargeDetailModel}) => {
    const chargeDetailVM = useChargeDetailVM();
    const chargeBatchUpdate = chargeDetailState.chargeBatchUpdate;
    const [currentAccordionData, setCurrentAccordionData] = useState<any[]>();
    //disable the panel which show by their owner button click
    let tempChargeBatchUpdate = ({
        ...chargeBatchUpdate,
        // accResumeTerminalEnable: false,
        // oneOffRateEnable: false,
        // uptAbsolutelyCommonAcEnable: false,
        // copyCommonAcEnable: false,
        // adjustmentEnable: false,
    }); 

    const handleAccordionChange = (e:boolean, i:number) => {
        if (currentAccordionData) {
            const key = currentAccordionData.at(i)?.key;
            chargeDetailVM.onAccordionPanelChange(e, chargeBatchUpdate, key);
        }        
    };
    
    const accordionData = useMemo(() => {
        const accordionData = []

            if (tempChargeBatchUpdate.billToCompanyEnable || tempChargeBatchUpdate.chargeOnCompanyEnable || tempChargeBatchUpdate.alterableBillToCompanyEnable || tempChargeBatchUpdate.alterableChargeOnCompanyEnable) {
                accordionData.push({
                    id: 'bill-to/charge-on company',
                    header: 'Bill-to/Charge-on Company Code',
                    content: <CompanyAccordion chargeDetailState={chargeDetailState}/>,
                    enableToggle: tempChargeBatchUpdate.billToCompanyActive??false,
                    key: "billToCompanyActive"
                })
            }
            if (tempChargeBatchUpdate.accResumeTerminalEnable) {
                accordionData.push({
                    id: 'account terminal',
                    header: 'Account Terminal',
                    content: <AccountTerminalAccordion  chargeDetailState={chargeDetailState}/>,
                    enableToggle: tempChargeBatchUpdate.accResumeTerminalActive??false,
                    key: "accResumeTerminalActive"
                })
            }
            if (tempChargeBatchUpdate.sizeEnable || tempChargeBatchUpdate.cntrTypeEnable) {
                accordionData.push({
                    id: 'cntr size/type',
                    header: 'Update Size/Type',
                    content: <CntrSizeTypeAccordion chargeDetailState={chargeDetailState}/>,
                    enableToggle: tempChargeBatchUpdate.sizeActive??false,
                    key: "sizeActive",                    
                })
            }
            if (tempChargeBatchUpdate.billingTypeEnable) {
                accordionData.push({
                    id: 'billing type',
                    header: 'Update Billing Type',
                    content: <BillingTypeAccordion chargeDetailState={chargeDetailState}/>,
                    enableToggle: tempChargeBatchUpdate.billingTypeActive??false,
                    key: "billingTypeActive",                    
                })
            }
            if (tempChargeBatchUpdate.pooEnable || tempChargeBatchUpdate.polEnable || tempChargeBatchUpdate.podEnable || tempChargeBatchUpdate.fdEnable) {
                accordionData.push({
                    id: 'Poo Pol Pod Fd',
                    header: 'Update Poo Pol Pod Fd',
                    content: <PooPolPodFdAccordion chargeDetailState={chargeDetailState}/>,
                    enableToggle: tempChargeBatchUpdate.pooActive??false,
                    key: "pooActive",                    
                })
            }
            if (tempChargeBatchUpdate.emptyIndEnable) {
                accordionData.push({
                    id: 'empty indicator',
                    header: 'Update Empty Indicator',
                    content: <EmptyIndAccordion chargeDetailState={chargeDetailState}/>,
                    enableToggle: tempChargeBatchUpdate.emptyIndActive??false,
                    key: "emptyIndActive",
                })
            }
            if (tempChargeBatchUpdate.oneOffRateEnable) {
                accordionData.push({
                    id: 'one-off Rate',
                    header: 'Assign/Resume One-off Rate',
                    content: <OneOffRateAccordion chargeDetailState={chargeDetailState}/>,
                    enableToggle: tempChargeBatchUpdate.oneOffRateActive??false,
                    key: "oneOffRateActive",
                })
            }
            if (tempChargeBatchUpdate.operatingTmlEnable) {
                accordionData.push({
                    id: 'operating tml',
                    header: 'Update Operating Tml',
                    content: <OperatingTmlAccordion chargeDetailState={chargeDetailState}/>,
                    enableToggle: tempChargeBatchUpdate.operatingTmlActive??false,
                    key: "operatingTmlActive",                    
                })
            }
            if (tempChargeBatchUpdate.manualTsLegChargeIndEnable) {
                accordionData.push({
                    id: 'Manual TS Leg Charge Ind.',
                    header: 'Assign/Resume Manual TS Leg Charge Ind.',
                    content: <ManualTsLegChargeIndAccordion chargeDetailState={chargeDetailState}/>,
                    enableToggle: tempChargeBatchUpdate.manualTsLegChargeIndActive??false,
                    key: "manualTsLegChargeIndActive",                    
                })
            }
            if (tempChargeBatchUpdate.ignoreCntrStatusValidEnable) {
                accordionData.push({
                    id: 'Ignore Cont. Status Validation',
                    header: 'Ignore Cont. Status Validation',
                    content: <IgnoreCntrStatusValidAccordion chargeDetailState={chargeDetailState}/>,
                    enableToggle: tempChargeBatchUpdate.ignoreCntrStatusValidActive??false,
                    key: "ignoreCntrStatusValidActive",                    
                })
            }
            if (tempChargeBatchUpdate.dgContentEnable) {
                accordionData.push({
                    id: 'DG DU Content',
                    header: 'Update DG/DU Content',
                    content: <DgDuContentAccordion chargeDetailState={chargeDetailState}/>,
                    enableToggle: tempChargeBatchUpdate.dgContentActive??false,
                    key: "dgContentActive",
                })
            }
            if (tempChargeBatchUpdate.specialIndEnable) {
                accordionData.push({
                    id: 'Special Ind/Forwarder Ref',
                    header: 'Update Special Ind/Forwarder Reference',
                    content: <SpecialIndForwarderRefAccordion chargeDetailState={chargeDetailState}/>,
                    enableToggle: tempChargeBatchUpdate.specialIndtActive??false,
                    key: "specialIndtActive",                    
                })
            }
            if (tempChargeBatchUpdate.rfTempEnable) {
                accordionData.push({
                    id: 'RF Temp.',
                    header: 'Update RF Temp./Unit',
                    content: <RFTempAccordion chargeDetailState={chargeDetailState}/>,
                    enableToggle: tempChargeBatchUpdate.rfTempActive??false,
                    key: "rfTempActive",                    
                })
            }
            if (tempChargeBatchUpdate.manualAdjEnable) {
                accordionData.push({
                    id: 'manual Adj Charge',
                    header: 'Manual Adjustment Charge',
                    content: <ManualAdjAccordion chargeDetailState={chargeDetailState}/>,
                    enableToggle: tempChargeBatchUpdate.manualAdjActive??false,
                    key: "manualAdjActive",                    
                })
            }
            if (tempChargeBatchUpdate.soNumberEnable) {
                accordionData.push({
                    id: 'so number',
                    header: 'Update So Number',
                    content: <SoNumberAccordion chargeDetailState={chargeDetailState}/>,
                    enableToggle: tempChargeBatchUpdate.soNumberActive??false,
                    key: "soNumberActive",                    
                })
            }
            if (tempChargeBatchUpdate.uptAbsolutelyCommonAcEnable) {
                accordionData.push({
                    id: 'upt Absolutely Common Ac',
                    header: 'Update 100% Common Account',
                    content: <UpdateAbsolutelyCommonAcAccordion chargeDetailState={chargeDetailState}/>,
                    enableToggle: tempChargeBatchUpdate.uptAbsolutelyCommonAcActive??false,
                    key: "uptAbsolutelyCommonAcActive",
                    
                })
            }
            if (tempChargeBatchUpdate.copyCommonAcEnable) {
                accordionData.push({
                    id: 'Copy Cm A/C To',
                    header: 'Copy Cm A/C To',
                    content: <CopyCommonAcAccordion chargeDetailState={chargeDetailState}/>,
                    enableToggle: tempChargeBatchUpdate.copyCommonAcActive??false,
                    key: "copyCommonAcActive",                    
                })
            }
            if (tempChargeBatchUpdate.adjustmentEnable) {
                accordionData.push({
                    id: 'adjustment',
                    header: 'Adjustment',
                    content: <AdjustmentAccordion chargeDetailState={chargeDetailState}/>,
                    enableToggle: tempChargeBatchUpdate.adjustmentActive??false,
                    key: "adjustmentActive",                    
                })
            }
            if (tempChargeBatchUpdate.waiverNoEnable) {
                accordionData.push({
                    id: 'waiverNo',
                    header: 'Waiver No.',
                    content: <WaiverNumberAccordion chargeDetailState={chargeDetailState}/>,
                    enableToggle: tempChargeBatchUpdate.waiverNoActive??false,
                    key: "waiverNoActive",
                })
            }
            if (tempChargeBatchUpdate.dischargeIndEnable) {
                accordionData.push({
                    id: 'directDischargeInd',
                    header: 'Direct/Indirect Discharge Ind.',
                    content: <DirectDischargeIndAccordion chargeDetailState={chargeDetailState}/>,
                    enableToggle: tempChargeBatchUpdate.dischargeIndActive??false,
                    key: "dischargeIndActive",
                })
            }
            if (tempChargeBatchUpdate.seclegDischargeIndEnable || tempChargeBatchUpdate.seclegOperatingTmlEnable || tempChargeBatchUpdate.seclegSpecialIndEnable) {
                accordionData.push({
                    id: 'secLegInfo',
                    header: 'Update 2nd Leg Info',
                    content: <SecondLegInfoAccordion chargeDetailState={chargeDetailState}/>,
                    enableToggle: tempChargeBatchUpdate.seclegSpecialIndActive??false,
                    key: "seclegSpecialIndActive",
                })
            }
            if (tempChargeBatchUpdate.permitNoEnable) {
                accordionData.push({
                    id: 'permitNo',
                    header: 'Update Permit No.',
                    content: <PermitNumberAccordion chargeDetailState={chargeDetailState}/>,
                    enableToggle: tempChargeBatchUpdate.permitNoActive??false,
                    key: "permitNoActive",
                })
            }
            if (tempChargeBatchUpdate.rfInfoEnable) {
                accordionData.push({
                    id: 'refeerContainerInfo',
                    header: 'Refeer Container Info',
                    content: <RFInfoAccordion chargeDetailState={chargeDetailState}/>,
                    enableToggle: tempChargeBatchUpdate.rfInfoActive??false,
                    key: "rfInfoActive",
                })
            }
            if (tempChargeBatchUpdate.emptyChargeQtyEnable) {
                accordionData.push({
                    id: 'emptyChargeQty',
                    header: 'Empty Charge Qty',
                    content: <EmptyQtyAccordion chargeDetailState={chargeDetailState}/>,
                    enableToggle: tempChargeBatchUpdate.emptyChargeQtyActive??false,
                    key: "emptyChargeQtyActive",
                })
            }
            if (tempChargeBatchUpdate.bundleMasterNoEnable) {
                accordionData.push({
                    id: 'bundleMasterNo',
                    header: 'Bundle Master No.',
                    content: <BundleMasterNumberAccordion chargeDetailState={chargeDetailState}/>,
                    enableToggle: tempChargeBatchUpdate.bundleMasterNoActive??false,
                    key: "bundleMasterNoActive",
                })
            }
            if (tempChargeBatchUpdate.tierFspEnable) {
                accordionData.push({
                    id: 'tierFspEnable',
                    header: 'Update FSP',
                    content: <FspAccordion chargeDetailState={chargeDetailState}/>,
                    enableToggle: tempChargeBatchUpdate.tierFspActive??false,
                    key: "tierFspActive",
                })
            }
            if (tempChargeBatchUpdate.voltageEnable) {
                accordionData.push({
                    id: 'voltageEnable',
                    header: 'Update Voltage',
                    content: <VoltageAccordion chargeDetailState={chargeDetailState}/>,
                    enableToggle: tempChargeBatchUpdate.voltageActive??false,
                    key: "voltageActive",
                })
            }
            if (tempChargeBatchUpdate.offStdMeasurementModeEnable) {
                accordionData.push({
                    id: 'offStdMeasurementModeEnable',
                    header: 'Update Off Std Measurement Mode',
                    content: <OffStdMeasurementModeAccordion chargeDetailState={chargeDetailState}/>,
                    enableToggle: tempChargeBatchUpdate.offStdMeasurementModeActive??false,
                    key: "offStdMeasurementModeActive",
                })
            }
            if (tempChargeBatchUpdate.marshallingTypeEnable) {
                accordionData.push({
                    id: 'marshallingTypeEnable',
                    header: 'Update Marshalling Type',
                    content: <MarshallingTypeAccordion chargeDetailState={chargeDetailState}/>,
                    enableToggle: tempChargeBatchUpdate.marshallingTypeActive??false,
                    key: "marshallingTypeActive",
                })
            }
            if (tempChargeBatchUpdate.specialHandlingCodeEnable) {
                accordionData.push({
                    id: 'specialHandlingCodeEnable',
                    header: 'Update Special Handling Code (D1)',
                    content: <SpecialHandlingCodeAccordion chargeDetailState={chargeDetailState}/>,
                    enableToggle: tempChargeBatchUpdate.specialHandlingCodeActive??false,
                    key: "specialHandlingCodeActive",
                })
            }
            if (tempChargeBatchUpdate.vesselInfoEnable) {
                accordionData.push({
                    id: 'vesselInfoEnable',
                    header: 'Update Vessel Info',
                    content: <VesselInfoAccordion chargeDetailState={chargeDetailState}/>,
                    enableToggle: tempChargeBatchUpdate.vesselInfoActive??false,
                    key: "vesselInfoActive",
                })
            }
            if (tempChargeBatchUpdate.tsVesselInfoEnable) {
                accordionData.push({
                    id: 'tsVesselInfoEnable',
                    header: 'Update TS Vessel Info',
                    content: <TSVesselInfoAccordion chargeDetailState={chargeDetailState}/>,
                    enableToggle: tempChargeBatchUpdate.tsVesselInfoActive??false,
                    key: "tsVesselInfoActive",
                })
            }

            return accordionData
        }, [tempChargeBatchUpdate.billToCompanyEnable, tempChargeBatchUpdate.chargeOnCompanyEnable, tempChargeBatchUpdate.alterableBillToCompanyEnable,
            tempChargeBatchUpdate.alterableChargeOnCompanyEnable, tempChargeBatchUpdate.accResumeTerminalEnable, tempChargeBatchUpdate.sizeEnable,
            tempChargeBatchUpdate.cntrTypeEnable, tempChargeBatchUpdate.billingTypeEnable, tempChargeBatchUpdate.pooEnable, tempChargeBatchUpdate.polEnable,
            tempChargeBatchUpdate.podEnable, tempChargeBatchUpdate.fdEnable, tempChargeBatchUpdate.emptyIndEnable, tempChargeBatchUpdate.oneOffRateEnable,
            tempChargeBatchUpdate.operatingTmlEnable, tempChargeBatchUpdate.manualTsLegChargeIndEnable, tempChargeBatchUpdate.ignoreCntrStatusValidEnable,
            tempChargeBatchUpdate.dgContentEnable, tempChargeBatchUpdate.specialIndEnable, tempChargeBatchUpdate.rfTempEnable, tempChargeBatchUpdate.manualAdjEnable,
            tempChargeBatchUpdate.soNumberEnable, tempChargeBatchUpdate.uptAbsolutelyCommonAcEnable, tempChargeBatchUpdate.copyCommonAcEnable, tempChargeBatchUpdate.adjustmentEnable,
            tempChargeBatchUpdate.billToCompanyActive, tempChargeBatchUpdate.accResumeTerminalActive, tempChargeBatchUpdate.sizeActive,
            tempChargeBatchUpdate.billingTypeActive, tempChargeBatchUpdate.pooActive, tempChargeBatchUpdate.emptyIndActive, tempChargeBatchUpdate.oneOffRateActive,
            tempChargeBatchUpdate.operatingTmlActive, tempChargeBatchUpdate.manualTsLegChargeIndActive, tempChargeBatchUpdate.ignoreCntrStatusValidActive,
            tempChargeBatchUpdate.dgContentActive, tempChargeBatchUpdate.specialIndtActive, tempChargeBatchUpdate.rfTempActive,
            tempChargeBatchUpdate.manualAdjActive, tempChargeBatchUpdate.soNumberActive, tempChargeBatchUpdate.uptAbsolutelyCommonAcActive, tempChargeBatchUpdate.copyCommonAcActive,
            tempChargeBatchUpdate.adjustmentActive, chargeDetailState, tempChargeBatchUpdate.waiverNoActive, tempChargeBatchUpdate.waiverNoEnable,
            tempChargeBatchUpdate.dischargeIndEnable, tempChargeBatchUpdate.dischargeIndActive, tempChargeBatchUpdate.seclegDischargeIndEnable, 
            tempChargeBatchUpdate.seclegOperatingTmlEnable, tempChargeBatchUpdate.seclegSpecialIndActive, tempChargeBatchUpdate.seclegSpecialIndEnable,
            tempChargeBatchUpdate.permitNoActive, tempChargeBatchUpdate.permitNoEnable, tempChargeBatchUpdate.rfInfoActive, tempChargeBatchUpdate.rfInfoEnable,
            tempChargeBatchUpdate.emptyChargeQtyActive, tempChargeBatchUpdate.emptyChargeQtyEnable, tempChargeBatchUpdate.bundleMasterNoActive, tempChargeBatchUpdate.bundleMasterNoEnable,
            tempChargeBatchUpdate.tierFspActive, tempChargeBatchUpdate.tierFspEnable,tempChargeBatchUpdate.voltageActive,tempChargeBatchUpdate.voltageEnable,
            tempChargeBatchUpdate.offStdMeasurementModeActive, tempChargeBatchUpdate.offStdMeasurementModeEnable,tempChargeBatchUpdate.marshallingTypeActive,
            tempChargeBatchUpdate.marshallingTypeEnable, tempChargeBatchUpdate.specialHandlingCodeActive, tempChargeBatchUpdate.specialHandlingCodeEnable,
            tempChargeBatchUpdate.vesselInfoActive, tempChargeBatchUpdate.vesselInfoEnable, tempChargeBatchUpdate.tsVesselInfoActive, tempChargeBatchUpdate.tsVesselInfoEnable,
        ]
    );

    useEffect(() => {
        setCurrentAccordionData(accordionData);
    }, [accordionData])
            
    return (
        <div className='flex-row-item flex-row-item-full-width'>
            <Accordion accordionData={accordionData} width="400px" toggle onChange={(e, activeIndex) => {handleAccordionChange(e, activeIndex)}}/>
        </div>
    )
}