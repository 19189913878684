import { ChargeConstant } from "presentation/constant/ChargeHeader/ChargeConstant";
import { useChargeDetailTracked } from "presentation/store/ChargeDetailMaintenance/ChargeDetailProvider";
import { useChargeHeaderMaintenanceTracked } from "presentation/store/ChargeHeaderMaintenance/ChargeHeaderMaintenanceProvider";
import { convertDateToDateForComponent, convertDateToDateTimeForComponent } from "presentation/utils/timeUtil";
import { memo } from "react";

const ChargeDetailHeaderPanel = () => {
    
    const VSL_SUMMARY_CONSTANT = ChargeConstant.VesselSummary;
    const CHARGE_CONSTANT = ChargeConstant.Charge;
    const [chargeHeaderState] = useChargeHeaderMaintenanceTracked();
    const [chargeDetailState] = useChargeDetailTracked();
    const currentChargeHeader = chargeDetailState?.currentChargeHeader ? chargeDetailState?.currentChargeHeader : chargeHeaderState?.currentChargeHeader;
    const currentGroupName = chargeHeaderState?.searchCriteria.hdrGroupName??
        (chargeDetailState?.currentChargeHeader.groupType==="1" ? "SHIPBOUNDED" :
            (chargeDetailState?.currentChargeHeader.groupType==="2" ? "SHIPBOUNDEDINPERIOD" :
                (chargeDetailState?.currentChargeHeader.groupType==="3" ? "DOMESTIC" : "SHIPBOUNDED")
            )
        );

    // const memoVesselType = useMemo(() => {
    //     return <>
    //     <div className='flex-row-item' style={{width:"145px"}}>
    //         <CommonField
    //             isReadOnly={true}
    //             readOnlyValue={currentChargeHeader?.vesselType??""}
    //             fieldLabel={VSL_SUMMARY_CONSTANT.VESSEL_TYPE}
    //             fieldType={FieldType.TEXT}
    //             fieldKey={'vesselType'}
    //             requiredFieldList={[]}
    //             onFieldChange={()=>{}} />
    //     </div>
    //     </>
    // }, [VSL_SUMMARY_CONSTANT.VESSEL_TYPE, currentChargeHeader?.vesselType]);

    // const memoHandVslVoy = useMemo(() => {
    //     return <>
    //     <div className='flex-row-item' style={{width:"300px"}}>
    //         <CommonField
    //             isReadOnly={true}
    //             readOnlyValue={`${currentChargeHeader?.handlingTerminal??""}/
    //                 ${currentChargeHeader?.vesselCode??""}/
    //                 ${currentChargeHeader?.voyageCode??""}`
    //             }
    //             fieldLabel={`${CHARGE_CONSTANT.HANDLING_TML}/
    //                 ${CHARGE_CONSTANT.VSL}/
    //                 ${CHARGE_CONSTANT.VOY}`}
    //             fieldType={FieldType.TEXT}
    //             fieldKey={''}
    //             isMultipleDropDown={true}
    //             requiredFieldList={[]}
    //             onFieldChange={()=>{}} />
    //     </div>
    //     </>
    // }, [CHARGE_CONSTANT.HANDLING_TML, CHARGE_CONSTANT.VOY, CHARGE_CONSTANT.VSL, currentChargeHeader?.handlingTerminal, currentChargeHeader?.vesselCode, currentChargeHeader?.voyageCode]);

    // const memoSOA = useMemo(() => {
    //     return <>
    //     <div className='flex-row-item' style={{width:"145px"}}>
    //         <CommonField
    //             isReadOnly={true}
    //             readOnlyValue={currentChargeHeader?.shipOwner??""}
    //             fieldLabel={ChargeConstant.ChargeHeader.SOA}
    //             fieldType={FieldType.TEXT}
    //             fieldKey={''}
    //             maxLength={6}
    //             isTextFieldCapitalize={true}
    //             isMultipleDropDown={true}
    //             requiredFieldList={[]}
    //             onFieldChange={()=>{}} />
    //     </div>
    //     </>
    // }, [currentChargeHeader?.shipOwner]);

    // const memoETD = useMemo(() => {
    //     return <>
    //     <div className='flex-row-item' style={{width:"145px"}}>
    //         <DateTimePickerComponent
    //             disabled={true}
    //             date={currentChargeHeader?.etd}
    //             label={ChargeConstant.Charge.ETD}
    //             onDateChange={()=>{}}
    //             />
    //     </div>
    //     </>
    // }, [currentChargeHeader?.etd]);

    // const memoOpsDate = useMemo(() => {
    //     return <>
    //     <div className='flex-row-item' style={{width:"145px"}}>
    //         <DatePickerComponent
    //             disabled={true}
    //             date={currentChargeHeader?.opsDate}
    //             label={ChargeConstant.Charge.OPS_DATE}
    //             onDateChange={()=>{}} />
    //     </div>
    //     </>
    // }, [currentChargeHeader?.opsDate]);

    // const memoOpsDateFrom = useMemo(() => {
    //     return <>
    //     <div className='flex-row-item' style={{width:"145px"}}>
    //         <DatePickerComponent
    //             disabled={true}
    //             date={currentChargeHeader?.opsDateFrom}
    //             label={ChargeConstant.ChargeHeader.OPS_DATE_FROM}
    //             onDateChange={()=>{}} />
    //     </div>
    //     </>
    // }, [currentChargeHeader?.opsDateFrom]);

    // const memoOpsDateTo = useMemo(() => {
    //     return <>
    //     <div className='flex-row-item' style={{width:"145px"}}>
    //         <DatePickerComponent
    //             disabled={true}
    //             date={currentChargeHeader?.opsDateTo}
    //             label={ChargeConstant.ChargeHeader.OPS_DATE_TO}
    //             onDateChange={()=>{}} />
    //     </div>
    //     </>
    // }, [currentChargeHeader?.opsDateTo]);

    // const memoDivertShip = useMemo(() => {
    //     return <>
    //     <div className='flex-row-item' style={{width:"145px"}}>
    //         <CommonField
    //             isReadOnly={true}
    //             readOnlyValue={currentChargeHeader?.divertShip??""}
    //             fieldLabel={ChargeConstant.ChargeHeader.DIVERT_SHIP}
    //             fieldType={FieldType.TEXT}
    //             fieldKey={''}
    //             isMultipleDropDown={true}
    //             requiredFieldList={[]}
    //             onFieldChange={()=>{}} />
    //     </div>
    //     </>
    // }, [currentChargeHeader?.divertShip]);

    // const memoDivertShipFrom = useMemo(() => {
    //     return <>
    //     <div className='flex-row-item' style={{width:"145px"}}>
    //         <CommonField
    //             isReadOnly={true}
    //             readOnlyValue={currentChargeHeader?.divertShipFrom??""}
    //             fieldLabel={ChargeConstant.ChargeHeader.DIVERT_SHIP_FROM}
    //             fieldType={FieldType.TEXT}
    //             fieldKey={''}
    //             isMultipleDropDown={true}
    //             requiredFieldList={[]}
    //             onFieldChange={()=>{}} />
    //     </div>
    //     </>
    // }, [currentChargeHeader?.divertShipFrom]);

    // const memoChargeDetailInfo = useMemo(() => {
    //     return <>
    //     <div className='flex-row-item' style={{width:"145px"}}>
    //         <CommonField
    //             isReadOnly={true}
    //             readOnlyValue={currentChargeHeader?.chargeDtlInfo??""}
    //             fieldLabel={ChargeConstant.ChargeHeader.CHARGE_DETAIL_INFO}
    //             fieldType={FieldType.TEXT}
    //             fieldKey={''}
    //             isMultipleDropDown={true}
    //             requiredFieldList={[]}
    //             onFieldChange={()=>{}} />
    //     </div>
    //     </>
    // }, [currentChargeHeader?.chargeDtlInfo]);

    // const memoETA = useMemo(() => {
    //     return <>
    //     <div className='flex-row-item' style={{width:"145px"}}>
    //         <DateTimePickerComponent
    //             disabled={true}
    //             date={currentChargeHeader?.eta}
    //             label={ChargeConstant.ChargeHeader.ETA}
    //             onDateChange={()=>{}}
    //             />
    //     </div>
    //     </>
    // }, [currentChargeHeader?.eta]);

    // const memoVesselName = useMemo(() => {
    //     return <>
    //     <div className='flex-row-item' style={{width:"300px"}}>
    //         <CommonField
    //             isReadOnly={true}
    //             readOnlyValue={currentChargeHeader?.vesselName??""}
    //             fieldLabel={ChargeConstant.ChargeHeader.VESSEL_NAME}
    //             fieldType={FieldType.TEXT}
    //             fieldKey={''}
    //             isMultipleDropDown={true}
    //             requiredFieldList={[]}
    //             onFieldChange={()=>{}} />
    //     </div>
    //     </>
    // }, [currentChargeHeader?.vesselName]);

    // const memoOperatingTml = useMemo(() => {
    //     return <>
    //     <div className='flex-row-item' style={{width:"145px"}}>
    //         <CommonField
    //             isReadOnly={true}
    //             readOnlyValue={currentChargeHeader?.operatingTml??""}
    //             fieldLabel={ChargeConstant.ChargeHeader.OPERATING_TML}
    //             fieldType={FieldType.TEXT}
    //             fieldKey={''}
    //             isMultipleDropDown={true}
    //             requiredFieldList={[]}
    //             onFieldChange={()=>{}} />
    //     </div>
    //     </>
    // }, [currentChargeHeader?.operatingTml]);

    // const memoBillingCycle = useMemo(() => {
    //     return <>
    //     <div className='flex-row-item' style={{width:"145px"}}>
    //         <CommonField
    //             isReadOnly={true}
    //             readOnlyValue={currentChargeHeader?.billingCycle??""}
    //             fieldLabel={ChargeConstant.ChargeHeader.BILLING_CYCLE}
    //             fieldType={FieldType.TEXT}
    //             fieldKey={''}
    //             isMultipleDropDown={true}
    //             requiredFieldList={[]}
    //             onFieldChange={()=>{}} />
    //     </div>
    //     </>
    // }, [currentChargeHeader?.billingCycle]);

    // const memoBillingCycleCode = useMemo(() => {
    //     return <>
    //     <div className='flex-row-item' style={{width:"145px"}}>
    //         <CommonField
    //             isReadOnly={true}
    //             readOnlyValue={currentChargeHeader?.billingCycleCode??""}
    //             fieldLabel={ChargeConstant.ChargeHeader.BILLING_CYCLE_CODE}
    //             fieldType={FieldType.TEXT}
    //             fieldKey={''}
    //             isMultipleDropDown={true}
    //             requiredFieldList={[]}
    //             onFieldChange={()=>{}} />
    //     </div>
    //     </>
    // }, [currentChargeHeader?.billingCycleCode]);

    // const memoServiceCode = useMemo(() => {
    //     return <>
    //     <div className='flex-row-item' style={{width:"145px"}}>
    //         <CommonField
    //             isReadOnly={true}
    //             readOnlyValue={currentChargeHeader?.serviceCode??""}
    //             fieldLabel={ChargeConstant.ChargeHeader.SERVICE_CODE}
    //             fieldType={FieldType.TEXT}
    //             fieldKey={''}
    //             isMultipleDropDown={true}
    //             requiredFieldList={[]}
    //             onFieldChange={()=>{}} />
    //     </div>
    //     </>
    // }, [currentChargeHeader?.serviceCode]);

    // return <>
    //     <div className='side-form-content-left-wrapper'>
    //         <div className={'flex-row'}>
    //             <div className={'flex-row-item'}>
    //                 <Sidebarheader>
    //                     <SidebarTitle>{
    //                         currentGroupName?.toUpperCase()==="DOMESTIC"?                                
    //                             convertDateToDateForComponent(convertDateToUTC(moment(currentChargeHeader?.opsDate))) :
    //                             `${currentChargeHeader?.handlingTerminal??""}/${currentChargeHeader?.vesselCode??""}/${currentChargeHeader?.voyageCode??""}` 
    //                     }
    //                     </SidebarTitle>
    //                 </Sidebarheader>
    //             </div>
    //         </div>
    //         <div className={'add-edit-form'} style={{ maxHeight:'78vh' , overflow: 'auto'}}>
    //             <div className="flex-row-auto">
    //                 <div className={'flex-row-item'}>
    //                     <CriteriaItemContainer>
    //                         {memoHandVslVoy}
    //                     </CriteriaItemContainer>
    //                     <CriteriaItemContainer>
    //                         {memoSOA}{memoServiceCode}
    //                     </CriteriaItemContainer>
    //                     <CriteriaItemContainer>
    //                         {memoVesselType}{memoOperatingTml}
    //                     </CriteriaItemContainer>
    //                     <CriteriaItemContainer>
    //                         {memoETD}{memoETA}
    //                     </CriteriaItemContainer>
    //                     <CriteriaItemContainer>
    //                         {memoDivertShip}{memoDivertShipFrom}
    //                     </CriteriaItemContainer>                        
    //                     <CriteriaItemContainer>
    //                         {memoBillingCycle}{memoBillingCycleCode}
    //                     </CriteriaItemContainer>  
    //                     <CriteriaItemContainer>
    //                         {memoOpsDate}{memoChargeDetailInfo}
    //                     </CriteriaItemContainer>
    //                     <CriteriaItemContainer>
    //                         {memoOpsDateFrom}{memoOpsDateTo}
    //                     </CriteriaItemContainer>
    //                     <CriteriaItemContainer>
    //                         {memoVesselName}
    //                     </CriteriaItemContainer>                      
    //                 </div>                  
    //             </div>
    //         </div>
    //     </div>
    // </>
    
    return <div className="headerInfo">
        <div className={`${currentGroupName==="SHIPBOUNDED" ? "sixColumns" : (currentGroupName==="SHIPBOUNDEDINPERIOD" ? "eightColumns" : "fiveColumns")}`}>
            {currentGroupName==="DOMESTIC" &&
            <div className={"item"}>
                <div className={"label"}>
                    {ChargeConstant.Charge.BILL_TO_COMPANY}
                </div>
                <div className={"value"}>
                    {currentChargeHeader?.billToCompany}
                </div>
            </div>
            }

            {(currentGroupName==="SHIPBOUNDED" || currentGroupName==="SHIPBOUNDEDINPERIOD") && <>
            <div className={"item"}>
                <div className={"label"}>
                    {`${CHARGE_CONSTANT.HANDLING_TML}/
                    ${CHARGE_CONSTANT.VSL}/
                    ${CHARGE_CONSTANT.VOY}`}
                </div>
                <div className={"value"}>
                    {`${currentChargeHeader?.handlingTerminal??""}/
                        ${currentChargeHeader?.vesselCode??""}/
                        ${currentChargeHeader?.voyageCode??""}`
                    }
                </div>
            </div>
            <div className={"item"}>
                <div className={"label"}>
                    {ChargeConstant.Charge.ETD}
                </div>
                <div className={"value"}>
                    {convertDateToDateTimeForComponent(currentChargeHeader?.etd)}
                </div>
            </div>
            <div className={"item"}>
                <div className={"label"}>
                    {ChargeConstant.ChargeHeader.ETA}
                </div>
                <div className={"value"}>
                    {convertDateToDateTimeForComponent(currentChargeHeader?.eta)}
                </div>
            </div>                
            <div className={"item"}>
                <div className={"label"}>
                    {ChargeConstant.ChargeHeader.SERVICE_CODE}
                </div>
                <div className={"value"}>
                    {currentChargeHeader?.serviceCode??""}
                </div>
            </div>
            <div className={"item"}>
                <div className={"label"}>
                    {ChargeConstant.ChargeHeader.DIVERT_SHIP}
                </div>
                <div className={"value"}>
                    {currentChargeHeader?.divertShip??""}
                </div>
            </div>
            <div className={"item"}>
                <div className={"label"}>
                    {ChargeConstant.ChargeHeader.DIVERT_SHIP_FROM}
                </div>
                <div className={"value"}>
                    {currentChargeHeader?.divertShipFrom??""}
                </div>
            </div></>}
            {(currentGroupName==="SHIPBOUNDEDINPERIOD" || currentGroupName==="DOMESTIC") && <>
            <div className={"item"}>
                <div className={"label"}>
                    {ChargeConstant.ChargeHeader.OPS_DATE_FROM}
                </div>
                <div className={"value"}>
                    {convertDateToDateForComponent(currentChargeHeader?.opsDateFrom)}
                </div>
            </div>
            <div className={"item"}>
                <div className={"label"}>
                    {ChargeConstant.ChargeHeader.OPS_DATE_TO}
                </div>
                <div className={"value"}>
                    {convertDateToDateForComponent(currentChargeHeader?.opsDateTo)}
                </div>
            </div>
            </>}

            {(currentGroupName==="SHIPBOUNDED" || currentGroupName==="SHIPBOUNDEDINPERIOD") && <>
            <div className={"item"}>
                <div className={"label"}>
                    {ChargeConstant.ChargeHeader.VESSEL_NAME}
                </div>
                <div className={"value"}>
                    {currentChargeHeader?.vesselName??""}
                </div>
            </div></>}
            {(currentGroupName==="SHIPBOUNDEDINPERIOD" || currentGroupName==="DOMESTIC") && <>
            <div className={"item"}>
                <div className={"label"}>
                    {ChargeConstant.ChargeHeader.BILLING_CYCLE}
                </div>
                <div className={"value"}>
                    {currentChargeHeader?.billingCycle??""}
                </div>
            </div>
            <div className={"item"}>
                <div className={"label"}>
                    {ChargeConstant.ChargeHeader.BILLING_CYCLE_CODE}
                </div>
                <div className={"value"}>
                    {currentChargeHeader?.billingCycleCode??""}
                </div>
            </div>
            </>}
            
            <div className={"item"}>
                <div className={"label"}>
                    {ChargeConstant.ChargeHeader.OPERATING_TML}
                </div>
                <div className={"value"}>
                    {currentChargeHeader?.operatingTml??""}
                </div>
            </div>
            {currentGroupName==="DOMESTIC" && <>
            <div className={"item"}>
                <div className={"label"}>
                    {ChargeConstant.ChargeHeader.HANDLING_TML}
                </div>
                <div className={"value"}>
                    {currentChargeHeader?.handlingTerminal??""}
                </div>
            </div></>}
            {(currentGroupName==="SHIPBOUNDED" || currentGroupName==="SHIPBOUNDEDINPERIOD") && <>
            <div className={"item"}>
                <div className={"label"}>
                    {ChargeConstant.ChargeHeader.SOA}
                </div>
                <div className={"value"}>
                    {currentChargeHeader?.shipOwner??""}
                </div>
            </div>
            <div className={"item"}>
                <div className={"label"}>
                    {VSL_SUMMARY_CONSTANT.VESSEL_TYPE}
                </div>
                <div className={"value"}>
                    {currentChargeHeader?.vesselType??""}
                </div>
            </div></>}
            <div className={"item"}>
                <div className={"label"}>
                    {ChargeConstant.ChargeHeader.CHARGE_DETAIL_INFO}
                </div>
                <div className={"value"}>
                    {currentChargeHeader?.chargeDtlInfo??""}
                </div>
            </div>
        </div>
    </div>   
}

export default memo(ChargeDetailHeaderPanel);