import { EMPTY_VESSEL_SUMMARY_DATA_ENTITY, VesselSummaryEntity } from "domain/entity/ChargeHeader/VesselSummaryEntity";
import { EMPTY_VESSEL_SUMMARY_HEADER_ENTITY, VesselSummaryHdrEntity } from "domain/entity/ChargeHeader/VesselSummaryHdrEntity";
import { EMPTY_VESSEL_SUMMARY_TDR_ENTITY, VesselSummaryTdrEntity } from "domain/entity/ChargeHeader/VesselSummaryTdrEntity";
import { SearchPreferenceEntity } from "domain/entity/Common/SearchPreferenceEntity";
import { DEFAULT_ENABLED_VESSEL_SUMMARY_SEARCH_CRITERIA, VesselSummaryEnableSearchCriteria } from "presentation/constant/ChargeHeader/VesselSummary/VesselSummaryEnableSearchCriteria";
import { DEFAULT_ENABLED_VESSEL_SUMMARY_HEADER_FIELD, VesselSummaryEnabledHeaderField } from "presentation/constant/ChargeHeader/VesselSummary/VesselSummaryEnabledHeaderField";
import { DEFAULT_VESSEL_SUMMARY_HEADER_FIELD, VesselSummaryHeaderField } from "presentation/constant/ChargeHeader/VesselSummary/VesselSummaryHeaderField";
import { EMPTY_VESSEL_SUMMARY_SEARCH_CRITERIA, VesselSummarySearchCriteria } from "presentation/constant/ChargeHeader/VesselSummary/VesselSummarySearchCriteria";
import { RefObject } from "react";


export interface VesselSummaryMaintenanceDropdownOptions {
}

export interface VesselSummaryMaintenanceModel {
    isLoading: boolean,
    isShowSummaryHeaderFieldPanel: boolean,
    isShowTdrPanel: boolean,
    enabledSearchCriteria: VesselSummaryEnableSearchCriteria,
    searchCriteria: VesselSummarySearchCriteria,
    vslSummayHeaderField: VesselSummaryHeaderField, 
    enabledVslSummayHeaderField: VesselSummaryEnabledHeaderField,
    highlightedCheckboxKey?: string | null
    currentTableRef?: RefObject<any>,
    searchCounter: number,
    dynamicOptions: VesselSummaryMaintenanceDropdownOptions,
    isShowSaveNewConfirmModal: boolean,
    isShowDeleteConfirmModal: boolean,
    searchPreferences: SearchPreferenceEntity<VesselSummarySearchCriteria,VesselSummaryEnableSearchCriteria>[],
    currentSearchPreference: SearchPreferenceEntity<VesselSummarySearchCriteria,VesselSummaryEnableSearchCriteria> | null,
    currentSummaryHeader: VesselSummaryHdrEntity,
    vesselSummaryData: VesselSummaryEntity,
    tdrData: VesselSummaryTdrEntity,
}

export const EMPTY_VESSEL_SUMMARY_MODEL: VesselSummaryMaintenanceModel = {
    isLoading: true,
    isShowSummaryHeaderFieldPanel: true,
    isShowTdrPanel: false,
    enabledSearchCriteria: { ...DEFAULT_ENABLED_VESSEL_SUMMARY_SEARCH_CRITERIA },
    searchCriteria: { ...EMPTY_VESSEL_SUMMARY_SEARCH_CRITERIA },
    searchCounter: 0,
    isShowSaveNewConfirmModal: false,
    isShowDeleteConfirmModal: false,
    searchPreferences: [],
    currentSearchPreference: null,
    currentSummaryHeader: EMPTY_VESSEL_SUMMARY_HEADER_ENTITY,
    dynamicOptions: [],
    vslSummayHeaderField: DEFAULT_VESSEL_SUMMARY_HEADER_FIELD,
    enabledVslSummayHeaderField: DEFAULT_ENABLED_VESSEL_SUMMARY_HEADER_FIELD,
    vesselSummaryData: EMPTY_VESSEL_SUMMARY_DATA_ENTITY,
    tdrData: EMPTY_VESSEL_SUMMARY_TDR_ENTITY,
}