export interface VesselSummarySearchCriteria {    
    vesselCode?: string | null,
    voyageCode?: string | null,
    handlingTerminal?: string | null,

    [key: string]: string | null | undefined
}

export const EMPTY_VESSEL_SUMMARY_SEARCH_CRITERIA : VesselSummarySearchCriteria = {
    vesselCode: null,
    voyageCode: null,
    handlingTerminal: null,
}