import { SpecialIndEntity } from "domain/entity/SpecialInd/SpecialIndEntity";
import { axiosGetData } from "../axios/AxiosBasicImpl";
import referenceAxiosInstance from "../axios/referenceAxiosInstance";
import { SpecialIndRepository } from "./SpecialIndRepo";

export const SpecialIndRepoImpl = ():SpecialIndRepository => {
    const url = '/v1/specialInd'
    const getSpecialIndForComboBox = async() : Promise<SpecialIndEntity[]> => {
        return axiosGetData(referenceAxiosInstance, url,[]).then(res => {
            return res.data;
        }).catch(err => {
            return [];
        })
    }

    return{
        getSpecialIndForComboBox: getSpecialIndForComboBox
    }
}